import React, { useEffect, useState, useContext } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faFileExcel, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import TextareaAutosize from 'react-textarea-autosize';
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import FileUpload from './FileUpload';
import BrochureUpload from './BrochureUpload';
import * as Yup from 'yup';
import DatePickerField from './DatePickerField';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { AuthContext } from "../Academy/Auth";
import { db } from '../../firebase';
import SocialMediaUpload from './SocialMediaUpload';
import MyTextAreaField from './MyTextAreaField';
library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faCheck, faTimes);


//validation
const AddSocialMediaSchema = Yup.object().shape({
    imageCaptions: Yup.string().required('Required'),
});

// error messages
const ErrorMessages = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? <div className="text-danger ml-2">{error}</div> : null;
      }}
    />
);


function EditSocialMedia(props) {
    const [imageUrl, setImageUrl] = useState('');
    const [post, setPost] = useState();
    useEffect(() => {
        db.collection('socialMedia').doc(props.match.params.id).get().then((doc) => {
            if(doc.exists){
                setPost(doc.data());
                setImageUrl(doc.data().imageUrl);
            }
            else{
                props.history.push('/dashboard/');
            }
        })
    },[])

    const deleteFromFirebaseHandler = (url) => {
        const ref = firebase.storage().refFromURL(url);
        ref.delete().then(() => console.log('File Deleted!'));
    }
    
    const deleteSocialMediaHandler = () => {
        // deleteFromFirebaseHandler(imageUrl);
        //deleting post
        firebase.firestore().collection('socialMedia').doc(props.match.params.id).delete()
        .then(() => {
            alert('Post deleted')
            props.history.push('/dashboard/');
        })
    }
    

    return(
        <div className="socialMedia">
            <AcademyNavbar />
            <div className="container">
                <h4 className="text-white mt-4">Edit a social media post</h4>
                <Formik
                validationSchema={AddSocialMediaSchema}
                enableReinitialize
                initialValues={{
                    imageUrl: post ?  post.imageUrl : '',
                    imageCaptions: post ? post.imageCaptions : ''
                }}
                onSubmit={(values, { resetForm }) => {
                    values.imageUrl = imageUrl;
                    db.collection('socialMedia').doc(props.match.params.id).set(values).then(() => {
                        alert("Post edited");
                        props.history.push('/dashboard/');
                    });
                    resetForm();
                }}
                >
                    {({ errors, touched, isValidating, values }) => (
                    <Form className={'container-fluid program-content-web'}>
                        <div className="container-fluid bg-black pt-0">
                            <div className="container">
                                <Card className="text-white col-xs-12 col-sm-12 col-md-3 h-100 m-3 mx-auto" style={{ padding: '0', backgroundColor: '#333333'}}>
                                    <img className="img-fluid" src={imageUrl} ></img>
                                    <SocialMediaUpload url={setImageUrl} imagePreview={false} />
                                    <Card.Title className="font-weight-light p-3 m-0">
                                        <MyTextAreaField className={"form-control" + (errors.imageCaptions && touched.imageCaptions ? ' is-invalid' : '')} name="imageCaptions" placeholder="Captions" />
                                        {errors.imageCaptions && touched.imageCaptions ? (
                                        <div className="text-danger ml-2">{errors.imageCaptions}</div>
                                        ) : null}
                                    </Card.Title>
                                </Card>
                            </div>
                        </div>
                        <div className="row container" style={{margin: '0 auto', justifyContent: 'center', flexDirection: 'row'}}>
                            <div style={{textAlign: 'center'}} className="mr-4"> 
                                <button type="submit" className="btn btn-primary mt-3">Edit</button>
                            </div>
                            <div style={{textAlign: 'center'}}> 
                                <button type="button" onClick={deleteSocialMediaHandler}  className="btn btn-danger mt-3">Delete</button>
                            </div>
                        </div>
                    </Form>
                    )}
                </Formik>
            </div>
            <AcademyFooter />
        </div>
    )
 
}

export default EditSocialMedia;