import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import DatePickerLeadsComments from './DatePickerLeadsComments';
import * as Yup from 'yup';
import moment from 'moment';
import _ from 'lodash';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const AllLeadsComments = (props) => {
    const [lead, setLead] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const [userNames, setUserNames] = useState([]);
    const [sortedComments, setSortedComments] = useState('');

    const [currentLeadID, setCurrentLeadID] = useState('');
    const [stage, setStage] = useState('');
    const [remarks, setRemarks] = useState('');

    //checking whether url has state or not 
    useEffect(() => {
        if(!props.location.state || !props.location.state.leadid ){
            props.history.push('/admin/leads/');
        }
    },[])
    // Getting lead
    useEffect(() => {
        if(props.location.state && props.location.state.leadid ){
            firebase.firestore().collection('leads').doc(props.location.state.leadid).get()
            .then((doc) => {
                if(doc.exists){
                    setLead({leadId: doc.id, ...doc.data()});
                }
            })
        }
    }, [])
    // Getting userNames
    useEffect(() => {
        if(lead){
            firebase.firestore().collection('academyUsers').get()
            .then((snapshot) => {
                let tempList = [];
                snapshot.forEach(doc => {
                    tempList.push({id: doc.id, firstName: doc.data().firstName, lastName: doc.data().lastName})
                })
                setUserNames(tempList);
            })
        }
    }, [lead])
    // sorting comments
    useEffect(() => {
        if(lead.comments){
            const timeConverted = lead.comments.map((comment) => {
                comment.time = comment.time.toDate();
                return(comment)
            })
            const sorted = _.orderBy(timeConverted, 'time', 'desc');
            setLead((prevState) => {
                let prev = prevState;
                prev.comments = sorted;
                return(prev)
            })
        }
    },[lead])
    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    // Getting referrals
    useEffect(() => {
        let referralsRef = firebase.firestore().collection('referrals');
        referralsRef.get()
            .then(snapshot => {
                let tempReferrals = [];
                snapshot.forEach(doc => {
                    tempReferrals.push(doc.data());
                });
                setReferrals(tempReferrals);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }
    
    const getReferralNameHandler = (id) => {
        let referralName = '';
        referrals.forEach((referral) => {
            if(referral.ref === id){
                referralName = referral.firstName + " " + referral.lastName;
            }
        })
        return referralName;
    }

    const editLeadHandler = (leadid) => {
        props.history.push({pathname: '/admin/editlead', state: {leadid}})
    }

    //validation
    const RemarksSchema = Yup.object().shape({
        comments: Yup.array()
            .of(
                Yup.object().shape({
                    remark: Yup.string().required('Required')
                })
            )
    });

    const updateRemarksHandler = (leadid) => {
        props.history.push({pathname: '/admin/leadsremarks', state: {leadid}})
    }

    const getAddedByNameHandler = (uid) => {
        const name = userNames.map((users) => {
            if(users.id === uid){
                return(users.firstName + ' ' + users.lastName)
            }
        })
        return name;
    }
      


    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <h3 className="text-white mt-3 mb-3">Leads</h3>
                </div>
                <table class="table table-responsive-md table-hover table-dark m-0">
                <thead>
                    <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Program Name</th>
                    <th scope="col">Referred By</th>
                    <th scope="col">Date</th>
                    <th scope="col" className="text-center">Stage</th>
                    </tr>
                </thead>
                <tbody>

                                <tr>
                                <td style={{width: "15%"}}><span className="text-capitalize">{lead.firstName} {lead.lastName}</span>
                                    <br />
                                    <small>
                                        {lead.email}<br />
                                        {lead.phoneNumber}<br />
                                    </small>
                                </td>
                                <td style={{width: "25%"}}>{getProgramNameHandler(lead.programId)}</td>
                                <td>{lead.ref ? getReferralNameHandler(lead.ref) : 
                                <Button variant="link" className="btn-link"   onClick={() => {editLeadHandler(lead.leadsId)}}>Update</Button>
                                }</td>
                                <td>{lead.timestamp ? lead.timestamp.toDate().toDateString() : null}</td>
                                <td className="text-center">
                                    {lead.stage ? lead.stage : 'Uncontacted'}
                                </td>

                                </tr>
                                </tbody>
            </table>
            <div className="bg-dark p-3 text-white">
                    <>
                    {
                        lead.comments &&
                   
                    <div className="row">
                        <div className="col col-sm-3">
                            <span className="text-white">Date</span>
                        </div>
                        <div className="col col-sm-6">
                            <span>Remark</span>
                        </div>
                        <div className="col col-sm-3">
                            <span className="text-white">Added By</span>
                        </div>
                    </div>
                    }
                    {
                        lead.comments && lead.comments.map((comment) => (
                            <div className="row my-3">
                                <div className="col col-sm-3">
                                    <span>{moment(comment.time).format('LL')}</span>
                                </div>
                                <div className="col col-sm8">
                                    <span>{comment.remark}</span>
                                </div>
                                <div className="col col-sm-3">
                                    <span>{comment.addedBy ? getAddedByNameHandler(comment.addedBy) : ''}</span>
                                </div>
                            </div>
                        ))
                    }
                    </>
            </div>
            <div className="text-center">
                <Button variant="link" className="btn-link mt-2"  onClick={() => updateRemarksHandler(lead.leadId)}>Update Remarks</Button>
            </div>
            </div>
            <AcademyFooter />
        </>
    );
}


export default AllLeadsComments;
