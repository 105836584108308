import React, { useEffect } from 'react';
import {Modal, Button} from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import firebase from '../../firebase';

function MyVerticallyCenteredModal(props) {
    const ModalSchema = Yup.object().shape({
        hasAccess: Yup.string().required('Select a value'),
        canSeeOtherUsers: Yup.string().required('Select a value')
      });
    return (
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          Edit Permissions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h3>{props.firstName}</h3>
            <h3>{props.lastName}</h3>
        <Formik
            initialValues={{
                hasAccess: props.hasAccess,
                canSeeOtherUsers: props.canSeeOtherUsers
            }}
            validationSchema={ModalSchema}
            onSubmit={values => {
                //converting strings to booleans
                const hasAccess = values.hasAccess === 'true' ? true : false;
                const canSeeOtherUsers = values.canSeeOtherUsers === 'true' ? true : false;
                firebase.firestore().collection('academyUsers').doc(props.id).update({
                    hasAccess,
                    canSeeOtherUsers
                }).then(() => {
                    props.setModal(false);
                    props.hide(false)
                })
            }}
            >
            {({ errors, touched }) => (
                            <Form>
                            <label htmlFor="hasaccess">Has Access</label>
                            <Field id="hasaccess" className="form-control" as="select" name="hasAccess">
                                <option value="" disabled selected >Select</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Field>
                            {touched.hasAccess && errors.hasAccess && <div className="text-danger">{errors.hasAccess}</div>}
                            <label htmlFor="cansee">Can see other users</label>
                            <Field id="cansee" className="form-control" as="select" name="canSeeOtherUsers">
                                <option value="" disabled selected >Select</option>
                                <option value="true">True</option>
                                <option value="false">False</option>
                            </Field>
                            {touched.canSeeOtherUsers && errors.canSeeOtherUsers && <div className="text-danger">{errors.canSeeOtherUsers}</div>}
                            <button className="btn btn-primary mt-3 mx-auto" type="submit">Submit</button>
                        </Form>

            )}
        </Formik>

        </Modal.Body>
      </Modal>
    );
  }
  
  export default function ModalUser(props) {
    const [modalShow, setModalShow] = React.useState(false);
    useEffect(() => {
        setModalShow(props.show)
    },[props.show])
    return (
      <>
        <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => {setModalShow(false); props.hide(false)}}
          setModal={setModalShow}
          hide={props.hide}
          id={props.id}
          firstName={props.firstName}
          lastName={props.lastName}
        />
      </>
    );
  }
  