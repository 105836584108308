import React from 'react';
import { Navbar, Nav, NavDropdown, Modal, Button } from 'react-bootstrap'
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';




const Hero = (props) => {
  return (
    <div className="container mt-3">
        <h2 className="text-white font-weight-light text-capitalize" >Hi {props.firstName},</h2>
        <h4 className="text-white font-weight-lighter" >Welcome back.</h4>
    </div>
  );
}

export default Hero;