import React, {useState, useEffect} from 'react';
import { Navbar, Nav, NavDropdown, Modal, Button, Card } from 'react-bootstrap'
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import firebase from "../../firebase";
import Axios from 'axios';


const DashboardCount = (props) => {

    // count programs 
    const [activePrograms, setActivePrograms] = useState(0);
    const [loadingProgramList, setLoadingProgramList] = useState(true);
    const [faculty, setFaculty] = useState();
    const [loadingFaculty, setLoadingFaculty] = useState(true);
    const [leads, setLeads] = useState([]);
    const [loadingLeads, setLoadingLeads] = useState(true);
    const [todaysAction, setTodaysAction] = useState([]);
    useEffect(() => {
        let programListRef = firebase.firestore().collection('programListTests');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                snapshot.forEach(doc => {
                    if(doc.data().position > 0){
                        setActivePrograms((prev) => prev + 1)
                    }
                });
                setLoadingProgramList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    // count faculty
    useEffect(() => {
        let programListRef = firebase.firestore().collection('faculty');
        programListRef.get().then(snapshot => {
            let tempFaculty = [];

            snapshot.forEach(doc => {
                tempFaculty.push(doc.data());
            });
            setFaculty(tempFaculty);
            setLoadingFaculty(false);
        })
        .catch(err => {
            console.log('Error getting documents', err);
        });
    }, [])


    // count leads
    useEffect(() => {
        let leadsRef = firebase.firestore().collection('leads');
        leadsRef.get()
            .then(snapshot => {
                let tempLeadsList = [];
                snapshot.forEach(doc => {
                    tempLeadsList.push({ leadsId: doc.id, ...doc.data() });
                });
                setLeads(tempLeadsList);
                setLoadingLeads(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    const [paymentsMade, setPaymentsMade] = useState([]);

    // Getting Successful payments made
    useEffect(() => {
        Axios.get(`https://api.igesia.com/orders/success`)
        .then((res) => {
            const tempPaymentsMade = []
            res.data.data.map((enrolled) => {
                if(enrolled.order.lead.email !== "me@iarani.com"){
                    tempPaymentsMade.push(res.data.data);
                }
            })
            setPaymentsMade(tempPaymentsMade);
        })
    }, [])

    useEffect(() => {
        const tempLeads = [];
        leads.map((lead) => {
            if(lead.stage !== "Not interested"  && lead.nextdoa && lead.nextdoa.toDate().toDateString() === new Date().toDateString() && !lead.hidden){
                tempLeads.push(lead);
            }
        })
        setTodaysAction(tempLeads)
    },[leads])





    return (
        <div className="container mt-4">
        <div className="row justify-content-center">

                <div className="col-lg-2 col-md-6 mb-5 mb-lg-3">
                    <Card className="bg-primary text-white card-body d-flex align-items-start flex-column text-center class h-100" style={{ padding: '0.75rem'}}>
                        <Card.Title className="mx-auto h6">Active Programs</Card.Title>
                        <Card.Text className="w-100">
                            <h1 className="">{!loadingProgramList ? activePrograms : null}</h1>
                        </Card.Text>
                    </Card>
                </div>

            <div className="col-lg-2 col-md-6 mb-5 mb-lg-3">
                <Card className="bg-primary text-white card-body d-flex align-items-start flex-column text-center class h-100" style={{ padding: '0.75rem'}}>
                    <Card.Title className="mx-auto h6">Faculty</Card.Title>
                    <Card.Text className="w-100">
                        <h1 className="">{!loadingFaculty ? faculty.length : null}</h1>
                    </Card.Text>
                </Card>
            </div>
            <div className="col-lg-2 col-md-6 mb-5 mb-lg-3">
                <Card className="bg-primary text-white card-body d-flex align-items-start flex-column text-center class h-100" style={{ padding: '0.75rem'}}>
                    <Card.Title className="mx-auto h6">Leads</Card.Title>
                    <Card.Text className="w-100">
                        <h1 className="">{!loadingLeads ? leads.length : null}</h1>
                    </Card.Text>
                </Card>
            </div>
            <div className="col-lg-2 col-md-6 mb-5 mb-lg-3">
                <Card className="bg-primary text-white card-body d-flex align-items-start flex-column text-center class h-100" style={{ padding: '0.75rem'}}>
                    <Card.Title className="mx-auto h6">Enrolled Users</Card.Title>
                    <Card.Text className="w-100">
                        <h1 className="">{!loadingLeads ? paymentsMade.length : null}</h1>
                    </Card.Text>
                </Card>
            </div>
            <div className="col-lg-2 col-md-6 mb-5 mb-lg-3">
                <Card className="bg-primary text-white card-body d-flex align-items-start flex-column text-center class h-100" style={{ padding: '0.75rem'}}>
                    <Card.Title className="mx-auto h6">Today's Tasks</Card.Title>
                    <Card.Text className="w-100">
                        <h1 className="">{!loadingLeads ? todaysAction.length : null}</h1>
                    </Card.Text>
                </Card>
            </div>
        </div>
        </div>
    );
}

export default DashboardCount;