import React, { useCallback, useContext } from "react";
import { withRouter, Redirect } from "react-router";
import firebase from "../../firebase";
import { AuthContext } from "./Auth.js";
import AcademyNavbar from './AcademyNavbar'

const Reset = ({ history }) => {
  const handleReset = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      try {
        await firebase
          .auth()
          .sendPasswordResetEmail(email.value)
          .then(() => {
              alert("Password reset link has been sent to your email");
              history.push('/login/')
          })
          .catch(function(error) {
            // An error happened.
          });
      } catch (error) {
        alert(error);
      }
    },
    [history]
  );


  const { currentUser } = useContext(AuthContext);

//   if user is already logged in
  if (currentUser) {
    return <Redirect to="/dashboard/" />;
  }

  return (
    <>
    <AcademyNavbar />
    <div className="container text-white d-flex" style={{height: '100vh', alignItems: 'center'}}>
      <div className="row justify-content-center" style={{flex: '1'}}>
        <div className="col-12 col-md-8 col-lg-8 col-xl-6">
          <h1>Log in</h1>
          <form onSubmit={handleReset}>
            <div class="form-group">
              <label for="inputEmail">Email address</label>
              <input type="email" name="email" class="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Enter email" />
            </div>
            <div className="d-flex justify-content-around">
              <button className="btn btn-primary" type="submit">Reset</button>
            </div>
          </form>

        </div>
      </div>
    </div>
    </>
  );
};

export default withRouter(Reset);