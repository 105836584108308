import React from 'react';
import { Navbar, Nav, NavDropdown, Modal, Button } from 'react-bootstrap'
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import firebase from "../../firebase";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body className="pb-5">
        <h5 className="mb-3 ml-2">Suggested Language and Region</h5>
        <div className="row mb-5">
          <div className="col">
            <Button
              className="px-3 py-2" style={{ width: "150px", lineHeight: "1", textAlign: "left", border: 0 }}
              variant="outline-dark"
              onClick={props.onHide}>India<br />
              <small className="text-secondary">English</small>
            </Button>
          </div>
        </div>
        <h5 className="mb-3 ml-2">Select Language and Region</h5>
        <div className="row">
          <div className="col">
            <Button
              className="px-3 py-2" style={{ width: "150px", lineHeight: "1", textAlign: "left", border: 0 }}
              variant="outline-dark"
              onClick={props.onHide}>India<br />
              <small className="text-secondary">English</small>
            </Button>
          </div>
          <div className="col">
            <Button
              className="px-3 py-2" style={{ width: "150px", lineHeight: "1", textAlign: "left", border: 0 }}
              variant="outline-dark"
              onClick={props.onHide}>Brazil<br />
              <small className="text-secondary">English</small>
            </Button>
          </div>
          <div className="col">
            <Button
              className="px-3 py-2" style={{ width: "150px", lineHeight: "1", textAlign: "left", border: 0 }}
              variant="outline-dark"
              onClick={props.onHide}>Brazil<br />
              <small className="text-secondary">Portuguese</small>
            </Button>
          </div>
          <div className="col">
            <Button
              className="px-3 py-2" style={{ width: "150px", lineHeight: "1", textAlign: "left", border: 0 }}
              variant="outline-dark"
              onClick={props.onHide}>USA<br />
              <small className="text-secondary">English</small>
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function RegionSelector() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <span onClick={() => setModalShow(true)}>
        India (English)
      </span>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}


function AcademyNavbar() {
  return (
    <div className="container-fluid">
      <div className="container">
        <Navbar bg="black" variant="dark" expand="lg" className="px-0">
          <Navbar.Brand href="/"><img src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fedited.svg?alt=media&token=11473d04-0b59-43e5-b4f3-3c66798c0ad3"
            width="120"
          /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              {/* <Link class="nav-link" to="/home">Home</Link>
              <Link class="nav-link" to="/academy">Academy</Link>
              <Link class="nav-link" to="/insights">Insights</Link> */}
            </Nav>
            <Nav>
              <Navbar.Brand><img src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac"
              width="160"
            /></Navbar.Brand>
              {/* <NavDropdown title={<FontAwesomeIcon icon={faGlobe} />} id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1"><RegionSelector /></NavDropdown.Item>
              </NavDropdown> */}
              {/* <a class="nav-link" href="https://web.igesia.co" target="_blank">Console</a> */}
            </Nav>
          </Navbar.Collapse>
        </Navbar>

      </div>
    </div>
  )
}

export default AcademyNavbar;