import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import Axios from 'axios';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import moment from 'moment';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const EnrolledUsers = (props) => {
    const [paymentsMade, setPaymentsMade] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [referrals, setReferrals] = useState([]);

    // Getting Successful payments made
    useEffect(() => {
        Axios.get(`https://api.igesia.com/orders/success`)
            .then((res) => {
                setPaymentsMade(res.data.data);
            })
    }, [])

    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    // Getting referrals
    useEffect(() => {
        let referralsRef = firebase.firestore().collection('referrals');
        referralsRef.get()
            .then(snapshot => {
                let tempReferrals = [];
                snapshot.forEach(doc => {
                    tempReferrals.push(doc.data());
                });
                setReferrals(tempReferrals);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])


    const dateConvert = (dateString) => {
        const date = new Date(dateString);
        return moment(date).format('LLL');
    }
    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if (program.programId === id) {
                programName = program.programName;
            }
        })
        return programName;
    }
    let count = 0;
    const getNumber = () => {
        return ++ count;
    }
    const getReferralNameHandler = (id) => {
        let referralName = '';
        referrals.forEach((referral) => {
            if(referral.email === id){
                referralName = referral.firstName + " " + referral.lastName;
            }
        })
        return referralName;
    }


    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <h3 className="text-white mt-3 mb-3">Enrolled Users</h3>
                <table class="table table-responsive-md table-hover table-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col" style={{ width: '20%' }} >Program Name</th>
                            <th scope="col">Company Details</th>
                            <th scope="col">Transaction Details</th>
                            <th scope="col">Transaction Time</th>
                            <th scope="col">Referred By</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            paymentsMade.map((transaction, index) => {
                                if (transaction.order.lead.email !== "me@iarani.com") {
                                    return (
                                        <tr key={index}>
                                            <th scope="row">{getNumber()}</th>
                                            <th scope="row">{index + 1}</th>
                                            <td>{transaction.order.lead.first_name} {transaction.order.lead.last_name}
                                                <br />
                                                <small>
                                                    {transaction.order.lead.email}<br />
                                                    {transaction.order.lead.mobile}<br />
                                                    {transaction.order.lead.experience} yrs
                                                </small>
                                            </td>
                                            <td>
                                                {
                                                    transaction.order.suborders.map((suborder) => {
                                                        return(
                                                            <li>
                                                                {getProgramNameHandler(suborder.product_id)}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </td>
                                            <td>{transaction.order.lead.company_name} <br />
                                                <small className="font-weight-bold">{transaction.order.lead.designation}</small> <br />
                                                <small>{transaction.order.lead.business_unit} </small>
                                            </td>
                                            <td>₹ {transaction.transaction_amount / 100}
                                                <br />
                                                <small>{transaction.transaction_remarks}</small>
                                                <br />
                                                <small className="text-secondary">Order ID: {transaction.order.order_id}</small>
                                            </td>
                                            <td>{dateConvert(transaction.createdAt)}</td>
                                            <td>{(transaction.order.lead.referred_by === "Others") ? "Others" : getReferralNameHandler(transaction.order.lead.referred_by)}</td>
                                        </tr>
                                    )

                                }
                            })
                        }
                    </tbody>
                </table>
            </div>
            <AcademyFooter />
        </>
    );
}


export default EnrolledUsers;
