import React, {useContext, useRef, useEffect, useState} from 'react';
import { Navbar, Nav, NavDropdown, Modal, Button } from 'react-bootstrap'
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import firebase from "../../../firebase";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import _ from 'lodash';


const LeadsGraph = (props) => {
    const chart = useRef(null);
    const [leads, setLeads] = useState([]);
    const [programs, setPrograms] = useState([]);


    // Getting leads
    useEffect(() => {
        let leadsRef = firebase.firestore().collection('leads').orderBy("timestamp", "desc");
        leadsRef.get()
            .then(snapshot => {
                let tempLeadsList = [];
                snapshot.forEach(doc => {
                    tempLeadsList.push({ leadsId: doc.id, ...doc.data() });
                });
                setLeads(tempLeadsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    // theme
    function am4themes_darkTheme(target) {
        if (target instanceof am4core.InterfaceColorSet) {
            target.setFor("text", am4core.color("#ffffff"));
            target.setFor("grid", am4core.color("#ffffff"));
        }
    }

    //graph
    useEffect(() => {
      am4core.useTheme(am4themes_darkTheme);
      let x = am4core.create("chartdiv", am4charts.XYChart);
  
      x.paddingRight = 20;
  
      let data = [];

      leads.forEach((lead) => {
          data.push({date: lead.timestamp.toDate().toDateString()})
      })
      const result = _.countBy(data, "date" );
      console.log(result);

      const resultArr = Object.keys(result).map((key) => ({date: new Date(key), value: result[key]}))
      console.log(resultArr);
    //   leads.forEach((lead, index) => {
    //     data.push({ date: lead.timestamp.toDate(), name: lead.firstName + lead.lastName, value: 1 });
    //     console.log(data);
    //   })
  
      x.data = resultArr;
      let dateAxis = x.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 0;
  
      let valueAxis = x.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
  
      let series = x.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";
      series.tooltipText = "{valueY.value}";
      x.cursor = new am4charts.XYCursor();
  
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      x.scrollbarX = scrollbarX;
  
      chart.current = x;


      let label = x.createChild(am4core.Label);
      label.text = "Leads";
      label.fontSize = 20;
      label.align = "center";

  
      return () => {
        x.dispose();
      };
    }, [leads]);

    return (
        <div id="chartdiv" style={{ width: "50%", height: "300px", display: "inline-block" }}></div>
    );
}

export default LeadsGraph;