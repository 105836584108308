import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";
import {enIN} from "date-fns/locale";
registerLocale("enIN", enIN);

export const DatePickerLeadsComments = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DatePicker
      {...field}
      {...props}
      dateFormat="dd/MM/yyyy"
      placeholderText="Select a date" 
      className="form-control"
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val);
      }}
    />
  );
};

export default DatePickerLeadsComments;