import React, { useEffect, useState } from 'react';
import Axios from 'axios';

const ProgramPDF = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }
    // useEffect(() =>{
    //     Axios.get(`
    //     https://pdfapi.screenshotmachine.com/?key=d8564d&url=https%3A%2F%2Fcii.igesia.co%2F%23%2Fprogram%2F${props.match.params.id}&paper=letter&orientation=portrait&media=print&bg=bg&delay=2000&scale=100`)
    //     .then((res) => {
    //         console.log(res);
    //     })
    // })
    useEffect(() => {
        window.location.href=`https://pdfapi.screenshotmachine.com/?key=d8564d&url=https%3A%2F%2Fcii.igesia.co%2F%23%2Fpdf%2F${props.match.params.id}&paper=letter&orientation=portrait&media=print&bg=bg&delay=2000&scale=100`
    },[])
    return(
        <div class="container d-flex align-items-center" style={{minWidth: '100vw', minHeight: '100vh'}}>
            <div class="spinner-border text-light mx-auto" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    );
}

export default ProgramPDF;