import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import * as Yup from 'yup';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const AddLead = (props) => {
    const [programs, setPrograms] = useState([]);
    const [referrals, setReferrals] = useState([]);

    const [currentLeadID, setCurrentLeadID] = useState('');
    const [stage, setStage] = useState('');
    const [modalShow, setModalShow] = useState(false);

    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    // Getting referrals
    useEffect(() => {
        let referralsRef = firebase.firestore().collection('referrals');
        referralsRef.get()
            .then(snapshot => {
                let tempReferrals = [];
                snapshot.forEach(doc => {
                    tempReferrals.push(doc.data());
                });
                setReferrals(tempReferrals);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }
    
    const getReferralNameHandler = (id) => {
        let referralName = '';
        referrals.forEach((referral) => {
            if(referral.ref === id){
                referralName = referral.firstName + " " + referral.lastName;
            }
        })
        return referralName;
    }

    const leadStageHandler = () => {
        if(stage.trim() !== '' && currentLeadID.trim !== ''){
            firebase.firestore().collection('leads').doc(currentLeadID).update({
                stage: stage
            })
            .then(() => {
                setModalShow(false);
            })
        }
    }

    //validation
    const LeadSchema = Yup.object().shape({
        // email: Yup.string().required('Required'),
        firstName: Yup.string().required('Required'),
        // lastName: Yup.string().required('Required'),
        // phoneNumber: Yup.string().required('Required'),
        programId: Yup.string().required('Required'),
        // ref: Yup.string().required('Required'),
    });

    // error messages
    const ErrorMessages = ({ name }) => (
        <Field
        name={name}
        render={({ form }) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            return touch && error ? <div className="text-danger ml-2">{error}</div> : null;
        }}
        />
    );




      


    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <h3 className="text-white mt-3 mb-3">Add a lead</h3>
                </div>
            <Formik
            validationSchema={LeadSchema}
            initialValues={{
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                programId: '',
                programType: 'Executive Program',
                ref: '',
                timestamp: new Date()
            }}
            onSubmit={(values, { resetForm }) => {
                firebase.firestore().collection('leads').add(values).then(() => {
                    alert('Lead Added!');
                    resetForm();
                    props.history.push('/admin/leads/')
                })
            }}
            >
                {({ errors, touched, isValidating, values }) => (
                <Form className={'container-fluid py-2 program-content-web'}>
                    <div className="container-fluid bg-black pt-0 pt-md-2">
                        <div className="container">
                            <div className="form-row mt-3">
                                <div className="form-label-group col-6">
                                    <Field
                                    name="firstName"
                                    placeholder="First Name"
                                    type="text"
                                    className='form-control'
                                    />
                                    <label for={`firstName`} className="mb-1">First name</label>
                                    {errors.firstName && touched.firstName ? (
                                        <div className="text-danger ml-2">{errors.firstName}</div>
                                    ) : null}
                                </div>
                                <div className="form-label-group col-6">
                                    <Field
                                    name={`lastName`}
                                    placeholder="Last Name"
                                    type="text"
                                    className='form-control'
                                    />
                                    <label for={`lastName`} className="mb-1">Last name</label>
                                    {errors.lastName && touched.lastName ? (
                                        <div className="text-danger ml-2">{errors.lastName}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <div className="form-label-group col-6">
                                    <Field
                                    name="phoneNumber"
                                    placeholder="Phone Number"
                                    type="text"
                                    className='form-control'
                                    />
                                    <label for={`phoneNumber`} className="mb-1">Phone Number</label>
                                    {errors.phoneNumber && touched.phoneNumber ? (
                                        <div className="text-danger ml-2">{errors.phoneNumber}</div>
                                    ) : null}
                                </div>
                                <div className="form-label-group col-6">
                                    <Field
                                    name={`email`}
                                    placeholder="Email"
                                    type="text"
                                    className='form-control'
                                    />
                                    <label for={`email`} className="mb-1">Email</label>
                                    {errors.email && touched.email ? (
                                        <div className="text-danger ml-2">{errors.email}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-row mt-3">
                                <div className="form-label-group col-6">
                                    <Field as="select" className={"form-control mb-2 custom-select"  + (errors.ref && touched.ref ? ' is-invalid' : '')} name="ref" >
                                        <option value="" disabled selected>Referred By</option>
                                        {
                                            referrals.map((referral) => (
                                                <option value={referral.ref}>{referral.firstName} {referral.lastName}</option>
                                            ))
                                        }
                                    </Field>
                                    {errors.ref && touched.ref ? (
                                    <div className="text-danger ml-2">{errors.ref}</div>
                                    ) : null}
                                </div>
                                <div className="form-label-group col-6">
                                    <Field as="select" className={"form-control mb-2 custom-select"  + (errors.programId && touched.programId ? ' is-invalid' : '')} name="programId" >
                                        <option value="" disabled selected>Program</option>
                                        {
                                            programs.map((program) => (
                                                <option value={program.programId}>{program.programName}</option>
                                            ))
                                        }
                                    </Field>
                                    {errors.programId && touched.programId ? (
                                    <div className="text-danger ml-2">{errors.programId}</div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{textAlign: 'center'}}> 
                        <button type="submit" className="btn btn-primary mt-3">Add Lead</button>
                    </div>
                </Form>
                )}
            </Formik>

            </div>
            <AcademyFooter />
        </>
    );
}


export default AddLead;
