import React, { useEffect, useState } from "react";
import firebase from "../../firebase";

export const AuthContext = React.createContext();
export const AccessContext = React.createContext();
export const NameContext = React.createContext();
export const CanSeeOtherUsersContext = React.createContext();
export const LeadsAccessContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [hasAccess, setHasAccess] = useState(false);
  const [name, setName] = useState(null);
  const [canSeeOtherUsers, setCanSeeOtherUsers] = useState(false);
  const [leadsAccess, setLeadsAccess] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if(user){
        user.getIdTokenResult().then(idTokenResult => {
          firebase.firestore().collection('academyUsers').doc(idTokenResult.claims.user_id).get()
          .then((doc) => {
            if(doc.data().hasAccess && doc.data().canSeeOtherUsers){
              //it has access
              // so logging in
              // super admin
              // alert('super')
              setCurrentUser(user)
              setHasAccess(true)
              setPending(false)
              setName(doc.data().firstName)
              setCanSeeOtherUsers(true);
              setLeadsAccess(true);
            }
            else if (doc.data().hasAccess && !doc.data().canSeeOtherUsers){
              // admin or sales person with all access
              // alert('sales')
              setCurrentUser(user)
              setHasAccess(true)
              setPending(false)
              setName(doc.data().firstName)
              setCanSeeOtherUsers(false);
              setLeadsAccess(true);
            }
            else if(!doc.data().hasAccess && !doc.data().canSeeOtherUsers && doc.data().onlyLeadsAccess){
              //it doesnt have access
              // user with just leads access
              // alert('onlyleads')
              setCurrentUser(user)
              setHasAccess(false)
              setPending(false)
              setName(doc.data().firstName)
              setCanSeeOtherUsers(false);
              setLeadsAccess(true);
            }
            else if(!doc.data().hasAccess && !doc.data().canSeeOtherUsers && !doc.data().onlyLeadsAccess){
              //it doesnt have access
              // normal user
              // alert('user')
              setCurrentUser(user)
              setHasAccess(false)
              setPending(false)
              setName(doc.data().firstName)
              setCanSeeOtherUsers(false);
              setLeadsAccess(false);
            }
          })
          .catch((err) => {
            // if error with getting document from firestore or some other firestore related error
            // setting pending to false so that user can view the website
            console.log(err);
            setPending(false);
            firebase.auth().signOut().then(() => console.log('signed out'));
            window.localStorage.clear();
          })
        })
      }else{
        console.log('error cant login')
        setCurrentUser(null)
        setHasAccess(false)
        setPending(false)
        setName(null)
        setCanSeeOtherUsers(false);
        setLeadsAccess(false);
      }
    });
  }, []);

  if(pending){
    return <></>
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser
      }}
    >
      <AccessContext.Provider
        value={hasAccess}
      >
        <NameContext.Provider
          value={name}
        >
          <CanSeeOtherUsersContext.Provider
            value={canSeeOtherUsers}  
          >
          <LeadsAccessContext.Provider
            value={leadsAccess}  
          >
            {children}
            </LeadsAccessContext.Provider>
          </CanSeeOtherUsersContext.Provider>
        </NameContext.Provider>
      </AccessContext.Provider>
    </AuthContext.Provider>
  );
};