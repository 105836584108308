import React, { useEffect, useState, useRef } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge,
    Toast
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import _, { indexOf } from 'lodash';
import DatePicker, { registerLocale } from "react-datepicker";
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import DatePickerLeadsComments from './DatePickerLeadsComments';
import * as Yup from 'yup';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {connect} from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes';
import ReactExport from "react-export-excel";

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const Leads = (props) => {
    const [leads, setLeads] = useState([]);
    const [leadsDB, setLeadsDB] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const [currentLeadID, setCurrentLeadID] = useState('');
    const [stage, setStage] = useState('');
    const [nextAction, setNextAction] = useState('');
    const [userNames, setUserNames] = useState([]);
    // const [remarks, setRemarks] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [nextStageModalShow, setNextStageModalShow] = useState(false);
    const [subHeader, setSubHeader] = useState(false);

    // toasts
    const [showA, setShowA] = useState(true);
    const [showB, setShowB] = useState(true);
    const toggleShowA = () => setShowA(!showA);
    const toggleShowB = () => setShowB(!showB);

    const [followUpCount, setFollowUpCount] = useState();
    const [contactCount, setContactCount] = useState();
    
    // filtering states
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterProgramId, setFilterProgramId] = useState([]);
    const [selectedProgramOption, setSelectedProgramOption] = useState();
    const [filterStage, setFilterStage] = useState([
        {value: "Uncontacted" , label: 'Uncontacted'},
        {value: "Contacted" , label: 'Contacted'},
        {value: "Not interested" , label: 'Not interested'},
        {value: "Follow up" , label: 'Follow up'},
        {value: "Deal complete" , label: 'Deal complete'}
    ]);
    const [selectedStageOption, setSelectedStageOption] = useState();
    const [filterReferred, setFilterReferred] = useState();
    const [selectedReferredOption, setSelectedReferredOption] = useState();
    const [filterNextAction, setFilterNextAction] = useState([
        {value: "To Follow Up", label: "To Follow Up"},
        {value: "To Contact", label: "To Contact"},
        {value: "Not Interested", label: "Not Interested"}
    ]);
    const [selectedNextActionOption, setSelectedNextActionOption] = useState();
    const [filterRep, setFilterRep] = useState([
        {value: 'Aroop', label: 'Aroop'},
        {value: 'Natasha', label: 'Natasha'},
        {value: 'Divya', label: 'Divya'},
        {value: 'Tarandeep', label: 'Tarandeep'},
        {value: 'Deepak', label: 'Deepak'}
    ]);
    const [selectedRepOption, setSelectedRepOption] = useState();
    const [filterDate, setFilterDate] = useState(null);

    // Getting leads
    useEffect(() => {
        let leadsRef = firebase.firestore().collection('leads').orderBy("timestamp", "desc");
        leadsRef.get()
            .then(snapshot => {
                let tempLeadsList = [];
                snapshot.forEach(doc => {
                    if(!doc.data().hidden){
                        if(doc.data().stage !== "Not interested"  && doc.data().nextdoa && doc.data().nextdoa.toDate().toDateString() === new Date().toDateString()){
                            tempLeadsList.push({ leadsId: doc.id,todaysAction: true, ...doc.data() });
                        }
                        else if(!doc.data().stage || doc.data().stage === "Uncontacted"){
                            tempLeadsList.push({ leadsId: doc.id,todaysAction: true, ...doc.data() });
                        }
                        else{
                            tempLeadsList.push({ leadsId: doc.id,todaysAction: false, ...doc.data() });
                        }
                    }
                });
                setLeads(tempLeadsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [modalShow,nextStageModalShow])
    // Getting leads DB
    useEffect(() => {
        let leadsRef = firebase.firestore().collection('leads').orderBy("timestamp", "desc");
        leadsRef.get()
            .then(snapshot => {
                let tempLeadsList = [];
                snapshot.forEach(doc => {
                    tempLeadsList.push(doc.data());
                });
                setLeadsDB(tempLeadsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [modalShow,nextStageModalShow])
    // Getting userNames
    useEffect(() => {
        if(leads){
            firebase.firestore().collection('academyUsers').get()
            .then((snapshot) => {
                let tempList = [];
                snapshot.forEach(doc => {
                    tempList.push({id: doc.id, firstName: doc.data().firstName, lastName: doc.data().lastName})
                })
                setUserNames(tempList);
            })
        }
    }, [leads])
    // // sorting by uncontacted
    // useEffect(() => {
    //     if(leads){
    //         const sortedLeads = _.sortBy(leads, ['stage'])
    //         sortedLeads.reverse();
    //         setLeads(sortedLeads);
    //     }
    // },[])

    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                let tempProgramIds = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                    tempProgramIds.push({value: doc.id, label: doc.data().programName});
                });
                setPrograms(tempProgramsList);
                setFilterProgramId(tempProgramIds);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    // Getting referrals
    useEffect(() => {
        let referralsRef = firebase.firestore().collection('referrals');
        referralsRef.get()
            .then(snapshot => {
                let tempReferrals = [];
                let tempReferralsOptions =[];
                snapshot.forEach(doc => {
                    tempReferrals.push(doc.data());
                    tempReferralsOptions.push({value: doc.data().ref, label: doc.data().firstName + ' ' + doc.data().lastName})
                });
                setReferrals(tempReferrals);
                setFilterReferred(tempReferralsOptions);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    // counts for toasts
    useEffect(() => {
        const tempLeadsFollowUpArray = [];
        const tempLeadsContactArray = [];
        leads.map((lead) => {
            if(lead.todaysAction && lead.nextAction === "To Follow Up"){
                tempLeadsFollowUpArray.push(lead);
            }else if(lead.todaysAction && lead.nextAction === "To Contact"){
                tempLeadsContactArray.push(lead);
            }
        })
        setContactCount(tempLeadsContactArray.length);
        setFollowUpCount(tempLeadsFollowUpArray.length);
    },[leads])

    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }
    
    const getReferralNameHandler = (id) => {
        let referralName = '';
        referrals.forEach((referral) => {
            if(referral.ref === id){
                referralName = referral.firstName + " " + referral.lastName;
            }
        })
        return referralName;
    }

    // const leadStageHandler = () => {
    //     if(stage.trim() !== '' && currentLeadID.trim !== ''){
    //         firebase.firestore().collection('leads').doc(currentLeadID).update({
    //             stage: stage
    //         })
    //         .then(() => {
    //             setModalShow(false);
    //         })
    //     }
    // }

    // scroll position

    useEffect(() => {
        window.scrollTo(0, props.currentScrollPosition);
    },[leads])

    //validation
    const StageSchema = Yup.object().shape({
        stage: Yup.string().required('Required') 
    });

    //validation
    const NextActionSchema = Yup.object().shape({
        nextAction: Yup.string().required('Required') 
    });





    // modal
    function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
          >
            <Modal.Header closeButton className="bg-dark text-white font-weight-light modal-custom">
              <Modal.Title id="contained-modal-title-vcenter" className="font-weight-normal">
                Update Stage
              </Modal.Title>
            </Modal.Header>
            <Modal.Body  className="bg-dark text-white font-weight-normal">
            <Formik
            validationSchema={StageSchema}
            initialValues = {{
                stage: stage,
                doa: new Date()
            }}
            onSubmit={(values, { resetForm }) => {
                firebase.firestore().collection('leads').doc(currentLeadID).update({
                    stage: values.stage,
                    doa: values.doa
                })
                .then(() => {
                    setModalShow(false);
                })
            }}
            >
                {({ errors, touched, isValidating, values }) => (
                <Form className="bg-dark p-3">
                            <div>
                                <>
                                    <div className="form-group row" style={{display: 'flex', alignItems: 'center'}}>
                                        <div className="col-sm-12 mb-3">
                                            <label for="stage" className="mb-1">Stage</label>
                                            <Field
                                            name={`stage`}
                                            id={`stage`}
                                            placeholder="Remark"
                                            as="select"
                                            className='custom-select'
                                            >
                                            <option disabled  value="">Please select</option>
                                            <option value="Uncontacted">Uncontacted</option>
                                            <option value="Contacted">Contacted</option>
                                            <option value="Not interested">Not interested</option>
                                            <option value="Follow up">Follow up</option>
                                            <option value="Deal complete">Deal complete</option>
                                            </Field>
                                            {errors.stage && touched.stage ? (
                                            <div className="text-danger ml-2">{errors.stage}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-12 text-white">
                                            <label for="doa" className="mb-1">Date of action</label>
                                            <DatePickerLeadsComments  name={`doa`} id="doa" />
                                        </div>
                                    </div>
                            </>

                            </div>

                    <div style={{textAlign: 'center'}}> 
                        <button type="submit" className="btn btn-primary mt-3 btn-sm">Update Stage</button>
                    </div>
                </Form>
                )}
            </Formik>
            </Modal.Body>
          </Modal>
        );
    }

    // modal next action
    function AssignRepVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
          >
            <Modal.Header closeButton className="bg-dark text-white font-weight-light modal-custom">
              <Modal.Title id="contained-modal-title-vcenter" className="font-weight-normal">
                Assign Representative
              </Modal.Title>
            </Modal.Header>
            <Modal.Body  className="bg-dark text-white font-weight-normal">
            <Formik
            initialValues = {{
                assignedRep: '',
            }}
            onSubmit={(values, { resetForm }) => {
                firebase.firestore().collection('leads').doc(currentLeadID).update({
                    assignedRep: values.assignedRep,
                })
                .then(() => {
                    setNextStageModalShow(false);
                })
            }}
            >
                {({ errors, touched, isValidating, values }) => (
                <Form className="bg-dark p-3">
                            <div>
                                    <div className="form-group row" style={{display: 'flex', alignItems: 'center'}}>
                                        <div className="col-sm-12 mb-3">
                                            <label for="assignedRep" className="mb-1">Assign</label>
                                            <Field
                                            name={`assignedRep`}
                                            id={`assignedRep`}
                                            placeholder="Assign Rep"
                                            as="select"
                                            className='custom-select'
                                            >
                                            <option disabled  value="">Please select</option>
                                            <option value="9lFlnMaPNde0t011WoHI9D7sYmw1">Aroop</option>
                                            <option value="aL8x0wGY7gcEIGdqTxei2Q7xeSw2">Natasha</option>
                                            <option value="fSrHAHRdJYbpSSgvWlEB60aMS8g2">Divya</option>
                                            <option value="JAbBUSRQ6CgAPyL05EayxqCtZ7r1">Tarandeep</option>
                                            <option value="rxHugI3r8AWlz6sPGaW5D6hzQPJ3">Deepak</option>
                                            </Field>
                                        </div>
                                    </div>
                            </div>

                    <div style={{textAlign: 'center'}}> 
                        <button type="submit" className="btn btn-primary mt-3 btn-sm">Assign</button>
                    </div>
                </Form>
                )}
            </Formik>
            </Modal.Body>
          </Modal>
        );
    }



    const editLeadHandler = (leadid) => {
        props.onPageChange(window.pageYOffset);
        props.history.push({pathname: '/admin/editlead', state: {leadid}});
    }

    const updateRemarksHandler = (leadid) => {
        props.history.push({pathname: '/admin/leadsremarks', state: {leadid}})
    }
    const allRemarksHandler = (leadid) => {
        props.onPageChange(window.pageYOffset);
        props.history.push({pathname: '/admin/allleadsremarks', state: {leadid}})
    }
    
    const commentsHandler = (comments) => {
        const timeConverted = comments.map((comment) => {
            comment.time = comment.time;
            return(comment)
        })
        const sorted = _.orderBy(timeConverted, 'time', 'desc');
        return sorted[0];
    }

    const hideLeadHandler = (leadid) => {
        firebase.firestore().collection('leads').doc(leadid).update({
            hidden: true,
        })
        .then(()=> {
            window.location.reload();
        })
    }

    const getAddedByNameHandler = (uid) => {
        let name = '';
        userNames.forEach((users) => {
            if(users.id === uid){
                name = users.firstName;
            }
        })
        return name;
    }



    const resetCount = () => {
        // count = 0;
        let baseCount;
        if(props.pageNo === 1){
            baseCount = 0;
            document.querySelector('.rdt_TableBody').childNodes.forEach((c, i) => {c.childNodes[0].innerHTML = baseCount + ++i})
        }else{
            const baseCount = props.pageNo * 10 - 10;
            document.querySelector('.rdt_TableBody').childNodes.forEach((c, i) => {c.childNodes[0].innerHTML = baseCount + ++i})
        }

    }

    const changePageHandler = (e) => {
        props.onPageSet(e);
        resetCount();
    }

    useEffect(() => {
        if(document.querySelector('.rdt_TableBody')){
            resetCount()
        }
        
    })
    // const onPageCount = () => {
    //     count += 10;
    // }




      
    const columns = [
        {
            name: '#',
            minWidth: '1rem',
            maxWidth: '4rem',
            cell: lead => <CustomCount lead={lead} />
        },
        {
            name: '',
            minWidth: '0.5rem',
            maxWidth: '1.3rem',
            style:{padding: 0},
            selector: 'todaysAction',
            sortable: true,
            cell: lead => <CustomTodaysAction lead={lead} />
        },
        {
            name: 'Personal Details',
            selector: 'firstName',
            sortable: true,
            width: '10rem',
            cell: lead => <CustomDetails lead={lead} />
        },
        {
            name: 'Program Name',
            selector: row => getProgramNameHandler(row.programId),
            sortable: true,
            width: '11rem',
            cell: lead => <CustomProgramName lead={lead} />
        },
        {
            name: 'Referred By',
            selector: 'ref',
            sortable: true,
            cell: lead => <CustomReferredBy lead={lead} />
        },
        {
            name: 'Date',
            selector: 'timestamp',
            sortable: true,
            minWidth: '1rem',
            maxWidth: '5.4rem',
            cell: lead => <CustomDate lead={lead} />
        },
        {
            name: 'Stage',
            selector: 'stage',
            sortable: true,
            center: true,
            style:{padding: '0px'},
            cell: lead => <CustomStage lead={lead} />
        },
        {
            name: 'Next Action',
            selector: 'nextAction',
            sortable: true,
            cell: lead => <CustomNextAction lead={lead} />
        },
        {
            name: 'Remarks',
            cell: lead => <CustomRemarks lead={lead} />
        },
        {
            name: 'Rep',
            selector: row => row.comments ? getAddedByNameHandler(commentsHandler(row.comments).addedBy) : "",
            sortable: true,
            cell: lead => <RepresentedRemarks lead={lead} />
        }
    ];


    // custom cells
    const CustomCount = ({ lead }) => {
        return (<> </>)
    }
    const CustomTodaysAction = ({ lead }) => {
        if(lead.stage !== "Not interested"  && lead.nextdoa && lead.nextdoa.toDate().toDateString() === new Date().toDateString()){
            return(
                <span className="dot dot-success"></span>
            )
        }else{
            return(
                <>
                </>
            )
        }
    }
    const CustomDetails = ({ lead }) => (
        <div className="text-break h6 font-weight-normal pt-2">
            <span className="text-capitalize">{lead.firstName} {lead.lastName}</span>
            <br />
            <small>
                {lead.email}<br />
                {lead.phoneNumber}<br />
            </small>
            <Button variant="link" className="btn-link mt-2 p-0"  onClick={() => hideLeadHandler(lead.leadsId)}>Hide Lead</Button>
        </div>
    );

    const CustomProgramName = ({ lead }) => (
        <div className="text-break h6 font-weight-normal">
            <span className="text-capitalize">{getProgramNameHandler(lead.programId)}</span>
        </div>
    );

    const CustomReferredBy = ({ lead }) => (
        <div className="text-break h6 font-weight-normal">
            {lead.ref ? getReferralNameHandler(lead.ref) : 
            <Button variant="link" className="btn-link"   onClick={() => {editLeadHandler(lead.leadsId)}}>Update</Button>
        }</div>
    )

    const CustomDate = ({ lead }) => (
        <div className="text-break h6 font-weight-normal">
            <span className="text-capitalize">{moment(lead.timestamp.toDate()).format('ll')}</span>
        </div>
    )
    const CustomStage = ({ lead }) => (
        <div className="text-break h6 font-weight-normal text-center">
            {lead.stage ? lead.stage : 'Uncontacted'}
            <br></br>
            <small className="mt-2 d-block">{lead.doa? moment(lead.doa.toDate()).format('ll') : ''}</small>
            <Button variant="link" className="btn-link d-block btn-sm p-0"  onClick={() => {setStage(lead.stage? lead.stage : ''); setCurrentLeadID(lead.leadsId); setModalShow(true);}}>Update Stage</Button>
        </div>
    )

    const CustomNextAction = ({ lead }) => (
        <div className={`text-break h6 font-weight-normal text-center pb-4 `}>
            {lead.nextAction ? lead.nextAction : ''}
            <br></br>
            <small className={(lead.stage !== "Not interested"  && lead.nextdoa && lead.nextdoa.toDate().toDateString() === new Date().toDateString()) ? 'live-today' : ' ' }>{lead.nextdoa ? moment(lead.nextdoa.toDate()).format('ll') : ''}</small>
        </div>
    )

    const CustomRemarks = ({ lead }) => {
        if(lead.stage !== 'Not interested'){
            return(
                <div className="text-break h6 font-weight-normal text-center py-3">
                    <small className="d-block w-75 text-break mx-auto text-secondary overflow-hidden" style={{maxHeight: '25ch'}}>{lead.comments ? commentsHandler(lead.comments).remark : ''}</small>
                    <Button variant="link" className="btn-link mt-2 btn-sm"  onClick={() => allRemarksHandler(lead.leadsId)}>All Remarks</Button>
                </div>
            )
        }else{
            return(
                <>
                </>
            )
        }
    }

    const RepresentedRemarks = ({ lead }) => (
        <div className="text-break h6 font-weight-normal m-0">
            <small className="d-block text-break mx-auto text-capitalize" style={{maxHeight: '25ch'}}>{lead.comments ? getAddedByNameHandler(commentsHandler(lead.comments).addedBy) : lead.assignedRep ?  getAddedByNameHandler(lead.assignedRep) : <Button variant="link" className="btn-link d-block btn-sm p-0" onClick={() => {setCurrentLeadID(lead.leadsId); setNextStageModalShow(true);}}  >Assign Rep</Button>}</small>
        </div>
    )


    // filtering 
    // filtering by first name
    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <div className="col col-md-3 m-2 p-2">
          <input autoFocus id="search" type="text" placeholder="Search By Name" aria-label="Search Input" value={filterText} onChange={onFilter} className="form-control" ></input>
          {/* <Button type="button" className="btn-sm btn" onClick={onClear}>X</Button> */}
        </div>
    );   



    // filtering by program method
    const filterProgramIdHandler = (e) => {
        setSelectedProgramOption(e)
    }

    // fitlering by stage method
    const filterStageHandler = (e) => {
        setSelectedStageOption(e)
    }

    // filtering by referred by
    const filterReferredByHandler = (e) => {
        setSelectedReferredOption(e)
    }

    // filtering by next action
    const filterNextActionHandler = (e) => {
        setSelectedNextActionOption(e)
    }

    // filtering by rep
    const filterRepHandler = (e) => {
        setSelectedRepOption(e)
    }




    let filteredItems = leads;
    filteredItems = filteredItems.filter(item => item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase()) || item.lastName.toLowerCase().includes(filterText.toLowerCase()));

    // filtering programid 
    // selectedProgramOption && selectedProgramOption.map((program) => {
    //     filteredItems = filteredItems.filter((lead) => lead.programId === program.value)
    // })
    if(selectedProgramOption){
        filteredItems = filteredItems.filter((lead) => lead.programId === selectedProgramOption.value)
    }
    if(selectedStageOption){
        if(selectedStageOption.value === "Uncontacted"){
            filteredItems = filteredItems.filter((lead) => !lead.stage || lead.stage === "Uncontacted")
        }else{
            filteredItems = filteredItems.filter((lead) => {
                if(lead.stage && lead.stage === selectedStageOption.value){
                    return lead;
                }
            })
        }

    }
    if(selectedReferredOption){
        filteredItems = filteredItems.filter((lead) => lead.ref === selectedReferredOption.value )
    }
    if(selectedNextActionOption){
        filteredItems = filteredItems.filter((lead) => lead.nextAction === selectedNextActionOption.value )
    }
    if(selectedRepOption){
        filteredItems = filteredItems.filter((lead) => {
            if(lead.comments && commentsHandler(lead.comments).addedBy !== undefined){
                if(getAddedByNameHandler(commentsHandler(lead.comments).addedBy).toLowerCase() === selectedRepOption.value.toLowerCase()){
                    return lead;
                }    
            }
            else if(lead.assignedRep && getAddedByNameHandler(lead.assignedRep).toLowerCase() === selectedRepOption.value.toLowerCase()){
                console.log(lead.assignedRep,"assignedrep ")
                return lead;
            }
            
        })
    }
    if(filterDate){
        filteredItems = filteredItems.filter((lead) => lead.timestamp.toDate().toDateString() === filterDate.toDateString())
    }



    // filtering component
    const subHeaderComponentMemo = React.useMemo(() => {
      const handleClear = () => {
        if (filterText) {
          setResetPaginationToggle(!resetPaginationToggle);
          setFilterText('');
        }
      };

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
      return (
        <>
        <div className="row w-100">
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText}/>
                <Select
                    name="Program-Filter"
                    placeholder="Filter By Program"
                    searchable={true}
                    value={selectedProgramOption}
                    isClearable={true}
                    onChange={(e) => filterProgramIdHandler(e)}
                    options={filterProgramId}
                    className="col col-md-4 m-2 p-2"
                />
                <Select
                name="Stage-Filter"
                placeholder="Filter By Stage"
                value={selectedStageOption}
                isClearable={true}
                onChange={(e) => filterStageHandler(e)}
                options={filterStage}
                className="col col-md-4 m-2 p-2"
                />

                <Select
                    name="Referred-Filter"
                    placeholder="Filter By Referred By"
                    value={selectedReferredOption}
                    isClearable={true}
                    onChange={(e) => filterReferredByHandler(e)}
                    options={filterReferred}
                    className="col col-md-3 m-2 p-2"
                />

                <Select
                    name="Nextaction-Filter"
                    placeholder="Filter By Next Action"
                    value={selectedNextActionOption}
                    isClearable={true}
                    onChange={(e) => filterNextActionHandler(e)}
                    options={filterNextAction}
                    className="col col-md-4 m-2 p-2"
                />

                <Select
                    name="Rep-Filter"
                    placeholder="Filter By Rep"
                    value={selectedRepOption}
                    isClearable={true}
                    onChange={(e) => filterRepHandler(e)}
                    options={filterRep}
                    className="col col-md-4 m-2 p-2"
                />
                <div className="col col-md-3 m-2 p-2 datepickerw100">
                    <DatePicker 
                        selected={filterDate} 
                        onChange={date => setFilterDate(date)} 
                        isClearable
                        placeholderText="Select date"
                        className="form-control"
                    />
                </div>
        </div>

        </>
        );
    }, [filterText, programs, filterProgramId, selectedProgramOption, filterStage, selectedStageOption, filterReferred, selectedReferredOption, filterNextAction, selectedNextActionOption, filterRep, selectedRepOption, filterDate]);


    // pagination options
    const paginationOptions = { paginationRowsPerPageOptions: [10] };

    const ExcelFile = ReactExport.ExcelFile;
    const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <h3 className="text-white mt-3 mb-3">Leads</h3>
                    <div>
                        <button type="button" onClick={() => {setSubHeader((prev) => !prev)}} className="mr-2 btn btn-outline-light btn-sm">Filters</button>
                        <button type="button" onClick={() => {props.history.push('/admin/addlead')}} className="mr-2 btn btn-outline-light btn-sm">Add Lead</button>
                        <ExcelFile element={<button  className="mr-2 btn btn-outline-light btn-sm">Download</button>}>
                            <ExcelSheet data={filteredItems} name="Leads">
                            <ExcelColumn label="Name" value={(col) => col.firstName + ' ' + col.lastName}/>
                            <ExcelColumn label="Email" value={(col) => col.email}/>
                            <ExcelColumn label="Mobile" value={(col) => col.phoneNumber}/>
                            <ExcelColumn label="Date" value={(col) => col.timestamp.toDate()}/>
                            <ExcelColumn label="Program Name" value={(col) => getProgramNameHandler(col.programId)}/>
                            <ExcelColumn label="Referred By" value={(col) => getReferralNameHandler(col.ref)}/>
                            <ExcelColumn label="Stage" value={(col) => col.stage ? col.stage : "Uncontacted"}/>
                            <ExcelColumn label="Representative" value={(col) => col.comments ? getAddedByNameHandler(commentsHandler(col.comments).addedBy) : ''}/>
                            </ExcelSheet>
                        </ExcelFile>
                        <ExcelFile element={<button  className="mr-2 btn btn-outline-light btn-sm">Download DB</button>}>
                            <ExcelSheet data={leadsDB} name="Leads DB">
                            <ExcelColumn label="Name" value={(col) => col.firstName + ' ' + col.lastName}/>
                            <ExcelColumn label="Email" value={(col) => col.email}/>
                            <ExcelColumn label="Mobile" value={(col) => col.phoneNumber}/>
                            <ExcelColumn label="Date" value={(col) => col.timestamp.toDate()}/>
                            <ExcelColumn label="Program Name" value={(col) => getProgramNameHandler(col.programId)}/>
                            <ExcelColumn label="Referred By" value={(col) => getReferralNameHandler(col.ref)}/>
                            <ExcelColumn label="Stage" value={(col) => col.stage ? col.stage : "Uncontacted"}/>
                            <ExcelColumn label="Representative" value={(col) => col.comments ? getAddedByNameHandler(commentsHandler(col.comments).addedBy) : ''}/>
                            </ExcelSheet>
                        </ExcelFile>
                    </div>
                </div>
                <div className="d-flex align-items-baseline">
                    <Toast show={showA} onClose={toggleShowA} className="bg-primary text-white m-2" style={{maxWidth: '200px'}}>
                        <Toast.Header className="bg-primary text-white">
                            <strong className="mr-auto">Follow ups {followUpCount}</strong>
                        </Toast.Header>
                    </Toast>
                    
                    <Toast show={showB} onClose={toggleShowB} className="bg-primary text-white m-2" style={{maxWidth: '200px'}}>
                        <Toast.Header className="bg-primary text-white">
                            <strong className="mr-auto">To contact {contactCount}</strong>
                        </Toast.Header>
                    </Toast>
                </div>



                <DataTable
                theme={"dark"}
                noHeader={true}
                columns={columns}
                data={filteredItems}
                defaultSortField="todaysAction"
                defaultSortAsc={false}
                fixedHeaderScrollHeight="300px"
                style={{fontSize: '1rem'}}
                highlightOnHover={true}
                onSort={resetCount}
                pagination={true}
                paginationComponentOptions={paginationOptions}
                paginationRowsPerPageOptions={[10]}
                onChangePage={(e) => changePageHandler(e)}
                paginationDefaultPage={props.pageNo}
                subHeader={subHeader}
                subHeaderComponent={subHeaderComponentMemo}
            />



                {/* <table class="table table-responsive-md table-hover table-dark" style={{tableLayout: 'fixed'}}>
                <thead>
                    <tr>
                    <th scope="col" style={{width: '5%'}}>#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Program Name</th>
                    <th scope="col">Referred By</th>
                    <th scope="col">Date</th>
                    <th scope="col" className="text-center">Stage</th>
                    <th scope="col" className="text-center">Next Action</th>
                    <th scope="col" className="text-center">Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        leads.map((lead, index) =>{
                            if(!lead.hidden){
                                return(
                                    <tr>
                                    <th scope="row">{getNumber()}</th>
                                    <td style={{width: "15%"}} className="text-break"><span className="text-capitalize">{lead.firstName} {lead.lastName}</span>
                                        <br />
                                        <small>
                                            {lead.email}<br />
                                            {lead.phoneNumber}<br />
                                        </small>
                                    </td>
                                    <td style={{width: "25%"}}>{getProgramNameHandler(lead.programId)}</td>
                                    <td>{lead.timestamp.toDate().toDateString()}</td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
                </table> */}
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
                <AssignRepVerticallyCenteredModal
                    show={nextStageModalShow}
                    onHide={() => setNextStageModalShow(false)}
                />
            </div>
            <AcademyFooter />
        </>
    );
}

const mapStateToProps = (state) => {
    return({
        pageNo: state.pageNo,
        currentScrollPosition: state.currentScrollPosition,
        count: state.count
    })
}

const mapDispatchToProps = (dispatch) => {
    return{
        onPageSet: (pageNo) => dispatch({type: actionTypes.SETPAGENO, pageNo: pageNo}),
        onPageChange: (currentScrollPosition) => dispatch({type: actionTypes.SETCURRENTSCROLLPOSITION, currentScrollPosition: currentScrollPosition}),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
