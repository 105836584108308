import React, { useEffect, useState } from 'react';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import AcademyFooter from './AcademyFooter'
import background from '../../images/polygon.png'
import {Helmet} from 'react-helmet';


function AboutCII() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    useEffect(() => {

    }, [])

    return (
        <>
            <AcademyNavbar />
            <Helmet>
                <title>About CII - Igesia Academy</title>
                <meta name="title" content="About CII - Igesia Academy" />
                <meta name="description" content="World-class education designed to prepare you for the future." />
                {/* facebook */}
                <meta property="og:title" content="About CII - Igesia Academy" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Igesia Academy" />
                <meta property="og:description" content="World-class education designed to prepare you for the future." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/Metatags%2Flogo512.png?alt=media&token=e6f5c4e6-5a7c-4e49-942a-1ad5c4de2e62" />
                <meta property="og:url" content="https://academy.igesia.co/about-cii" />
            </Helmet>
            <div className="container-fluid pt-5" style={{ backgroundImage: `url(${background})` }}>
                <div className="container">
                    <div className="row py-4" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-12 text-white">
                            <h1 className="font-weight-light mb-3">Confederation of Indian Industry</h1>
                            
                        </div>
                        {/* <div className="col-md-6 text-light pt-3" style={{ overflow: "hidden" }}>
                        <p className="lead">Confederation of Indian Industry</p>

                        </div> */}
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-light py-5" style={{
                backgroundSize: 'cover', backgroundPosition: 'bottom right',
                backgroundImage: 'url(http://themesmummy.com/mitech/static/130fe9ab91ac2fc94b9b15afd0b37809/8c24d/title-bar-01-bg.jpg)'
            }}>
                <div className="container">
                    <div className="row" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-4 text-dark ">
                            <h2 className="font-weight-light">About</h2>
                        </div>
                        <div className="col-md-6" style={{ overflow: "hidden" }}>
                            <p>CII is a non-government, not-for-profit, industry-led and industry-managed organization, 
                                playing a proactive role in India’s development process. Founded in 1895 and celebrating 
                                125 years in 2020, India’s premier business association has more than 9100 members, from 
                                the private as well as public sectors, including SMEs and MNCs, and an indirect membership
                                 of over 300,000 enterprises from 291 national and regional sectoral industry bodies.</p>
                            <p>Extending its agenda beyond business, CII assists industry to identify and execute corporate 
                                citizenship programs. With 68 offices, including 9 Centres of Excellence, in India, and 11 
                                overseas offices in Australia, China, Egypt, France, Germany, Indonesia, Singapore, South Africa, 
                                UAE, UK, and USA, as well as institutional partnerships with 394 counterpart organizations 
                                in 133 countries, CII serves as a reference point for Indian industry and the international business community.</p>

                        </div>
                    </div>


                </div>
            </div>
            <AcademyFooter />
        </>
    );
}

export default AboutCII;