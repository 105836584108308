import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import DashboardNavbar from '../Dashboard/DashboardNavbar';



const SocialMedia = (props) => {
    const [socialMedia, setSocialMedia] = useState([]);
    useEffect(() => {
        let socialMediaRef = firebase.firestore().collection('socialMedia');
        socialMediaRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({id: doc.id, data:doc.data()});
                });
                setSocialMedia(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
        });
    },[])


    return (
        <>
            <DashboardNavbar />
            <div className="container mt-3">
                <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <h3 className="text-white mt-3 mb-3">Social Media Posts</h3>
                    <div>
                        <button type="button" onClick={() => {props.history.push('/admin/addsocialmedia')}} className="mr-2 btn btn-outline-light btn-sm">Add Post</button>
                        <button type="button" onClick={() => {props.history.push('/admin/selectmedia/')}} className="mr-2 btn btn-outline-light btn-sm">Modify Posts</button>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {
                        socialMedia.map((post) => {
                            return(
                                <Card className="text-white col-xs-12 col-sm-12 col-md-3 h-100 m-3" style={{ padding: '0', backgroundColor: '#333333'}}>
                                    <Card.Img variant="top" src={post.data.imageUrl} />
                                    <Card.Title className="font-weight-light p-3">{post.data.imageCaptions}</Card.Title>
                                    <Card.Text>
                                    </Card.Text>
                                </Card>
                            );
                        })
                    }
                </div>
            </div>
            <AcademyFooter />
        </>
    );
}


export default SocialMedia;
