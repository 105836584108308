import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Academy from "./components/Academy/Academy"
import Program from "./components/Academy/Program"
import Consult from "./components/Academy/Consult"
import Post from "./components/Academy/Post"
import Programs from "./components/Academy/Programs"
import Insights from "./components/Insights/insights"
import Home from "./components/Home"
import About from "./components/Academy/About"
import AboutCII from "./components/Academy/AboutCII"
import Terms from "./components/Academy/Terms"
import Privacy from "./components/Academy/Privacy"
import Refund from "./components/Academy/Refund"
import Contact from "./components/Academy/Contact"

import FacultyList from "./components/Academy/FacultyList"
import Faculty from "./components/Academy/Faculty"

import './styles.scss';
import { Alert, Button } from 'react-bootstrap'
import EnrollNow from "./components/Academy/EnrollNow";
import ThankYou from "./components/Academy/ThankYou";

import SignUp from './components/Academy/SignUp';
import Login from './components/Academy/Login';
import { AuthProvider } from './components/Academy/Auth';
import PrivateRoute from './components/Academy/PrivateRoute';
import SemiPrivateRoute from './components/Academy/SemiPrivateRoute';

import Leads from "./components/Admin/Leads";
import EnrolledUsers from "./components/Admin/EnrolledUsers";

import UserInfo from "./components/Academy/UserInfo";
import Dashboard from "./components/Dashboard/Dashboard";
import FacultyCRUD from "./components/Admin/FacultyCRUD";
import CurrentUsers from "./components/Admin/CurrentUsers";
import ProgramCardBuilder from "./components/Admin/ProgramCardBuilder";
import ProgramBuilder from "./components/Admin/ProgramBuilder";
import ProgramCardEditor from "./components/Admin/ProgramCardEditor";
import ProgramEditor from "./components/Admin/ProgramEditor";
import Referrals from "./components/Admin/Referrals";
import SalesMade from "./components/Admin/SalesMade";
import ViewLeadsFromReferral from './components/Admin/ViewLeadsFromReferral';
import ApprovePrograms from "./components/Admin/ApprovePrograms";

import NewProgram from "./components/Admin/NewProgram";
import NewPrograms from "./components/Academy/NewPrograms"


import SelectProgram from "./components/Admin/SelectProgram";
import AllFaculties from "./components/Admin/AllFaculties";
import ViewFaculty from "./components/Admin/ViewFaculty";
import EditFaculty from "./components/Admin/EditFaculty";
import AddFaculty from "./components/Admin/AddFaculty";

import PDFProgram from "./components/Admin/PDFProgram";

import ProgramPDF from "./components/Admin/ProgramPDF";
import ResetPassword from "./components/Academy/ResetPassword";
import FacultyBooks from "./components/Admin/FacultyBooks";
import FacultyPapers from "./components/Admin/FacultyPapers";

import SocialMedia from "./components/Admin/SocialMedia";
import AddSocialMedia from "./components/Admin/AddSocialMedia";
import EditSocialMedia from "./components/Admin/EditSocialMedia";
import SelectMedia from "./components/Admin/SelectMedia";
import OrderInfo from "./components/Academy/OrderInfo";
import AddParticipant from "./components/Academy/AddParticipant";
import SendEmails from "./components/Academy/SendEmails";
import EditParticipant from "./components/Academy/EditParticipant";
import AddLead from "./components/Admin/AddLead";
import EditLead from "./components/Admin/EditLead";
import OnlyLeads from "./components/Admin/OnlyLeads";
import LeadsComments from "./components/Admin/LeadsComments";
import AllLeadsComments from "./components/Admin/AllLeadsComments";
import OnlyAddLead from "./components/Admin/OnlyAddLead";
import OnlyEditLead from "./components/Admin/OnlyEditLead";
import OnlyAllLeadsComments from "./components/Admin/OnlyAllLeadsComments";
import OnlyLeadsComments from "./components/Admin/OnlyLeadsComments";
import ApproveLeads from "./components/Admin/ApproveLeads";
import LeadsDataTables from "./components/Admin/LeadsDataTables";

export default function App() {
  const [show, setShow] = useState(true);

  return (
    <AuthProvider>
    <Router>
      <div>
        {/* <Alert className="rounded-0 py-2 mb-0" show={show} variant="light" >
          <div className="container">
            <div className="row">
              <div className="col-9 col-md-10">
                <p className="mb-0 small">
                  Please note that this website is for internal consumption only and not intended for external distribution.
              </p>
              </div>
              <div className="col-3 col-md-2 text-right">
                <p onClick={() => setShow(false)} className="small text-danger mb-0" style={{ cursor: "pointer" }}>Close</p>
              </div>
            </div>
          </div>
        </Alert> */}



        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <PrivateRoute exact path="/admin/" component={Dashboard} />
          {/* <Route path="/academy">
            <Academy />
          </Route> */}
          <PrivateRoute exact path="/admin/leads" component={Leads} />
          <PrivateRoute exact path="/admin/addlead" component={AddLead} />
          <PrivateRoute exact path="/admin/editlead" component={EditLead} />
          <PrivateRoute exact path="/admin/allleadsremarks" component={AllLeadsComments} />
          <PrivateRoute exact path="/admin/leadsremarks" component={LeadsComments} />
          <PrivateRoute exact path="/admin/approveleads" component={ApproveLeads} />

          <SemiPrivateRoute exact path="/leads" component={OnlyLeads} />
          <SemiPrivateRoute exact path="/addlead" component={OnlyAddLead} />
          <SemiPrivateRoute exact path="/editlead" component={OnlyEditLead} />
          <SemiPrivateRoute exact path="/allleadsremarks" component={OnlyAllLeadsComments} />
          <SemiPrivateRoute exact path="/leadsremarks" component={OnlyLeadsComments} />
          <PrivateRoute exact path="/admin/leadsdatatables" component={LeadsDataTables} />

          <PrivateRoute exact path="/admin/enrolledusers" component={EnrolledUsers} />

          <PrivateRoute exact path="/admin/allfaculties" component={AllFaculties} />
          <PrivateRoute exact path="/admin/addfaculty/" component={AddFaculty} />
          <PrivateRoute exact path="/admin/viewfaculty/:id" component={ViewFaculty} />
          <PrivateRoute exact path="/admin/editfaculty/:id" component={EditFaculty} />
          <PrivateRoute exact path="/admin/facultybooks/:id" component={FacultyBooks} />
          <PrivateRoute exact path="/admin/facultypapers/:id" component={FacultyPapers} />

          <SemiPrivateRoute exact path="/admin/programcardbuilder" component={ProgramCardBuilder} />
          <SemiPrivateRoute exact path="/admin/programbuilder/:id" component={ProgramBuilder} />
          <SemiPrivateRoute exact path="/admin/viewprogram/:id" component={NewProgram} />
          <SemiPrivateRoute exact path="/admin/programcardeditor/:id" component={ProgramCardEditor} />
          <SemiPrivateRoute exact path="/admin/programeditor/:id" component={ProgramEditor} />
          <SemiPrivateRoute exact path="/admin/selectprogram/" component={SelectProgram} />
          <PrivateRoute exact path="/admin/programpdf/:id" component={ProgramPDF} />
          <PrivateRoute exact path="/admin/referrals/" component={Referrals} />
          <PrivateRoute exact path="/admin/salesmade/" component={SalesMade} />
          <PrivateRoute exact path="/admin/leadsfromref/:leadsfromref" component={ViewLeadsFromReferral} />

          <PrivateRoute exact path="/admin/socialmedia" component={SocialMedia} />
          <PrivateRoute exact path="/admin/selectmedia" component={SelectMedia} />
          <PrivateRoute exact path="/admin/addsocialmedia" component={AddSocialMedia} />
          <PrivateRoute exact path="/admin/editsocialmedia/:id" component={EditSocialMedia} />

          <PrivateRoute exact path="/approveprograms/" component={ApprovePrograms} />
          <PrivateRoute exact path="/sendemails/" component={SendEmails} />
          <SemiPrivateRoute exact path="/dashboard/" component={Dashboard} />
          <SemiPrivateRoute exact path="/userinfo/" component={UserInfo} />
          <SemiPrivateRoute exact path="/orderinfo/" component={OrderInfo} />
          <SemiPrivateRoute exact path="/addparticipant/" component={AddParticipant} />
          <SemiPrivateRoute exact path="/editparticipant/" component={EditParticipant} />
          <PrivateRoute exact path="/CurrentUsers/" component={CurrentUsers} />
          <Route exact path="/pdf/:id" component={PDFProgram} />
          <Route path="/signup/" component={SignUp} />
          <Route path="/login/" component={Login} />
          <Route path="/resetpassword/" component={ResetPassword} />


          <Route path="/all-faculty">
            <FacultyList />
          </Route>
          <Route
            exact
            path="/faculty/:id"
            render={(props) => (
              <Faculty {...props} />
            )} />
          <Route path="/insights">
            <Insights />
          </Route>
          <Route path="/programs">
            <NewPrograms />
          </Route>
          <Route exact path="/program/:id/(ref=)?:ref?" component={NewProgram} />
          <Route exact path="/program/:id" component={NewProgram} />





          <Route exact path="/enroll/" component={EnrollNow} />
          <Route exact path="/thankyou/" component={ThankYou} />
          <Route path="/post">
            <Post />
          </Route>
          <Route path="/consult">
            <Consult />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/about-cii">
            <AboutCII />
          </Route>
          <Route path="/refund">
            <Refund />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/(ref=)?:ref?" component={NewPrograms} />

          
        </Switch>
      </div>
    </Router>
    </AuthProvider>
  );
}