import React, {useContext, useEffect} from 'react';
import { Navbar, Nav, NavDropdown, Modal, Button } from 'react-bootstrap'
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import firebase from "../../firebase";
import DashboardNavbar from './DashboardNavbar';
import Hero from './Hero';
import DashboardActions from './DashboardActions';
import DashboardSnapshot from './DashboardSnapshot';
import {NameContext} from '../Academy/Auth';
import { AccessContext } from '../Academy/Auth';
import DashboardCount from './DashboardCount';
import {connect} from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes';

const Dashboard = (props) => {
  const firstName = useContext(NameContext);
  const hasAccess = useContext(AccessContext);

  useEffect(() => {
    return(
      props.onCleanUp
    )
  },[])
  return (
    <>
      <DashboardNavbar />
      <Hero firstName={firstName}/>
      {
        hasAccess ? <DashboardSnapshot /> : null
      }
            {
        hasAccess ? <DashboardCount /> : null
      }

      <DashboardActions hasAccess={hasAccess} />
    </>
  );
}

const mapStateToProps = (state) => {
  return(null)
}

const mapDispatchToProps = (dispatch) => {
  return{
    onCleanUp: () => dispatch({type: actionTypes.ONEXITLEADSPAGE})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Dashboard);