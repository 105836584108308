import React, { useEffect, useState, useContext } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import { CanSeeOtherUsersContext } from '../Academy/Auth';
import { db } from '../../firebase';
import {AuthContext} from '../Academy/Auth';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight, faCheck, faTimes);


const ApproveLeads = (props) => {
    const [leads, setLeads] = useState([]);
    const [tempUsers, setTempUsers] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const {currentUser} = useContext(AuthContext);
    const canSeeOtherUsers = useContext(CanSeeOtherUsersContext);
    // Getting temp leads
    useEffect(() => {
        if(canSeeOtherUsers){
            let leadsRef = firebase.firestore().collection('leadsToBeApproved').orderBy("timestamp", "desc");
            leadsRef.get()
                .then(snapshot => {
                    let tempLeadsList = [];
                    snapshot.forEach(doc => {
                        tempLeadsList.push({ approvalCollectionId: doc.id, ...doc.data() });
                    });
                    setLeads(tempLeadsList);
                })
                .catch(err => {
                    console.log('Error getting documents', err);
                });
        }else{
            props.history.push('/dashboard/')
        }
    }, [])

    // Getting users
    useEffect(() => {
        if(canSeeOtherUsers){
            firebase.firestore().collection('academyUsers').get()
            .then(snapshot => {
                let tempUsersList = [];
                snapshot.forEach(doc => {
                    tempUsersList.push({id: doc.id, ...doc.data()});
                });
                console.log(tempUsersList);
                setTempUsers(tempUsersList);
            })
        }else{
            props.history.push('/dashboard/')
        }
    }, [])


    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    // Getting referrals
    useEffect(() => {
        let referralsRef = firebase.firestore().collection('referrals');
        referralsRef.get()
            .then(snapshot => {
                let tempReferrals = [];
                snapshot.forEach(doc => {
                    tempReferrals.push(doc.data());
                });
                setReferrals(tempReferrals);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }
    
    const getReferralNameHandler = (id) => {
        let referralName = '';
        referrals.forEach((referral) => {
            if(referral.ref === id){
                referralName = referral.firstName + " " + referral.lastName;
            }
        })
        return referralName;
    }

    const approveLeadHandler = (leadid, leadData) => {
        firebase.firestore().collection('leadsToBeApproved').doc(leadid).update({
            hidden: true,
            approved: true,
            approvedBy: currentUser.uid
        })
        firebase.firestore().collection('leads').add(leadData).then(() => {
            alert('Lead Approved!')
            props.history.push('/dashboard')
        })
    }

    const rejectLeadHandler = (leadid) => {
        firebase.firestore().collection('leadsToBeApproved').doc(leadid).update({
            hidden: true,
            approved: false,
            approvedBy: currentUser.uid
        }).then(() => {
            alert('Lead Rejected!')
            props.history.push('/dashboard')
        })
    }


    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <h3 className="text-white mt-3 mb-3">Leads to be approved</h3>
                <table class="table table-responsive-md table-hover table-dark">
                <thead>
                    <tr>
                    <th scope="col" style={{width: '5%'}}>#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Program Name</th>
                    <th scope="col">Referred By</th>
                    <th scope="col">Date</th>
                    <th scope="col" className="text-center">Approve</th>
                    <th scope="col" className="text-center">Reject</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        leads.map((lead, index) =>{
                            if(!lead.hidden){
                                return(
                                    <tr>
                                    <th scope="row">{index + 1}</th>
                                    <td style={{width: "15%"}} className="text-break"><span className="text-capitalize">{lead.firstName} {lead.lastName}</span>
                                        <br />
                                        <small>
                                            {lead.email}<br />
                                            {lead.phoneNumber}<br />
                                        </small>
                                    </td>
                                    <td style={{width: "25%"}}>{getProgramNameHandler(lead.programId)}</td>
                                    <td>{lead.ref ? getReferralNameHandler(lead.ref) : 
                                    null
                                    }</td>
                                    <td>{lead.timestamp.toDate().toDateString()}</td>
                                    <td className="text-center"><Button variant="success" className="btn-sm" onClick={() => approveLeadHandler(lead.approvalCollectionId, lead)} ><FontAwesomeIcon icon={faCheck} /></Button></td>
                                    <td className="text-center"><Button variant="danger" className="btn-sm" onClick={() => rejectLeadHandler(lead.approvalCollectionId)} ><FontAwesomeIcon icon={faTimes} /></Button></td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
                </table>
            </div>
            <AcademyFooter />
        </>
    );
}


export default ApproveLeads;
