import React, { useEffect, useState, useContext } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import { CanSeeOtherUsersContext } from '../Academy/Auth';
import ModalUser from './Modal';
import DashboardNavbar from '../Dashboard/DashboardNavbar';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);


const CurrentUsers = (props) => {
    const [users, setUsers] = useState([]);
    const canSeeOtherUsers = useContext(CanSeeOtherUsersContext);
    // Getting users
    useEffect(() => {
        if(canSeeOtherUsers){
            firebase.firestore().collection('academyUsers').get()
            .then(snapshot => {
                let tempUsersList = [];
                snapshot.forEach(doc => {
                    tempUsersList.push({userId: doc.id ,...doc.data()});
                });
                setUsers(tempUsersList);
            })
        }else{
            props.history.push('/dashboard/')
        }
    }, [])

    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <h3 className="text-white mt-3 mb-3">Current Users</h3>
                <table class="table table-responsive-md table-hover table-dark">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Has Access</th>
                    <th scope="col">Can See Others</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((user, index) =>{
                            return(
                                <tr>
                                <th scope="row" className="align-middle">{index + 1}</th>
                                <td className="align-middle">{user.firstName}</td>
                                <td className="align-middle">{user.lastName}</td>
                                <td className="align-middle">{user.email}</td>
                                <td className="align-middle">{user.hasAccess ? 'true' : 'false'}</td>
                                <td className="align-middle">{user.canSeeOtherUsers ? 'true' : 'false'}</td>
                                <td><Button variant="warning" onClick={() => setShowModal((prev) => !prev)}>Edit</Button></td>
                                <ModalUser show={showModal} hide={setShowModal} hasAccess={user.hasAccess} canSeeOtherUsers={props.canSeeOtherUsers} id={user.userId} firstName={user.firstName} lastName={user.lastName}/>
                                </tr>
                            )
                        })
                    }
                </tbody>
                </table>
            </div>
            <AcademyFooter />
        </>
    );
}


export default CurrentUsers;
