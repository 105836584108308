import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import AcademyFooter from './AcademyFooter'
import firebase from 'firebase';
import background from '../../images/polygon.png'
import {Helmet} from 'react-helmet';



function Academy() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [programList, setProgramList] = useState([]);
    const [loadingProgramList, setLoadingProgramList] = useState(true);

    useEffect(() => {

        let programListRef = firebase.firestore().collection('programList');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                let tempProgramList = [];

                snapshot.forEach(doc => {
                    tempProgramList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramList);
                setLoadingProgramList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    }, [])

    return (
        <>
            <AcademyNavbar />
            <Helmet>
                <title>Contact Us - Igesia Academy</title>
                <meta name="title" content="Contact Us - Igesia Academy" />
                <meta name="description" content="World-class education designed to prepare you for the future." />
                {/* facebook */}
                <meta property="og:title" content="Contact Us - Igesia Academy" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Igesia Academy" />
                <meta property="og:description" content="World-class education designed to prepare you for the future." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/Metatags%2Flogo512.png?alt=media&token=e6f5c4e6-5a7c-4e49-942a-1ad5c4de2e62" />
                <meta property="og:url" content="https://academy.igesia.co/contact" />
            </Helmet>
            <div className="container-fluid pt-5">
                <div className="container">
                    <div className="row py-4" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-10 text-white">
                            <h1 className="font-weight-light mb-3">Contact Us</h1>
                            <p>For information on programs, please visit their respective pages</p>
                            <h5>Confederation of Indian Industry</h5>
                            <p className="mb-5">The Mantosh Sondhi Centre<br />
                            23, Institutional Area, Lodi Road, New Delhi - 110 003 (India)<br />
                            + 91 11 45771000 / 24629994-7 <br />
                            shalini.sharma@cii.in
                            </p>
                            <h5>igesia.co</h5>
                            <p>
                                <strong>USA</strong><br />
                            522 Cayuga Heights Road<br />
                            Ithaca, New York 14850<br />
                            <strong>India</strong><br />
                            71 Espace, Nirvana Country <br />
                            Gurugram, Haryana 122009<br />
                            hello@igesia.co
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <AcademyFooter />
        </>
    );
}

export default Academy;