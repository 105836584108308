import React, { useEffect, useState, useContext } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import Axios from 'axios';
import DashboardNavbar from '../Dashboard/DashboardNavbar';


library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);


const SalesMade = (props) => {
    useEffect(() => {
        if(!props.location.state || !props.location.state.objIds || !props.location.state.fName ){
            props.history.push('/admin/referrals/');
        }
    },[])
    const [sales, setSales] = useState([]);
    const [programs, setPrograms] = useState([]);
    // Getting users
    useEffect(() => {
        const salesIds = props.location.state ? props.location.state.objIds : [];
        Axios.get(`https://dev.igesia.com/orders/success`)
        .then((res) => {
            const ordersPlaced = res.data.data;
            const tempSales = [];
            salesIds.forEach((sale, index) => {
                ordersPlaced.forEach((order,i) => {
                    if(order.order.order_id === sale){
                        tempSales.push(order);
                    }
                })
            })
            setSales(tempSales);
        })
    }, [])
    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    const dateConvert = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString();
    }
    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }


    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <h2 className="text-white">Sales Made By {props.location.state ? props.location.state.fName : null}</h2>
                <table class="table table-responsive-md table-hover table-dark">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Order ID</th>
                    <th scope="col">Program Name</th>
                    <th scope="col">Applicant's First Name</th>
                    <th scope="col">Applicant's Last Name</th>
                    <th scope="col">Applicant's Email</th>
                    <th scope="col">Program Slot</th>
                    <th scope="col">Transaction Time</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sales.map((sale, index) =>{
                            return(
                                <tr>
                                <th scope="row" className="align-middle">{index + 1}</th>
                                <td className="align-middle">{sale.order.order_id}</td>
                                <td className="align-middle">{getProgramNameHandler(sale.order.program_id)}</td>
                                <td className="align-middle">{sale.order.lead.first_name}</td>
                                <td className="align-middle">{sale.order.lead.last_name}</td>
                                <td className="align-middle">{sale.order.lead.email}</td>
                                <td className="align-middle">{sale.order.program_slot_id}</td>
                                <td className="align-middle">{dateConvert(sale.createdAt)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                </table>
            </div>
            <AcademyFooter />
        </>
    );
}


export default SalesMade;
