import React, {useContext, useRef, useEffect} from 'react';
import { Navbar, Nav, NavDropdown, Modal, Button, Card } from 'react-bootstrap'
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import firebase from "../../firebase";
import LeadsGraph from './Graphs/LeadsGraph';
import EnrolledGraph from './Graphs/EnrolledGraph';


const DashboardSnapshot = (props) => {

    return (
        <div className="container">
        <LeadsGraph />
        <EnrolledGraph />
        </div>
    );
}

export default DashboardSnapshot;