import React from 'react';
import { db } from '../../firebase';
import { withRouter, Link } from 'react-router-dom';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faMicrophoneAlt, faBook } from '@fortawesome/free-solid-svg-icons';


class ViewFaculty extends React.Component {
    state = {
        docs: [],
        id: ''
    }
    componentDidMount() {

        this.setState({ id: this.props.match.params.id })
        console.log(this.props)
        db.collection("faculty").onSnapshot(snapshot => {
            let changes = snapshot.docChanges();
            changes.forEach(change => {

                const doc = change.doc;
                const id = change.doc.id;
                const fullName = change.doc.data().fullName;
                const description = change.doc.data().description;
                const languages = change.doc.data().languages;
                const education = change.doc.data().education;
                const experience = change.doc.data().experience;
                const profilePic = change.doc.data().profilePicUrl;
                const email = change.doc.data().email;
                const gender = change.doc.data().gender;
                const dob = change.doc.data().dob;
                const books = change.doc.data().books ? change.doc.data().books : [];
                const papers = change.doc.data().papers ? change.doc.data().papers : [];

                // console.log(education)
                if (change.type === 'added') {
                    this.setState({ docs: [...this.state.docs, { fullName, id, description, languages, education, experience, profilePic, email, gender, dob, books, papers }] })
                } else if (change.type === 'removed') {
                    var temp = this.state.docs
                    temp = temp.filter((docs) => docs.id != id)
                    this.setState({ docs: temp })
                }
            })
        })
    }


    render() {
        return (

            <div className="text-white">
                <DashboardNavbar />
                {this.state.docs.map((docs, index) => (
                    <div className="mt-5">
                        {(this.state.id === docs.id) ? (

                            <div key={docs.id} className="container">

                                <div className="row profile-header">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-md-3 text-right">
                                                <img className="img-fluid" style={{ height: "150px", width: "150px", borderRadius: "30px" }} src={docs.profilePic} alt="Avatar"></img>
                                            </div>
                                            <div className="col-md-6 align-items-center">
                                                <div className="profile-details">
                                                    <div className="profile-top text-theme">
                                                        <h4 className="font-weight-normal">{docs.fullName.selectTitle} {docs.fullName.firstName} {docs.fullName.middleName} {docs.fullName.lastName}</h4>
                                                    </div>
                                                    <div className="profile-speciality text-theme">
                                                        <h6 className="font-weight-normal">Professor of Operations, Technology and Information Management</h6>
                                                        <h6 className="font-weight-normal">Cornell SC Johnson College of Business</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <Link to={`/admin/facultypapers/${this.state.id}`} className="text-white my-4 d-flex align-items-center">
                                                    <FontAwesomeIcon className="mr-1" size="sm" icon={faFileAlt} />
                                                    <p className="mx-2 mb-0">Papers</p>
                                                    <p className="mx-2 mb-0">{docs.papers.length}</p>
                                                </Link>
                                                <div className="my-4 d-flex align-items-center">
                                                    <FontAwesomeIcon className="mr-1" size="sm" icon={faMicrophoneAlt} />
                                                    <p className="mx-2 mb-0">Conferences</p>
                                                    <p className="mx-2 mb-0">11</p>
                                                </div>
                                                <Link to={`/admin/facultybooks/${this.state.id}`} className="text-white my-4 d-flex align-items-center">
                                                    <FontAwesomeIcon className="mr-1" size="sm" icon={faBook} />
                                                    <p className="mx-2 mb-0">Books</p>
                                                    <p className="mx-2 mb-0">{docs.books.length}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5">
                                    <div className="col-md-3">
                                        <div className="expertise text-right">
                                            <p>Expertise</p>
                                            <span className="badge badge-secondary m-1 text-capitalize">Artificial intelligence</span>
                                            <span className="badge badge-secondary m-1 text-capitalize">big data analytics</span>
                                            <span className="badge badge-secondary m-1 text-capitalize">Technology and innovation policies</span>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about">
                                            {docs.description.split("\\n").map(para => (
                                                <p>{para}</p>
                                            ))}
                                        </div>
                                    </div>

                                    {/* Expertise */}
                                    <div className="col-md-3">
                                        <div className="similar-profiles my-2">
                                            <p>Similar Expertise</p>
                                            <div className="similar-profile d-flex my-3 ">
                                                <img className="img-fluid mr-3" style={{ height: "50px", width: "50px", borderRadius: "5px" }} src={"https://hubstaff-talent.s3.amazonaws.com/avatars/f1a014dd8f158939366971c99b807d94"} alt="Avatar"></img>
                                                <div className="d-flex flex-column details align-items-start">
                                                    <p className="mb-0 font-weight-bold">Dr. Richard James</p>
                                                    <p className="mb-0">NYU</p>
                                                </div>
                                            </div>
                                            <div className="similar-profile d-flex my-3 ">
                                                <img className="img-fluid mr-3" style={{ height: "50px", width: "50px", borderRadius: "5px" }} src={"https://hubstaff-talent.s3.amazonaws.com/avatars/f1a014dd8f158939366971c99b807d94"} alt="Avatar"></img>
                                                <div className="d-flex flex-column details align-items-start">
                                                    <p className="mb-0 font-weight-bold">Dr. Richard James</p>
                                                    <p className="mb-0">NYU</p>
                                                </div>
                                            </div>
                                            <div className="similar-profile d-flex my-3 ">
                                                <img className="img-fluid mr-3" style={{ height: "50px", width: "50px", borderRadius: "5px" }} src={"https://hubstaff-talent.s3.amazonaws.com/avatars/f1a014dd8f158939366971c99b807d94"} alt="Avatar"></img>
                                                <div className="d-flex flex-column details align-items-start">
                                                    <p className="mb-0 font-weight-bold">Dr. Richard James</p>
                                                    <p className="mb-0">NYU</p>
                                                </div>
                                            </div>
                                            <div className="similar-profile d-flex my-3 ">
                                                <img className="img-fluid mr-3" style={{ height: "50px", width: "50px", borderRadius: "5px" }} src={"https://hubstaff-talent.s3.amazonaws.com/avatars/f1a014dd8f158939366971c99b807d94"} alt="Avatar"></img>
                                                <div className="d-flex flex-column details align-items-start">
                                                    <p className="mb-0 font-weight-bold">Dr. Richard James</p>
                                                    <p className="mb-0">NYU</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-md-3">
                                        <div className="text-right">
                                            <p>Education</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="about">
                                            <p className="font-weight-bold">INSEAD, Fontainebleau, France</p>
                                            <p>The Challenge of Leadership (Executive Program – 4 weeks)</p>
                                            <p>2008-2009</p>
                                        </div>
                                        <div className="about">
                                            <p className="font-weight-bold">University of California at Berkeley, Berkeley, California</p>
                                            <p>Ph.D. in Computer Science; major in artificial intelligence, minors in finance and statistics</p>
                                            <p>1989 - 1990</p>
                                        </div>
                                        <div className="about">
                                            <p className="font-weight-bold">University of California at Berkeley, Berkeley, California</p>
                                            <p>M.S. in Business Administration; specializations in management science and finance.</p>
                                            <p>1987 - 1989</p>
                                        </div>
                                        <div className="about">
                                            <p className="font-weight-bold">University of California at Berkeley, Berkeley, California</p>
                                            <p>M.S. in Computer Science; major in artificial intelligence.</p>
                                            <p>1985 - 1987</p>
                                        </div>
                                        <div className="about">
                                            <p className="font-weight-bold">Indian Institute of Technology, New Delhi, India</p>
                                            <p>B.Tech. in Electrical Engineering & Computer Science; major in computer networks.</p>
                                            <p>1981 - 1985</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <p>Similar Education</p>
                                        <div className="similar-profile d-flex my-3 ">
                                                <img className="img-fluid mr-3" style={{ height: "50px", width: "50px", borderRadius: "5px" }} src={"https://hubstaff-talent.s3.amazonaws.com/avatars/f1a014dd8f158939366971c99b807d94"} alt="Avatar"></img>
                                                <div className="d-flex flex-column details align-items-start">
                                                    <p className="mb-0 font-weight-bold">Dr. Richard James</p>
                                                    <p className="mb-0">NYU</p>
                                                </div>
                                            </div>
                                            <div className="similar-profile d-flex my-3 ">
                                                <img className="img-fluid mr-3" style={{ height: "50px", width: "50px", borderRadius: "5px" }} src={"https://hubstaff-talent.s3.amazonaws.com/avatars/f1a014dd8f158939366971c99b807d94"} alt="Avatar"></img>
                                                <div className="d-flex flex-column details align-items-start">
                                                    <p className="mb-0 font-weight-bold">Dr. Richard James</p>
                                                    <p className="mb-0">NYU</p>
                                                </div>
                                            </div>
                                            <div className="similar-profile d-flex my-3 ">
                                                <img className="img-fluid mr-3" style={{ height: "50px", width: "50px", borderRadius: "5px" }} src={"https://hubstaff-talent.s3.amazonaws.com/avatars/f1a014dd8f158939366971c99b807d94"} alt="Avatar"></img>
                                                <div className="d-flex flex-column details align-items-start">
                                                    <p className="mb-0 font-weight-bold">Dr. Richard James</p>
                                                    <p className="mb-0">NYU</p>
                                                </div>
                                            </div>
                                            <div className="similar-profile d-flex my-3 ">
                                                <img className="img-fluid mr-3" style={{ height: "50px", width: "50px", borderRadius: "5px" }} src={"https://hubstaff-talent.s3.amazonaws.com/avatars/f1a014dd8f158939366971c99b807d94"} alt="Avatar"></img>
                                                <div className="d-flex flex-column details align-items-start">
                                                    <p className="mb-0 font-weight-bold">Dr. Richard James</p>
                                                    <p className="mb-0">NYU</p>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ) : (null)}
                    </div>
                ))}


            </div>
        )
    }
}

export default ViewFaculty;