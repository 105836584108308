import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar';


library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const ViewLeadsFromReferral = (props) => {
    const [leads, setLeads] = useState([]);
    const [programs, setPrograms] = useState([]);
    //checking whether url has state or not 
    useEffect(() => {
        if(!props.location.state || !props.location.state.fName ){
            props.history.push('/admin/referrals/');
        }
    },[])
    // Getting leads
    useEffect(() => {
        let leadsRef = firebase.firestore().collection('leads').orderBy("timestamp", "desc");
        leadsRef.get()
            .then(snapshot => {
                let tempLeadsList = [];
                snapshot.forEach(doc => {
                    if(doc.data().ref === props.match.params.leadsfromref)
                    tempLeadsList.push({ leadsId: doc.id, ...doc.data() });
                });
                setLeads(tempLeadsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }



    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <h3 className="text-white mt-3 mb-3">Leads by {props.location.state ? props.location.state.fName : null}</h3>
                <table class="table table-responsive-md table-hover table-dark">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Program Name</th>
                    <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        leads.map((lead, index) =>{
                            return(
                                <tr>
                                <th scope="row">{index + 1}</th>
                                <td><span className="text-capitalize">{lead.firstName} {lead.lastName}</span>
                                    <br />
                                    <small>
                                        {lead.email}<br />
                                        {lead.phoneNumber}<br />
                                    </small>
                                </td>
                                <td className={"w-50"}>{getProgramNameHandler(lead.programId)}</td>
                                <td>{lead.timestamp.toDate().toDateString()}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                </table>
            </div>
            <AcademyFooter />
        </>
    );
}


export default ViewLeadsFromReferral;
