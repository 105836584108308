import React from "react";
import firebase from "../../firebase";
import "firebase/storage";
import shortid from 'shortid';
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css"
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const storage = firebase.storage().ref();


const AddImage = (props,{ onRequestSave, onRequestClear, defaultFiles = [], }) => {
    const [files, setFiles] = React.useState(defaultFiles);
    return (
      <FilePond
        files={files}
        allowMultiple={false}
        maxFiles={1}
        allowImagePreview={props.imagePreview}
        onupdatefiles={fileItems => {
          if (fileItems.length === 0) {
            // onRequestClear()
          }
  
          setFiles(fileItems.map(fileItem => fileItem.file))
        }}
        server={
            {
                // this uploads the image using firebase
                process: (fieldName, file, metadata, load, error, progress, abort) => {
                  // create a unique id for the file
                  const id = shortid.generate();
                    
                  // upload the image to firebase
                  const task = storage.child('public/program-thumbnails' + id ).put(file, {
                    contentType: 'image/jpeg',
                  })
              
                  // monitor the task to provide updates to FilePond
                  task.on(
                    firebase.storage.TaskEvent.STATE_CHANGED,
                    snap => {
                      // provide progress updates
                      progress(true, snap.bytesTransferred, snap.totalBytes)
                    },
                    err => {
                      error(err.message)
                    },
                    () => {
                      // the file has been uploaded
                      load(id)
                      storage
                      .child('public/program-thumbnails' + id )
                      .getDownloadURL()
                      .then(url => {
                            props.url(url);
                        }
                      )
                      .catch(err => {
                        error(err.message)
                        abort()
                      })
                    }
                  )
                },
            }
        } 
      />
    )

};


export default AddImage;