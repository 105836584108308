import React, { useState, useEffect } from 'react'
import { Tabs, Tab, Spinner, Card } from 'react-bootstrap'
import AcademyNavbar from './AcademyNavbar'
import firebase from 'firebase';

function Publications() {
    return (
        <div className="row py-3">   
            <div className="col-md-6">
                <Card className="bg-dark link-card">
                    <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                    <Card.Body className="p-2">
                        <Card.Text>
                            <p className="url text-uppercase">WSJ.COM</p>
                            <p className="title">How Covid-19 is changing the language in emails</p>
                            <p className="meta-description">Work emails during the coronavirus pandemic must walk a fine line</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
            <div className="col-md-6">
                <Card className="bg-dark link-card">
                    <Card.Img variant="top" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-img.jpg?alt=media&token=26933727-eb73-463e-a1f8-d0c14217ddbe" />
                    <Card.Body className="p-2">
                        <Card.Text>
                            <p className="url text-uppercase">WSJ.COM</p>
                            <p className="title">How Covid-19 is changing the language in emails</p>
                            <p className="meta-description">Work emails during the coronavirus pandemic must walk a fine line</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </div>
        
    )
}

function Video() {
    return (
        <div className="row py-3">   
            <div className="col-md-12">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item"
                        src="https://www.youtube.com/embed/KuCa-ubAF-E"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen></iframe>
                </div>
            </div>
        </div>
    )
}

function Sonnet3() {
    return (
        <p className="my-2">Let's Go Party</p>
    )
}

function ControlledTabs() {
    const [key, setKey] = useState('home');
  
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
        <Tab eventKey="home" title="Publications">
          <Publications />
        </Tab>
        <Tab eventKey="profile" title="Videos">
          <Video />
        </Tab>
        <Tab eventKey="contact" title="Books">
          <Sonnet3 />
        </Tab>
      </Tabs>
    );
  }

function Faculty(props) {

    const [facultyData, setFacultyData] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        console.log(props.match.params.id)

        firebase.firestore().collection("doctorList").where("id", "==", props.match.params.id).get()
            .then((facultyQS) => {
                facultyQS.forEach(facultyDoc => {
                    setFacultyData(facultyDoc.data())
                    console.log(facultyDoc.data())
                });
                setIsLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setIsLoading(false);
            });

    }, []);

    return (
        <>
            <AcademyNavbar />
            {isLoading
                ? <div className="container-fluid w-100 d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                    <Spinner animation="border" role="status" variant="dark">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
                : <>
                    <div className="container-fluid bg-black text-white pt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3">
                                    <img
                                        className="rounded-circle"
                                        width="100%"
                                        src={`https://via.placeholder.com/800x533?text=${facultyData.firstName} ${facultyData.lastName}`} />
                                </div>
                                <div className="col-md-6">
                                <h1 className="font-weight-light pt-0">{facultyData.designation} {facultyData.firstName} {facultyData.lastName}</h1>
                                            <p className="mb-1">{facultyData.college}</p>
                                            <p>{facultyData.university.name}{facultyData.university.location && `, ${facultyData.university.location}`}</p>
                                            <a className="btn btn-sm btn-primary text-white mb-3 small">Request Consultation</a>
                                    {facultyData.biography.split("\\n").map((item) => (
                                        <p>{item}</p>
                                    ))}
                                    <ControlledTabs />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid bg-black pt-5">
                        <div className="container">
                        </div>
                    </div>
                </>}
        </>
    )
}

export default Faculty;