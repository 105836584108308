import React, {useContext, useRef, useEffect, useState} from 'react';
import { Navbar, Nav, NavDropdown, Modal, Button } from 'react-bootstrap'
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import Axios from 'axios';
import firebase from "../../../firebase";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import _ from 'lodash';


const EnrolledGraph = (props) => {
    const chart = useRef(null);
    const [transactions, setTransactions] = useState([]);


    // Getting leads
    useEffect(() => {
        Axios.get(`https://api.igesia.com/orders/success`)
        .then((res) => {
            setTransactions(res.data.data);
        })
    }, [])


    // theme
    function am4themes_darkTheme(target) {
        if (target instanceof am4core.InterfaceColorSet) {
            target.setFor("text", am4core.color("#ffffff"));
            target.setFor("grid", am4core.color("#ffffff"));
        }
    }

    //graph
    useEffect(() => {
      am4core.useTheme(am4themes_darkTheme);
      let y = am4core.create("chartdiv2", am4charts.XYChart);
  
      y.paddingRight = 20;
  
      let data = [];

      transactions.forEach((transaction) => {
        if(transaction.order.lead.email !== "me@iarani.com"){
          data.push({date: new Date(transaction.createdAt).toDateString()})
        }
          
      })
      const result = _.countBy(data, "date" );
      console.log(result);

      const resultArr = Object.keys(result).map((key) => ({date: (key), value: result[key]}))
      console.log(resultArr);
    //   leads.forEach((lead, index) => {
    //     data.push({ date: lead.timestamp.toDate(), name: lead.firstName + lead.lastName, value: 1 });
    //     console.log(data);
    //   })
  
      y.data = resultArr;
      let dateAxis = y.xAxes.push(new am4charts.DateAxis());
      dateAxis.renderer.grid.template.location = 1;
  
      let valueAxis = y.yAxes.push(new am4charts.ValueAxis());
      valueAxis.tooltip.disabled = true;
      valueAxis.renderer.minWidth = 35;
  
      let series = y.series.push(new am4charts.LineSeries());
      series.dataFields.dateX = "date";
      series.dataFields.valueY = "value";
      series.tooltipText = "{valueY.value}";
      y.cursor = new am4charts.XYCursor();
  
      let scrollbarX = new am4charts.XYChartScrollbar();
      scrollbarX.series.push(series);
      y.scrollbarX = scrollbarX;
  
      chart.current = y;

      let label = y.createChild(am4core.Label);
      label.text = "Enrolled Users";
      label.fontSize = 20;
      label.align = "center";
  
      return () => {
        y.dispose();
      };
    }, [transactions]);

    return (
        <div id="chartdiv2" style={{ width: "50%", height: "300px", display: "inline-block" }}></div>
    );
}

export default EnrolledGraph;