import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';

var webconfig = {
  apiKey: "AIzaSyAyox7br-qET_sNRtJSB8BUV2W6yuSbPxs",
  authDomain: "igesia-web.firebaseapp.com",
  databaseURL: "https://igesia-web.firebaseio.com",
  projectId: "igesia-web",
  storageBucket: "igesia-web.appspot.com",
  messagingSenderId: "167545419151",
  appId: "1:167545419151:web:7d51b3b30c023cfc1147fa",
  measurementId: "G-ZXFRJBSRLT"
};

var guconfig = {
  apiKey: "AIzaSyAhil9-iZHA2jHUJU2owqpmu63sn07lvrk",
  authDomain: "gu-igesia.firebaseapp.com",
  databaseURL: "https://gu-igesia.firebaseio.com",
  projectId: "gu-igesia",
  storageBucket: "gu-igesia.appspot.com",
  messagingSenderId: "80096395676",
  appId: "1:80096395676:web:ccf19037d81d5bf3ea4859",
  //measurementId: "G-ZXFRJBSRLT"
};

// Initialize Firebase
firebase.initializeApp(webconfig);
export const auth =firebase.auth()
export const db = firebase.firestore()
export const storage = firebase.storage()
export const storageRef = firebase.storage().ref();
// firebase.initializeApp(guconfig);
// firebase.analytics();

//npm run build;  firebase deploy --project igesia-web
//cii-igesia
//igesiaacademy

export default firebase;
