import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import AcademyFooter from './AcademyFooter'
import firebase from 'firebase';
import background from '../../images/polygon.png'
import {Helmet} from 'react-helmet';


function Academy() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [programList, setProgramList] = useState([]);
    const [loadingProgramList, setLoadingProgramList] = useState(true);

    useEffect(() => {

        let programListRef = firebase.firestore().collection('programList');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                let tempProgramList = [];

                snapshot.forEach(doc => {
                    tempProgramList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramList);
                setLoadingProgramList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    }, [])

    return (
        <>
            <AcademyNavbar />
            <Helmet>
                <title>Refund Policy - Igesia Academy</title>
                <meta name="title" content="Refund Policy - Igesia Academy" />
                <meta name="description" content="World-class education designed to prepare you for the future." />
                {/* facebook */}
                <meta property="og:title" content="Refund Policy - Igesia Academy" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Igesia Academy" />
                <meta property="og:description" content="World-class education designed to prepare you for the future." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/Metatags%2Flogo512.png?alt=media&token=e6f5c4e6-5a7c-4e49-942a-1ad5c4de2e62" />
                <meta property="og:url" content="https://academy.igesia.co/refund" />
            </Helmet>
            <div className="container-fluid pt-5">
                <div className="container">
                    <div className="row py-4" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-10 text-white">
                            <h1 className="font-weight-light mb-3">Igesia Refund Policy</h1>
                            <div id="contents">
                                <p>
                                    In the event of participant cancellation, the following schedule will apply :-
                                </p>
                                <ul>
                                    <li>
                                        4 weeks before start date: No cancellation fee
                                    </li>
                                    <li>
                                        2-4 weeks before start date: Half programme fee forfeiture
                                    </li>
                                    <li>
                                        Less than 2 weeks before start date: Full programme fee forfeiture
                                    </li>
                                </ul>
                                <p>
                                    In case of refunds of online payments, bank charges will be deducted.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AcademyFooter />
        </>
    );
}

export default Academy;