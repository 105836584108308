import * as actionTypes from '../actions/actionTypes';
const initialState = {
    pageNo: 1,
    currentScrollPosition: 0
}
const reducer = (state = initialState, action) => {
    if(action.type === actionTypes.SETPAGENO){
        return{
            ...state,
            pageNo: action.pageNo
        }
    }
    if(action.type === actionTypes.SETCURRENTSCROLLPOSITION){
        return{
            ...state,
            currentScrollPosition: action.currentScrollPosition
        }
    }
    if(action.type === actionTypes.ONEXITLEADSPAGE){
        return{
            ...state,
            pageNo: 1,
            currentScrollPosition: 0,
            count: 0,
        }
    }
    return state;
}


export default reducer;