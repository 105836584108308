import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from './AcademyNavbar';
import AcademyFooter from './AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import {Helmet} from 'react-helmet';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const ThankYou = (props) => {
    const [currentProgramBrouchure, setCurrentProgramBrochure] = useState('');
    const [programList, setProgramList] = useState([]);

    // Reading from local storage to get the url of brochure
    useEffect(() => {
        if((window.localStorage.getItem('requestedbrochureURL')) === 'undefined'){
            props.history.push('/');
        }else{
            setCurrentProgramBrochure(JSON.parse(window.localStorage.getItem('requestedbrochureURL')));
        }
        
    }, [])

    // Getting list of all the programs
    useEffect(() => {
        let programListRef = firebase.firestore().collection('programList');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                let tempProgramList = [];

                snapshot.forEach(doc => {
                    tempProgramList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    return (
        <>
            <AcademyNavbar />
            <Helmet>
                <title>Thank You - Igesia Academy</title>
                <meta name="title" content="Thank You - Igesia Academy" />
                <meta name="description" content="World-class education designed to prepare you for the future."   />
                {/* facebook */}
                <meta property="og:title" content="Thank You - Igesia Academy" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Igesia Academy" />
                <meta property="og:description" content="World-class education designed to prepare you for the future."   />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/Metatags%2Flogo512.png?alt=media&token=e6f5c4e6-5a7c-4e49-942a-1ad5c4de2e62" />
                <meta property="og:url" content="https://academy.igesia.co/thankyou" />
            </Helmet>
            <div className="container">
                <h1 className="h1 font-weight-normal text-white mt-3 mb-2">Thank You!</h1>
                <h2 className="h3 font-weight-light text-white">You can download the brochure by <a href={currentProgramBrouchure} target="_blank">clicking here</a>. We have also sent the download link of the brochure on your email.</h2>
                <h2 className="h3 mt-5 font-weight-light text-white">Check out our other programs</h2>
                <br></br>
                <div className="row">
                    {programList.map((item, key) => {
                        if (+item.position >= 0) {
                            return (
                                <div className="col-md-4" key={key}>
                                    <Link className="link" to={`/program/${item.programSlug}`}>
                                        <Card className="mb-3 border-0" bg="transparent" text="white">
                                            <Card.Img variant="top" src={item.programThumbnail} />
                                            <Card.Body className="px-0">
                                                <span className="small mb-4 text-warning">STARTS MARCH 2021</span>
                                                <Card.Title>{item.programTitle}</Card.Title>
                                                <Card.Text className="small">{item.programSubTitle}</Card.Text>
                                                <div className="row">
                                                    <div className="col">
                                                        {item.associatedUniversities && <div>
                                                            <p className="small font-weight-bold text-secondary">Learn and interact through live online classes with world class faculty from</p>
                                                            <div className="row">
                                                                {item.associatedUniversities.map(university =>
                                                                    <p className="col-md-6 mb-1 small ">{university}</p>
                                                                )}
                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <span className="text-muted">Learn More <FontAwesomeIcon icon={faArrowRight} size="sm" color="grey" /></span>
                                            </Card.Body>
                                        </Card>
                                    </Link>
                                </div>
                            )
                        }
                    }
                    )}
                </div>
            </div>
            <AcademyFooter />
        </>
    );
}


export default ThankYou;
