import React from 'react';
import firebase from "firebase";
import {withRouter} from 'react-router-dom';
import {db} from '../../firebase';
import { Container } from 'react-bootstrap';
import DashboardNavbar from '../Dashboard/DashboardNavbar';



class AllFaculties extends React.Component{
    state={
        docs:[],
        doc:[],
        super:false,
        userName:''
    }
    componentDidMount(){
        db.collection("faculty").onSnapshot(snapshot => {
            let changes = snapshot.docChanges();
            changes.forEach(change => {
              const doc = change.doc;
              const id = change.doc.id;
              const selectTitle = change.doc.data().selectTitle;
              const fullName = change.doc.data().fullName;
              const description = change.doc.data().description;
              const languages = change.doc.data().languages;
              const education = change.doc.data().education;
              const experience = change.doc.data().experience;
              const profilePic = change.doc.data().profilePicUrl;
              const email = change.doc.data().email;
              const gender =change.doc.data().gender;
              const dob = change.doc.data().dob;
              const programsTeaching = change.doc.data().programsTeaching;
              
             // console.log(education)
              if (change.type === 'added') {
                this.setState({ docs: [...this.state.docs, {fullName, id ,description, languages, education, experience, profilePic, email, gender,dob, programsTeaching }] })
              } else if (change.type === 'removed') {
                var temp = this.state.docs
                temp = temp.filter((docs) => docs.id != id)
                this.setState({ docs: temp })
              }
            })
          })
    
    }


    delData = (id, profileurl) => {
        db.collection('faculty').doc(id).delete().then(() => {
          var deleteRef = firebase.storage().refFromURL(profileurl)
        deleteRef.delete().then(() => {
          console.log('deleted successfully')
        }).catch((error) => {
         console.log('error')
        })
        })
        
      }
 
    viewFaculty = (id) => {
        this.props.history.push({pathname:`/admin/viewfaculty/${id}`})
    }
    editFaculty = (id) => {
        this.props.history.push({pathname:`/admin/editfaculty/${id}`})
    }

    render(){
        return(
                    <Container>
                    <DashboardNavbar />
                    <div className='container-fluid'>
                      <h3 className="text-white mt-3 mb-3">Faculty</h3>
                        <table className='table table-responsive-md table-hover table-dark'>
                        <thead className='thead-dark text-white'>
                            <tr>
                            <th scope='col'>#</th>
                            <th scope='col'>Profile Picture</th>
                            <th scope='col' style={{width: '20%'}}>Name</th>
                            <th scope='col' style={{width: '20%'}}>Programs Teaching</th>
                            <th scope='col'>View</th>
                            <th scope='col'>Edit</th>
                            <th scope='col'>Delete</th>
                            </tr>
                        </thead>

                        {this.state.docs.map((doc,index) => (
                        <tbody key={doc.id}>
                            <tr>
                            <th className="align-middle" scope='row'>{index+1}</th>
                                <td className="align-middle"><img style={{width:'45%', maxWidth: 120}} alt="profile picture" className='rounded' src={doc.profilePic} /></td>
                                <td className="align-middle font-weight-bold">{doc.fullName.firstName} {doc.fullName.lastName}</td>
                                <td className="align-middle">
                                  {
                                    doc.programsTeaching.map((program) => (
                                      <li className="font-weight-light my-2">{program}</li>
                                    ))
                                  }
                                </td>
                                <td className="align-middle">
                                <button className='btn btn-info mb-1' onClick={() => this.viewFaculty(doc.id) }>View</button>
                                </td>  
                                <td className="align-middle">
                                <button className='btn btn-warning mb-1' onClick={() => this.editFaculty(doc.id) }>Edit</button>
                                </td>
                                <td className="align-middle">
                                {/* <button className="btn btn-danger" onClick={() => {if (window.confirm('Are you sure you wish to delete this item?')) this.delData(doc.id, doc.profilePic)}}>Delete</button> */}
                                </td>
                            </tr>   
                                
                        </tbody>
                        ))}
                        </table>
                    </div>
                    </Container>
                
        )
    }
}
export default withRouter(AllFaculties);