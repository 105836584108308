import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from './AcademyNavbar';
import AcademyFooter from './AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import RequestBrochure from './RequestBrochure';
import TextareaAutosize from 'react-textarea-autosize';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb);


function AlertDismissible({ showAlert, handleClose, handleAlertHide }) {

    return (
        <div className="col-10" style={{ position: "absolute", zIndex: 10, top: "10%" }}>
            <Alert show={showAlert} variant="dark">
                <Alert.Heading>Are you sure you want to leave?</Alert.Heading>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={handleAlertHide}
                        variant="light"
                        className="mx-2">
                        No
                    </Button>

                    <Button
                        onClick={handleClose}
                        variant="dark"
                        className="mx-2">
                        Yes, Leave
                    </Button>
                </div>
            </Alert>
        </div>
    );
}


function RequestBrochureModal({ programId, programType, brochure, history }) {
    const [show, setShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleClose = () => {
        setShow(false);
        setShowAlert(false);
    };
    const handleShow = () => setShow(true);
    const handleAlertShow = () => setShowAlert(true);
    const handleAlertHide = () => setShowAlert(false);
    window.localStorage.setItem('requestedbrochureURL',JSON.stringify(brochure));

    useEffect(() => {
        // window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
        //     size:"invisible"
        // });
    }, [])

    return (
        <>
            <Button className="mr-2" variant="outline-light" size="sm" onClick={handleShow}><span className="font-weight-bold mb-0">Request Brochure</span></Button>

            <Modal
                onEscapeKeyDown={handleAlertShow}
                onHide={handleAlertShow}
                centered
                show={show}
            // onHide={handleClose}
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Request Brochure</Modal.Title>
                </Modal.Header> */}
                <Modal.Body className="border-white">
                    <div className="d-flex align-items-center justify-content-center">
                        <AlertDismissible showAlert={showAlert} handleClose={handleClose} handleAlertHide={handleAlertHide} />
                    </div>
                    <RequestBrochure programId={programId} programType={programType} brochure={brochure} history={history}  />
                </Modal.Body>
                {/* <Modal.Footer>
                        <Button variant="light" onClick={handleClose}>Close</Button>
                        <Button variant="dark" onClick={handleClose}>Done</Button>
                    </Modal.Footer> */}
            </Modal>
        </>
    );
}


function Program(props) {
    const [price, setPrice] = useState(95000);
    const [count, setCount] = useState(0);

    const [programId, setProgramId] = useState("");
    const [programType, setProgramType] = useState("");
    const [programThumbnail, setProgramThumbnail] = useState("");

    const [aboutProgram, setAboutProgram] = useState('');
    const [certificationAuthority, setCertificationAuthority] = useState('');
    const [faculty, setFaculty] = useState([]);
    const [learningJourney, setLearningJourney] = useState({});
    const [learningObjectives, setLearningObjectives] = useState({});
    const [programFeatures, setProgramFeatures] = useState([]);
    const [programName, setProgramName] = useState('');
    const [programShortDescription, setProgramShortDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [who, setWho] = useState({});
    const [completeProgramData, setCompleteProgramData] = useState({})
    const [contacts, setContacts] = useState({});
    const [loadingData, setLoadingData] = useState(true);
    const [admin, setAdmin] = useState(false);

    const [alreadyAdded, setAlreadyAdded] = useState(false);
    const [cartOrderIds, setCartOrderIds] = useState([]);



    function LiveClasses(sessions) {
        for (var i = 0; i < sessions; i++) {
            return (
                <>
                    <span className="livemarker">LIVE</span>
                </>
            )
        }
    }

    useEffect(() => {
        console.log('Executing Initial useEffect Process.')
        console.log(props.match.params.id)

        let recentViews = window.localStorage.getItem("recentViews") ? JSON.parse(window.localStorage.getItem("recentViews")) : [];

        // const testRef = firebase.firestore().collection("executivePrograms").doc("IGESIA-EP-0002");

        // fetch("https://igesia-dbadmin.firebaseio.com/programList/IGESIA-EP-0002.json?print=pretty")
        //     .then(response => response.json())
        //     .then(responseJson => {
        //         console.log(responseJson);
        //         testRef.set(responseJson);
        //     })
        //     .catch(error => console.error(error));

        firebase.firestore().doc(`programList/${props.match.params.id}`).get()
            .then((programListDoc) => {
                if (programListDoc.exists) {
                    const programListData = programListDoc.data();

                    setProgramId(programListData.programId);
                    setProgramType(programListData.programType);
                    setProgramThumbnail(programListData.programThumbnail);

                    // console.log("programListData", programListData);

                    programListData.programData.get().then((programDoc) => {
                        if (programDoc.exists) {

                            const programData = programDoc.data();

                            console.log("programListData", programListData);
                            console.log("programData", programData)
                            setCompleteProgramData(programData);

                            // postData("https://igesia-dbadmin.firebaseio.com/programList.json", {[programListData.programId]: programData})
                            setPrice(programData.feeBreakout.baseAmount);
                            setAboutProgram(programData.aboutProgram);
                            setCertificationAuthority(programData.certificationAuthority);
                            setFaculty(programData.faculty);
                            setLearningJourney(programData.learningJourney);
                            setLearningObjectives(programData.learningObjectives);
                            setProgramFeatures(programData.programFeatures);
                            setProgramName(programData.programName);
                            setProgramShortDescription(programData.programShortDescription);
                            setTags(programData.tags);
                            setWho(programData.who);
                            setContacts(programData.contacts);
                            setLoadingData(false);

                        } else {
                            console.log('Program Data not found.');
                        }
                    });
                } else {
                    console.log('Program Data not found.');
                }
            }).catch((error) => {
                console.log('Unable to fetch Program data.');
            });
    }, []);

    // async function postData(url = '', data = {}) {
    //     // Default options are marked with *
    //     const response = await fetch(url, {
    //         method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
    //         mode: 'cors', // no-cors, *cors, same-origin
    //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //         //   credentials: 'same-origin', // include, *same-origin, omit
    //         headers: {
    //             'Content-Type': 'application/json'
    //             // 'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //         redirect: 'follow', // manual, *follow, error
    //         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //         body: JSON.stringify(data) // body data type must match "Content-Type" header
    //     });
    //     return response.json(); // parses JSON response into native JavaScript objects
    // }


    useEffect(() => {
        let pastCartOrderIds = window.localStorage.getItem("enrollProgramIds") ? JSON.parse(window.localStorage.getItem("enrollProgramIds")) : [];

        if (pastCartOrderIds.indexOf(programId) > -1) {
            setAlreadyAdded(true)
        }
        setCartOrderIds(pastCartOrderIds)
    }, [programId])

    // referral
    useEffect(() => {
        console.log(props);
        // check whether the url has ref or not
        if(!props.match.params.ref || !props.match.params[0]){
            //if no ref in url, skip
        }else if(props.match.params.ref){
            // url has ref
            if(window.localStorage.getItem('ref')){
                // there is already a ref in local storage
                // console.log('LS has ref already!');
                // incrementing count of new ref but not replacing LS
                firebase.firestore().collection('referrals').doc(props.match.params.ref).update({
                    referralLinkVisited : firebase.firestore.FieldValue.increment(1)
                })
            }else if (!window.localStorage.getItem('ref')){
                // no ref in local storage
                window.localStorage.setItem('ref', JSON.stringify(props.match.params.ref));
                firebase.firestore().collection('referrals').doc(props.match.params.ref).update({
                    referralLinkVisited : firebase.firestore.FieldValue.increment(1)
                })
            }

        }
    },[])




    const addToCart = () => {
        let pastCart = window.localStorage.getItem("enrollProgram") ? JSON.parse(window.localStorage.getItem("enrollProgram")) : [];
        let pastCartOrderIds = window.localStorage.getItem("enrollProgramIds") ? JSON.parse(window.localStorage.getItem("enrollProgramIds")) : [];

        console.log("Checking: ", pastCartOrderIds.indexOf(programId))
        console.log("pastCart", pastCart, "completeProgramData", completeProgramData)
        console.log("programId", programId, "programType", programType, "programThumbnail", programThumbnail)

        if (pastCartOrderIds.indexOf(programId) > -1) {
            alert("Already added")
            return;
        }

        pastCart.push({ ...completeProgramData, programId, programThumbnail, programType });
        window.localStorage.setItem("enrollProgram", JSON.stringify(pastCart));

        pastCartOrderIds.push(programId);
        window.localStorage.setItem("enrollProgramIds", JSON.stringify(pastCartOrderIds));
    }

    const addToCartSingle = () => {
        let pastCart = [];
        let pastCartOrderIds = [];

        console.log("Checking: ", pastCartOrderIds.indexOf(programId))
        console.log("pastCart", pastCart, "completeProgramData", completeProgramData)
        console.log("programId", programId, "programType", programType, "programThumbnail", programThumbnail)

        if (pastCartOrderIds.indexOf(programId) > -1) {
            alert("Already added")
            return;
        }

        pastCart.push({ ...completeProgramData, programId, programThumbnail, programType });
        window.localStorage.setItem("enrollProgram", JSON.stringify(pastCart));

        pastCartOrderIds.push(programId);
        window.localStorage.setItem("enrollProgramIds", JSON.stringify(pastCartOrderIds));
    }


    if (loadingData) {
        return (
            <div className="container-fluid w-100 d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <Spinner animation="border" role="status" variant="light">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        )
    }

    return (
        <div className="academyProgram">
            <AcademyNavbar />
            <div className="container-fluid pricing-bar py-3">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-6">
                            <p className="font-weight-bold mb-0">
                                <span className="d-none d-md-block pt-2">{programName}</span>
                                {admin
                                    ? <span className="d-block d-md-none">
                                        From ₹<input type="text" value={price} className="form-control" />/ participant
                                    </span>
                                    : <span className="d-block d-md-none">From ₹{price}/ participant </span>
                                }
                            </p>
                        </div>
                        <div className="col-6 text-right">
                            <p className="font-weight-bold mb-0">

                                {admin
                                    ?
                                    <span className="d-none d-md-inline">From ₹<input type="text" value={price} className="form-control d-none d-md-inline w-25" />/ participant</span>
                                    : <span className="mr-2 d-none d-md-inline">From ₹{price}/ participant </span>
                                }
                                {/* <Button variant="primary d-inline font-weight-bold mb-0" style={{ marginTop: '-5px' }}>See dates</Button> */}
                                <span className="d-none d-md-inline" >
                                    <RequestBrochureModal programId={programId} programType={programType} brochure={completeProgramData.brochure} history={props.history} />
                                </span>
                                <Link to={`/enroll/`}>
                                    {cartOrderIds.length > 0 && alreadyAdded
                                        ? <Button variant="outline-light" size="sm">
                                            <span className="mr-2">Checkout</span>
                                            <Badge variant="dark">{cartOrderIds.length}</Badge>
                                        </Button>
                                        : <Button variant="outline-light" size="sm" onClick={addToCartSingle}>
                                            <p className="font-weight-bold mb-0">Enroll Now</p>
                                        </Button>}
                                </Link>
                                {/* <Link to={`/enroll/`}>
                                    {cartOrderIds.length > 0
                                        ? <Button variant="outline-light" size="sm">
                                            <span className="mr-2">Checkout</span>
                                            <Badge variant="dark">{cartOrderIds.length}</Badge>
                                        </Button>
                                        : alreadyAdded
                                            ? <Button variant="outline-light" size="sm">
                                                <span className="mr-2">Already added</span>
                                            </Button>
                                            : <Button variant="outline-light" size="sm" onClick={addToCart}>
                                                <p className="font-weight-bold mb-0">Enroll Now</p>
                                            </Button>
                                    }
                                </Link> */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-black pt-0 pt-md-5">
                <div className="container">
                    <div className="mb-3 mb-md-5 flextry" style={{ width: "100%" }}>
                        <div class="flex-container">
                            {/* <div id="MainBanner" className="d-none d-md-block">
                                <video width="100%" controlsList="nodownload" controls loop muted style={{ maxWidth: "300px" }}>
                                    <source src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fsample-video.mp4?alt=media&token=1eccfe7a-8468-4f89-874e-f6dfbcf69e38" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div> */}
                            <div className="cover-image"
                                style={{
                                    flexGrow: "2",
                                    backgroundImage: `url(${programThumbnail})`
                                }}
                            >
                            </div>
                            {/* <div>
                                <div style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FAlice%20Li.jpg?alt=media&token=0717ae51-18a1-4cd0-b37b-eb5cfb8daa75)" }}>
                                    <span>Faculty 2</span>
                                </div>
                                <div style={{
                                    backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FLutz%20Finger_Prof.jpg?alt=media&token=eda6c7ec-b3b0-45f1-bdd0-2ea69fc494d8)"
                                }}>
                                    <span>Faculty 3</span>
                                </div>
                            </div> */}
                            <div className="d-none d-md-block" style={{
                                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FA_Michael_Spence_new.jpg?alt=media&token=067a84d4-05c5-41ad-a939-691d2a30d098)"
                            }}
                            >
                                <p>Inaugural address by Nobel Laureate A. Michael Spence</p>
                            </div>
                            <div className="d-none d-md-block" style={{
                                backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FYG5Z7272_new.jpg?alt=media&token=43b62a2b-0132-480d-aee5-6a6e19ce8b14)"
                            }}
                            >
                                <p>Record of attendance from the University of California, Los Angeles (UCLA) Extension</p>
                            </div>

                        </div>
                    </div>



                    <div className="row program-header pb-5">
                        <div className="col-md-6 text-white mb-3">
                            {
                                admin ? <h1><TextareaAutosize className="form-control" value={programName} /></h1>
                                    : <h1 className="font-weight-light h2">{programName}</h1>
                            }
                            {
                                admin ? <TextareaAutosize className="form-control mb-2" value={programShortDescription} />
                                    : <p className="font-weight-light lead">{programShortDescription}</p>
                            }
                            {!loadingData && tags.map(item => (
                                <span key={item} class="badge text-uppercase badge-dark mr-2 mb-2 font-weight-normal p-2">{item}</span>
                            ))}
                            <span className="d-block d-md-none" >
                                <RequestBrochureModal programId={programId} programType={programType} brochure={completeProgramData.brochure} history={props.history} /> 
                            </span>
                            <div>
                                <p>Learn and interact through live online classes with world class faculty from</p>
                                <div className="row">
                                    {completeProgramData.associatedUniversities.map(university => 
                                        <p className="col-md-6 mb-1 font-weight-bold">{university}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-white ">
                            <div className="border-dark border-top border-bottom py-2">
                                <div className="row">
                                    <div className="col-md-4 pt-2 ">
                                        <img className="logo mb-3" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac"
                                            width="100%" />
                                    </div>
                                    <div className="col-md-8 col-12 certification-text">Attested with a record of attendance from the<br /> <strong>University of California, Los Angeles (UCLA) Extension</strong></div>
                                    <div className="col-12 mt-3 d-block d-sm-none">
                                        <img className="rounded w-100" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FYG5Z7272_new.jpg?alt=media&token=43b62a2b-0132-480d-aee5-6a6e19ce8b14" />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                {
                                    !loadingData && programFeatures.map(feature => (
                                        <div className="col-6">
                                            {
                                                admin ? <><FontAwesomeIcon icon={feature.icon} color="grey" />
                                                    <small><input className="form-control" value={feature.content} /></small></>
                                                    : <><FontAwesomeIcon icon={feature.icon} color="grey" />
                                                        <p className="small">{feature.content}</p></>
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="row mt-3 d-md-none">
                                <div className="col-2">
                                    <div style={{ height: "200px" }}>
                                        <p className="vertical-text small">Inaugural address by A. Michael Spence, Nobel prize winner for economics in 2001</p>
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="rounded" style={{
                                        height: "200px", backgroundSize: "cover", backgroundOrigin: "top right",
                                        backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2FA_Michael_Spence_new.jpg?alt=media&token=067a84d4-05c5-41ad-a939-691d2a30d098)"
                                    }}>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="container-fluid bg-light py-5 program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>About the Program</h2>
                            </div>
                            {
                                admin ? <div className="col-md-8 ">
                                    <TextareaAutosize className="form-control" value={aboutProgram} />
                                </div>
                                    : <div className="col-md-8 ">
                                        {aboutProgram.split("\\n").map(item => (
                                            <p>{item}</p>
                                        ))}
                                    </div>
                            }

                        </div>
                    </div>
                </div>

                <div className="container-fluid py-5  program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>What you will learn</h2>
                            </div>
                            <div className="col-md-8 ">
                                {
                                    admin ? <TextareaAutosize className="form-control" value={!loadingData && learningObjectives.intro} />
                                        : <p>{!loadingData && learningObjectives.intro}</p>
                                }
                                <p>
                                    <ol>
                                        {
                                            !loadingData && learningObjectives.learningPoints.map(learningPoint => (
                                                <li>
                                                    <p className="w-100">
                                                        {
                                                            admin ? <input type="text" value={learningPoint.title} className="form-control" />
                                                                : <h3>{learningPoint.title}</h3>}
                                                        {
                                                            admin ? <TextareaAutosize className="form-control" value={learningPoint.content} />
                                                                : <>{learningPoint.content}</>
                                                        }
                                                    </p>
                                                </li>
                                            ))
                                        }
                                    </ol>
                                </p>
                                <p>
                                    {admin ? <TextareaAutosize className="form-control" value={!loadingData && learningObjectives.learningOutcomes} />
                                        : <>{!loadingData && learningObjectives.learningOutcomes}</>}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-light py-5 program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Record of Participation</h2>
                            </div>
                            <div className="col-md-8 ">
                                <p>Upon successful completion of the course, you will be awarded
                                a record of participation from the
                                University of California, Los Angeles (UCLA) Extension
                                </p>
                                <img src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Funex_certificate.png?alt=media&token=e273510f-6596-4ffc-9565-94149eee018d" width="100%" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 program-content-web bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>World Class Faculty</h2>
                            </div>
                            <div className="col-md-8">
                                <p>You will learn from world class faculty in this program from leading business schools and global corporates.</p>
                            </div>
                        </div>
                        {
                            !loadingData && faculty.map(professor => (
                                <div className="row mt-3">
                                    <div className="col-md-4 pr-2 pr-md-3">
                                        <img className="img-fluid float-right" style={{ height: 150, width: 150, borderRadius: 30 }} src={professor.profilePicture} />
                                    </div>
                                    <div className="col-md-8 ">
                                        <p>
                                            {admin ? <input type="text" className="form-control" value={professor.facultyName} />
                                                : <h5>{professor.facultyName}</h5>}
                                            {
                                                admin
                                                    ? <TextareaAutosize className="form-control" value={professor.bio} />
                                                    : <>{professor.bio.split("\\n").map(para => (
                                                        <p>{para}</p>
                                                    ))}</>
                                            }
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="container-fluid py-5 text-white program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Your Learning Journey</h2>
                            </div>
                            <div className="col-md-8">
                                {admin
                                    ? <TextareaAutosize className="form-control" value={!loadingData && learningJourney.intro} />
                                    : <p><span className="text-white">{learningJourney.intro}</span></p>}
                                <RequestBrochureModal programId={programId} programType={programType} brochure={completeProgramData.brochure} history={props.history} />
                                {/* <div className="program-structure">
                                    {!loadingData && learningJourney.modules.map(item => (
                                        <p>
                                            {LiveClasses(item.sessions)}

                                            {item.moduleType !== ""
                                                ? item.moduleType == "Orientation Module"
                                                    ? <span className="d-block font-weight-bold text-uppercase text-warning small">{item.moduleType}</span>
                                                    : item.moduleType === "POST PROGRAM"
                                                        ? <span className="d-block font-weight-bold text-uppercase text-warning small">{item.moduleType}</span>
                                                        : <span className="d-block font-weight-bold text-uppercase text-primary small">{item.moduleType}</span>
                                                : null
                                            }
                                            {item.moduleName !== ""
                                                ? admin
                                                    ? <input className="form-control" value={item.moduleName} />
                                                    : <h4>{item.moduleName}</h4>
                                                : null
                                            }
                                             {item.description !== ""
                                                ? admin
                                                    ? <TextareaAutosize className="form-control" value={item.description} />
                                                    : item.description.split("\\n").map(item => (
                                                        <span className="font-weight-light">{item}<br /></span>
                                                    ))
                                                : null} 
                                        </p>
                                    ))}
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Who should take this course?</h2>
                            </div>
                            <div className="col-md-8 ">
                                {admin
                                    ? <TextareaAutosize className="form-control" value={!loadingData && who.intro} />
                                    : <p>{!loadingData && who.intro}</p>}

                                <h5>This program will benefit you if</h5>
                                <p>
                                    <ul>
                                        {
                                            !loadingData && who.questions.map(question => (
                                                <li><p>
                                                    {admin ? <input type="text" className="form-control" value={question} />
                                                        : <>{question}</>}
                                                </p>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {(window.location.href.indexOf("academy.igesia.co") < 0)
                && <div className="container-fluid py-5 program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Contact</h2>
                            </div>
                            <div className="col-md-8 ">
                                {
                                    contacts.map((contact) => (
                                        <div className="mb-5">
                                        <h5>{contact.contactName}</h5>
                                        <a href={"mailto:" + contact.contactEmail}><h5>{contact.contactEmail}</h5></a>
                                        <h5>{contact.contactMobile}</h5>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
            <AcademyFooter />
        </div>
    );
}

export default Program;
