import React, { useEffect, useState, useRef } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import _ from 'lodash';
import DatePicker, { registerLocale } from "react-datepicker";
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import DatePickerLeadsComments from './DatePickerLeadsComments';
import * as Yup from 'yup';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import { CSVLink } from "react-csv";

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const LeadsDataTables = (props) => {
    const [leads, setLeads] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const [currentLeadID, setCurrentLeadID] = useState('');
    const [stage, setStage] = useState('');
    const [nextAction, setNextAction] = useState('');
    // const [remarks, setRemarks] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [nextStageModalShow, setNextStageModalShow] = useState(false);

    let count;
    // Getting leads
    useEffect(() => {
        let leadsRef = firebase.firestore().collection('leads').orderBy("timestamp", "desc");
        leadsRef.get()
            .then(snapshot => {
                let tempLeadsList = [];
                snapshot.forEach(doc => {
                    if(!doc.data().hidden)
                    tempLeadsList.push({ leadsId: doc.id, ...doc.data() });
                });
                setLeads(tempLeadsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [modalShow,nextStageModalShow])
    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    // Getting referrals
    useEffect(() => {
        let referralsRef = firebase.firestore().collection('referrals');
        referralsRef.get()
            .then(snapshot => {
                let tempReferrals = [];
                snapshot.forEach(doc => {
                    tempReferrals.push(doc.data());
                });
                setReferrals(tempReferrals);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }
    
    const getReferralNameHandler = (id) => {
        let referralName = '';
        referrals.forEach((referral) => {
            if(referral.ref === id){
                referralName = referral.firstName + " " + referral.lastName;
            }
        })
        return referralName;
    }

    // const leadStageHandler = () => {
    //     if(stage.trim() !== '' && currentLeadID.trim !== ''){
    //         firebase.firestore().collection('leads').doc(currentLeadID).update({
    //             stage: stage
    //         })
    //         .then(() => {
    //             setModalShow(false);
    //         })
    //     }
    // }

    //validation
    const StageSchema = Yup.object().shape({
        stage: Yup.string().required('Required') 
    });

    //validation
    const NextActionSchema = Yup.object().shape({
        nextAction: Yup.string().required('Required') 
    });





    // modal
    function MyVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
          >
            <Modal.Header closeButton className="bg-dark text-white font-weight-light modal-custom">
              <Modal.Title id="contained-modal-title-vcenter" className="font-weight-normal">
                Update Stage
              </Modal.Title>
            </Modal.Header>
            <Modal.Body  className="bg-dark text-white font-weight-normal">
                {/* <label for="stage" className="mb-1">Stage</label>
                <select name="stage" onChange={e => setStage(e.target.value)} class="custom-select">
                    <option disabled selected={stage === "" ? true : false} value="">Please select</option>
                    <option selected={stage === "Uncontacted" ? true : false} value="Uncontacted">Uncontacted</option>
                    <option selected={stage === "Contacted" ? true : false} value="Contacted">Contacted</option>
                    <option selected={stage === "Not interested" ? true : false} value="Not interested">Not interested</option>
                    <option selected={stage === "Follow up" ? true : false} value="Follow up">Follow up</option>
                    <option selected={stage === "Deal complete" ? true : false} value="Deal complete">Deal complete</option>
                </select>
                <label>Date of Action</label>
                <DatePicker
                    selected={today}
                    onChange={handleDateChange}
                /> */}

            <Formik
            validationSchema={StageSchema}
            initialValues = {{
                stage: stage,
                doa: new Date()
            }}
            onSubmit={(values, { resetForm }) => {
                firebase.firestore().collection('leads').doc(currentLeadID).update({
                    stage: values.stage,
                    doa: values.doa
                })
                .then(() => {
                    setModalShow(false);
                })
            }}
            >
                {({ errors, touched, isValidating, values }) => (
                <Form className="bg-dark p-3">
                            <div>
                                <>
                                    <div className="form-group row" style={{display: 'flex', alignItems: 'center'}}>
                                        <div className="col-sm-12 mb-3">
                                            <label for="stage" className="mb-1">Stage</label>
                                            <Field
                                            name={`stage`}
                                            id={`stage`}
                                            placeholder="Remark"
                                            as="select"
                                            className='custom-select'
                                            >
                                            <option disabled  value="">Please select</option>
                                            <option value="Uncontacted">Uncontacted</option>
                                            <option value="Contacted">Contacted</option>
                                            <option value="Not interested">Not interested</option>
                                            <option value="Follow up">Follow up</option>
                                            <option value="Deal complete">Deal complete</option>
                                            </Field>
                                            {errors.stage && touched.stage ? (
                                            <div className="text-danger ml-2">{errors.stage}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-12 text-white">
                                            <label for="doa" className="mb-1">Date of action</label>
                                            <DatePickerLeadsComments  name={`doa`} id="doa" />
                                        </div>
                                    </div>
                            </>

                            </div>

                    <div style={{textAlign: 'center'}}> 
                        <button type="submit" className="btn btn-primary mt-3 btn-sm">Update Stage</button>
                    </div>
                </Form>
                )}
            </Formik>
            </Modal.Body>
            {/* <Modal.Footer  className="bg-dark text-white">
              <Button type="submit" onClick={formRef.current.submitForm}>Save</Button>
            </Modal.Footer> */}
          </Modal>
        );
    }

    // modal next action
    function NextActionVerticallyCenteredModal(props) {
        return (
          <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
          >
            <Modal.Header closeButton className="bg-dark text-white font-weight-light modal-custom">
              <Modal.Title id="contained-modal-title-vcenter" className="font-weight-normal">
                Update Next Action
              </Modal.Title>
            </Modal.Header>
            <Modal.Body  className="bg-dark text-white font-weight-normal">
                {/* <label for="stage" className="mb-1">Stage</label>
                <select name="stage" onChange={e => setStage(e.target.value)} class="custom-select">
                    <option disabled selected={stage === "" ? true : false} value="">Please select</option>
                    <option selected={stage === "Uncontacted" ? true : false} value="Uncontacted">Uncontacted</option>
                    <option selected={stage === "Contacted" ? true : false} value="Contacted">Contacted</option>
                    <option selected={stage === "Not interested" ? true : false} value="Not interested">Not interested</option>
                    <option selected={stage === "Follow up" ? true : false} value="Follow up">Follow up</option>
                    <option selected={stage === "Deal complete" ? true : false} value="Deal complete">Deal complete</option>
                </select>
                <label>Date of Action</label>
                <DatePicker
                    selected={today}
                    onChange={handleDateChange}
                /> */}

            <Formik
            validationSchema={NextActionSchema}
            initialValues = {{
                nextAction: nextAction,
                nextdoa: new Date()
            }}
            onSubmit={(values, { resetForm }) => {
                console.log(values,'values')
                firebase.firestore().collection('leads').doc(currentLeadID).update({
                    nextAction: values.nextAction,
                    nextdoa: values.nextdoa
                })
                .then(() => {
                    setNextStageModalShow(false);
                })
            }}
            >
                {({ errors, touched, isValidating, values }) => (
                <Form className="bg-dark p-3">
                            <div>
                                <>
                                    <div className="form-group row" style={{display: 'flex', alignItems: 'center'}}>
                                        <div className="col-sm-12 mb-3">
                                            <label for="stage" className="mb-1">Next action</label>
                                            <Field
                                            name={`nextAction`}
                                            id={`nextAction`}
                                            placeholder="Next Action"
                                            as="select"
                                            className='custom-select'
                                            >
                                            <option disabled  value="">Please select</option>
                                            <option value="To Contact">To Contact</option>
                                            <option value="To Follow Up">To Follow Up</option>
                                            </Field>
                                            {errors.nextAction && touched.nextAction ? (
                                            <div className="text-danger ml-2">{errors.nextAction}</div>
                                            ) : null}
                                        </div>
                                        <div className="col-sm-12 text-white">
                                            <label for="nextdoa" className="mb-1">Date of action</label>
                                            <DatePickerLeadsComments minDate={new Date()} name={`nextdoa`} id="nextdoa" />
                                        </div>
                                    </div>
                            </>

                            </div>

                    <div style={{textAlign: 'center'}}> 
                        <button type="submit" className="btn btn-primary mt-3 btn-sm">Update Stage</button>
                    </div>
                </Form>
                )}
            </Formik>
            </Modal.Body>
            {/* <Modal.Footer  className="bg-dark text-white">
              <Button type="submit" onClick={formRef.current.submitForm}>Save</Button>
            </Modal.Footer> */}
          </Modal>
        );
    }



    const editLeadHandler = (leadid) => {
        props.history.push({pathname: '/admin/editlead', state: {leadid}})
    }

    const updateRemarksHandler = (leadid) => {
        props.history.push({pathname: '/admin/leadsremarks', state: {leadid}})
    }
    const allRemarksHandler = (leadid) => {
        props.history.push({pathname: '/admin/allleadsremarks', state: {leadid}})
    }
    
    const commentsHandler = (comments) => {
        const timeConverted = comments.map((comment) => {
            comment.time = comment.time;
            return(comment)
        })
        const sorted = _.orderBy(timeConverted, 'time', 'desc');
        return sorted[0];
    }

    const hideLeadHandler = (leadid) => {
        firebase.firestore().collection('leads').doc(leadid).update({
            hidden: true,
        })
        .then(()=> {
            window.location.reload();
        })
    }

    count = 0;
    const getNumber = () => {
        return ++ count;
    }


    // export csv

    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function convertArrayOfObjectsToCSV(array) {
        let result;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        console.log(leads, 'first leads value')
        const keys = Object.keys(leads[0]);
    
        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;
    
        array.forEach(item => {
        let ctr = 0;
        keys.forEach(key => {
            if (ctr > 0) result += columnDelimiter;
    
            result += item[key];
            
            ctr++;
        });
        result += lineDelimiter;
        });
    
        return result;
    }
  
    // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
    function downloadCSV(array) {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array);
        if (csv == null) return;
    
        const filename = 'export.csv';
    
        if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        link.click();
    }

    //   end of export csv

    const Export = ({ onExport }) => (
        <Button onClick={e => onExport(e.target.value)}>Export</Button>
    );


      
    const columns = [
        {
            name: '#',
            minWidth: '1rem',
            maxWidth: '4rem',
            cell: lead => <CustomCount lead={lead} />
        },
        {
            name: 'Personal Details',
            selector: 'firstName',
            sortable: true,
            cell: lead => <CustomDetails lead={lead} />
        },
        {
            name: 'Program Name',
            selector: 'programId',
            sortable: true,
            cell: lead => <CustomProgramName lead={lead} />
        },
        {
            name: 'Referred By',
            selector: 'ref',
            sortable: true,
            cell: lead => <CustomReferredBy lead={lead} />
        },
        {
            name: 'Date',
            selector: 'timestamp',
            sortable: true,
            cell: lead => <CustomDate lead={lead} />
        },
        {
            name: 'Stage',
            selector: 'stage',
            sortable: true,
            center: true,
            cell: lead => <CustomStage lead={lead} />
        },
        {
            name: 'Next Action',
            selector: 'nextAction',
            sortable: true,
            cell: lead => <CustomNextAction lead={lead} />
        },
        {
            name: 'Remarks',
            cell: lead => <CustomRemarks lead={lead} />
        }
    ];


    // custom cells
    const CustomCount = ({ lead }) => (
        getNumber()
    )
    const CustomDetails = ({ lead }) => (
        <div className="text-break h6 font-weight-normal">
            <span className="text-capitalize">{lead.firstName} {lead.lastName}</span>
            <br />
            <small>
                {lead.email}<br />
                {lead.phoneNumber}<br />
            </small>
            {/* <Button variant="link" className="btn-link mt-2"  onClick={() => hideLeadHandler(lead.leadsId)}>Hide Lead</Button> */}
        </div>
    );

    const CustomProgramName = ({ lead }) => (
        <div className="text-break h6 font-weight-normal">
            <span className="text-capitalize">{getProgramNameHandler(lead.programId)}</span>
        </div>
    );

    const CustomReferredBy = ({ lead }) => (
        <div className="text-break h6 font-weight-normal">
            {lead.ref ? getReferralNameHandler(lead.ref) : 
            <Button variant="link" className="btn-link"   onClick={() => {editLeadHandler(lead.leadsId)}}>Update</Button>
        }</div>
    )

    const CustomDate = ({ lead }) => (
        <div className="text-break h6 font-weight-normal">
            <span className="text-capitalize">{moment(lead.timestamp.toDate()).format('ll')}</span>
        </div>
    )

    const CustomStage = ({ lead }) => (
        <div className="text-break h6 font-weight-normal text-center">
            {lead.stage ? lead.stage : 'Uncontacted'}
            <br></br>
            <small className="mt-2 d-block">{lead.doa? moment(lead.doa.toDate()).format('ll') : ''}</small>
            <Button variant="link" className="btn-link d-block btn-sm"  onClick={() => {setStage(lead.stage? lead.stage : ''); setCurrentLeadID(lead.leadsId); setModalShow(true);}}>Update Stage</Button>
        </div>
    )

    const CustomNextAction = ({ lead }) => (
        <div className="text-break h6 font-weight-normal text-center pb-4">
            {lead.nextAction ? lead.nextAction : ''}
            <br></br>
            <small>{lead.nextdoa? moment(lead.nextdoa.toDate()).format('ll') : ''}</small>
        </div>
    )

    const CustomRemarks = ({ lead }) => (
        <div className="text-break h6 font-weight-normal text-center py-3">
            <small className="d-block w-75 text-break mx-auto text-secondary overflow-hidden" style={{maxHeight: '25ch'}}>{lead.comments ? commentsHandler(lead.comments).remark : ''}</small>
            <Button variant="link" className="btn-link mt-2 btn-sm"  onClick={() => allRemarksHandler(lead.leadsId)}>All Remarks</Button>
        </div>
    )

    const actionsMemo = React.useMemo(() => <CSVLink data={leads}>
    Download me
  </CSVLink>, [leads]);
    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
                    <h3 className="text-white mt-3 mb-3">Leads</h3>
                    <div>
                        <button type="button" onClick={() => {props.history.push('/admin/addlead')}} className="mr-2 btn btn-outline-light btn-sm">Add Lead</button>
                    </div>
                </div>

                <DataTable
                theme={"dark"}
                noHeader={true}
                columns={columns}
                data={leads}
                defaultSortField="timestamp"
                defaultSortAsc={false}
                fixedHeaderScrollHeight="300px"
                style={{fontSize: '1rem'}}
                highlightOnHover={true}
            />



                {/* <table class="table table-responsive-md table-hover table-dark" style={{tableLayout: 'fixed'}}>
                <thead>
                    <tr>
                    <th scope="col" style={{width: '5%'}}>#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Program Name</th>
                    <th scope="col">Referred By</th>
                    <th scope="col">Date</th>
                    <th scope="col" className="text-center">Stage</th>
                    <th scope="col" className="text-center">Next Action</th>
                    <th scope="col" className="text-center">Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        leads.map((lead, index) =>{
                            if(!lead.hidden){
                                return(
                                    <tr>
                                    <th scope="row">{getNumber()}</th>
                                    <td style={{width: "15%"}} className="text-break"><span className="text-capitalize">{lead.firstName} {lead.lastName}</span>
                                        <br />
                                        <small>
                                            {lead.email}<br />
                                            {lead.phoneNumber}<br />
                                        </small>
                                    </td>
                                    <td style={{width: "25%"}}>{getProgramNameHandler(lead.programId)}</td>
                                    <td>{lead.timestamp.toDate().toDateString()}</td>
                                    </tr>
                                )
                            }
                        })
                    }
                </tbody>
                </table> */}
                <MyVerticallyCenteredModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
            <AcademyFooter />
        </>
    );
}


export default LeadsDataTables;
