import React, { useEffect, useState, useContext } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import _ from 'lodash';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);


const Referrals = (props) => {
    const [leads, setLeads] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const [leadsCount, setLeadsCount] = useState({});
    // Getting users
    useEffect(() => {
        firebase.firestore().collection('referrals').get()
        .then(snapshot => {
            let tempReferralsList = [];
            snapshot.forEach(doc => {
                tempReferralsList.push(doc.data());
            });
            setReferrals(tempReferralsList);
        })
    }, [])

    // Getting leads
    useEffect(() => {
        let leadsRef = firebase.firestore().collection('leads').orderBy("timestamp", "desc");
        leadsRef.get()
            .then(snapshot => {
                let tempLeadsList = [];
                snapshot.forEach(doc => {
                    if(doc.data().ref !== ""){
                        tempLeadsList.push(doc.data().ref);
                    }
                });
                setLeads(tempLeadsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    useEffect(() => {
        // getting count of leads
        const result = _.countBy(leads);
        setLeadsCount(result);
    },[leads])

    const viewSalesHandler = (index) => {
        props.history.push({pathname: '/admin/salesmade', state: {objIds: referrals[index].orderIdsOfSalesMade, fName: referrals[index].firstName}})
    }
    const viewLeadsHandler = (ref, index) => {
        props.history.push({pathname: `/admin/leadsfromref/${ref}`, state: {fName: referrals[index].firstName}})
    }

    const getLeadsCount = (ref) => {
        return leadsCount[ref];
    }

    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <h3 className="text-white mt-3 mb-3">Referrals</h3>
                <table class="table table-responsive-md table-hover table-dark">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Leads</th>
                    <th scope="col">Sales made</th>
                    <th scope="col">View leads</th>
                    <th scope="col">View sales</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        referrals.map((ref, index) =>{
                            return(
                                <tr>
                                <th scope="row" className="align-middle">{index + 1}</th>
                                <td className="align-middle">{ref.firstName} {ref.lastName}</td>
                                <td className="align-middle">{getLeadsCount(ref.ref)}</td>
                                <td className="align-middle">{ref.salesMade}</td>
                                <td className="align-middle"><Button variant="primary" onClick={() => viewLeadsHandler(ref.ref, index)}>View Leads</Button></td>
                                <td className="align-middle"><Button disabled={!ref.salesMade} variant="primary" onClick={() => viewSalesHandler(index)}>View Sales</Button></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                </table>
            </div>
            <AcademyFooter />
        </>
    );
}


export default Referrals;
