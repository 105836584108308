import React from 'react';
import {useField} from 'formik';
import TextareaAutosize from 'react-textarea-autosize';

const MyTextAreaField = ({label, ...props}) => {
    const [field, meta] = useField(props);
    
      if (field && field.value === null) {
        field.value = "";
      }
      return (   
          <TextareaAutosize
            rows={10}
            {...field}
            {...props}
          />
      );
};

export default MyTextAreaField;