import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import AcademyFooter from './AcademyFooter'
import firebase from 'firebase';
import background from '../../images/polygon.png'
import {Helmet} from 'react-helmet';

const categoryoptions = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Cateogry</Popover.Title>
        <Popover.Content>
            And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
);

const Category = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Category</Button>
    </OverlayTrigger>
);

const Dates = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Dates</Button>
    </OverlayTrigger>
);

function About() {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [programList, setProgramList] = useState([]);
    const [loadingProgramList, setLoadingProgramList] = useState(true);

    useEffect(() => {

        let programListRef = firebase.firestore().collection('programList');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                let tempProgramList = [];

                snapshot.forEach(doc => {
                    tempProgramList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramList);
                setLoadingProgramList(false);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    }, [])

    return (
        <>
            <AcademyNavbar />
            <Helmet>
                <title>About - Igesia Academy</title>
                <meta name="title" content="About - Igesia Academy" />
                <meta name="description" content="World-class education designed to prepare you for the future." />
                {/* facebook */}
                <meta property="og:title" content="About - Igesia Academy" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Igesia Academy" />
                <meta property="og:description" content="World-class education designed to prepare you for the future." />
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/Metatags%2Flogo512.png?alt=media&token=e6f5c4e6-5a7c-4e49-942a-1ad5c4de2e62" />
                <meta property="og:url" content="https://academy.igesia.co/about" />
            </Helmet>
            <div className="container-fluid pt-5" style={{ backgroundImage: `url(${background})` }}>
                <div className="container">
                    <div className="row py-4" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-4 text-white">
                            <h1 className="font-weight-light mb-3">Igesia Academy</h1>
                            
                        </div>
                        <div className="col-md-6 text-light pt-3" style={{ overflow: "hidden" }}>
                        <p className="lead">A Unique Live Virtual Learning Ecosystem for the World</p>

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-light py-5" style={{
                backgroundSize: 'cover', backgroundPosition: 'bottom right',
                backgroundImage: 'url(http://themesmummy.com/mitech/static/130fe9ab91ac2fc94b9b15afd0b37809/8c24d/title-bar-01-bg.jpg)'
            }}>
                <div className="container">
                    <div className="row" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-4 text-dark ">
                            <h2 className="font-weight-light">About</h2>
                        </div>
                        <div className="col-md-6" style={{ overflow: "hidden" }}>
                            <p>The Covid-19 crisis has ushered in a VUCA world, a world that is volatile, uncertain,
                            complex and ambiguous. Companies need to focus on increasing business resilience and
                            adjust their strategies for a changed global context. The half cycle of knowledge is
                            decreasing rapidly and business needs are evolving quickly. Investing in the learning
                            and education of managers and executives is essential for successfully navigating and
                                winning  in the future.</p>
                            <p>The Igesia Leadership Academy is a unique ecosystem for enhancing corporate learning
                            through live virtual learning. The Academy offers live virtual executive programs to
                                its members in partnership with the University of California at Los Angeles Extension.</p>

                        </div>
                    </div>


                </div>
            </div>
            <div className="container-fluid bg-light py-5" style={{}}>
                <div className="container">
                    <div className="row" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-4 text-dark ">
                            <h2 className="font-weight-light">Services</h2>
                        </div>
                        <div className="col-md-6" style={{ overflow: "hidden" }}>
                            <h3>Executive education programs</h3>
                            <p>
                                The Academy will create and deliver executive education programs for executives. Some 
                                of these programs will be offered in cooperation with other partners. The executive 
                                programs will bring world class faculty and industry experts to interact with participants 
                                through live virtual teaching on the Igesia learning platform. Participants in the executive 
                                education programs will get a record of accomplishment from the University of California at 
                                Los Angeles Extension. Academy will offer both open programs and customized programs for 
                                addressing the needs of corporate executives.</p><p>
                                The Igesia Learning Academy believes that the offered executive programs should fit three 
                                important criteria. One, they should be of the highest quality. This is ensured by engaging 
                                the world’s best faculty on all addressed topics in the programs and by ensuring that a high 
                                degree of attention is paid to the personal learning needs of each executive. Two, the 
                                programs need to be scalable. Delivered by live virtual teaching, the Academy will be able 
                                to offer programs at scale to serve the learning needs of many hundreds of managers. Three, 
                                the programs will be designed to be affordable and meet local price points that can be 
                                sustained by the member firms and executives. The Academy will offer live virtual executive 
                                programs to its members in partnership with the University of California at Los Angeles Extension.</p>
                                <h3>Marketplace of programs</h3>
                                <p>
                                The Academy will invite other business schools, training organizations and independent coaches 
                                and teachers to offer their programs through live virtual teaching on the Igesia learning 
                                platform. Executives will have the opportunity to sign up for these programs and attend them 
                                on the Igesia learning platform. Organizations and independent trainers participating in the 
                                marketplace can set their own rates and the Academy will take a modest percentage of the fees 
                                paid by the participating executives.</p>
                                <h3>Online learning channels</h3>
                                <p>
                                The Academy will curate online content from public and private sources on key topics of 
                                interest to executives such as globalization, supply chains, digital transformation and 
                                business resilience. These content channels will be shared for free with individual members 
                                and executives from corporate members of the Academy.</p>    

                        </div>
                    </div>

                </div>
            </div>
            <AcademyFooter />
        </>
    );
}

export default About;