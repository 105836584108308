import React, { useEffect, useState, useContext } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import { CanSeeOtherUsersContext } from '../Academy/Auth';
import { db } from '../../firebase';

library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight, faCheck, faTimes);


const ApprovePrograms = (props) => {
    const [tempProgramList, setTempProgramList] = useState([]);
    const [tempUsers, setTempUsers] = useState([]);
    const canSeeOtherUsers = useContext(CanSeeOtherUsersContext);
    // Getting temp programs
    useEffect(() => {
        if(canSeeOtherUsers){
            firebase.firestore().collection('programListTests').get()
            .then(snapshot => {
                let tempProgramList = [];
                snapshot.forEach(doc => {
                    tempProgramList.push(doc.data());
                });
                setTempProgramList(tempProgramList);
            })
        }else{
            props.history.push('/dashboard/')
        }
    }, [])

    // Getting users
    useEffect(() => {
        if(canSeeOtherUsers){
            firebase.firestore().collection('academyUsers').get()
            .then(snapshot => {
                let tempUsersList = [];
                snapshot.forEach(doc => {
                    tempUsersList.push({id: doc.id, ...doc.data()});
                });
                console.log(tempUsersList);
                setTempUsers(tempUsersList);
            })
        }else{
            props.history.push('/dashboard/')
        }
    }, [])


    const getCreatedByUserInfo = (id) => {
        let name ='';
        tempUsers.map((user) => {
            if(user.id === id){
                name = `${user.firstName} ${user.lastName}`;
            }
        })
        return name;
    }

    const viewProgramHandler = (slug) => {
        props.history.push({pathname: `/admin/viewprogram/${slug}`})
    }
    const editProgramHandler = (slug) => {
        props.history.push({pathname: `/admin/programcardeditor/${slug}`})
    }


    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <h3 className="text-white mt-3 mb-3">Programs to be approved</h3>
                <table class="table table-responsive-md table-hover table-dark">
                <thead>
                    <tr className="text-center">
                    <th scope="col">#</th>
                    <th scope="col w-25">Program Title</th>
                    <th scope="col">Program ID</th>
                    <th scope="col">Created By</th>
                    <th scope="col">View Program</th>
                    <th scope="col">Edit Program</th>
                    <th scope="col">Approve Program</th>
                    <th scope="col">Reject Program</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tempProgramList.map((tempProgram, index) =>{
                            return(
                                <tr className="text-center">
                                <th scope="row" className="align-middle">{index + 1}</th>
                                <td className="align-middle w-25">{tempProgram.programTitle}</td>
                                <td className="align-middle">{tempProgram.programId}</td>
                                <td className="align-middle">{getCreatedByUserInfo(tempProgram.createdBy)}</td>
                                <td className="align-middle"><Button variant="primary" onClick={() => viewProgramHandler(tempProgram.programSlug)}>View Program</Button></td>
                                <td className="align-middle"><Button variant="warning" disabled={+tempProgram.position} onClick={() => editProgramHandler(tempProgram.programSlug)} >Edit Program</Button></td>
                                <td className="align-middle">{ (+tempProgram.position > 0) ? "Approved" : <Button variant="success" ><FontAwesomeIcon icon={faCheck} /></Button>}</td>
                                <td className="align-middle">{ (+tempProgram.position > 0) ? "Approved" : <Button variant="danger" ><FontAwesomeIcon icon={faTimes} /></Button>}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                </table>
            </div>
            <AcademyFooter />
        </>
    );
}


export default ApprovePrograms;
