import React, {useState, useEffect} from 'react';
import { db } from '../../firebase';
import { withRouter, Link } from 'react-router-dom';
import DashboardNavbar from '../Dashboard/DashboardNavbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const FacultyBooks = (props) => {
    const [books, setBooks] = useState([]);
    const [name, setName] = useState('');
    useEffect(() => {
        db.collection('faculty').doc(props.match.params.id).get()
            .then((doc) => {
                if(doc.exists){
                    setName(`${doc.data().fullName.selectTitle} ${doc.data().fullName.firstName} ${doc.data().fullName.lastName}`)
                    setBooks(doc.data().books ? doc.data().books : [] );
                }
            })
            .catch((err) => {
                console.log(err);
            })
    },[])
    return(
        <>
            <DashboardNavbar />
            <div className="container">
                <h4 className="text-white mt-3 mb-3 text-capitalize font-weight-light">Books & Research Monographs by <Link to={`/admin/viewfaculty/${props.match.params.id}`} className="text-white">{name}</Link></h4>
                <table class="table table-responsive-md table-hover table-dark">
                <thead>
                    <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Link</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        books.map((book, index) =>{
                            return(
                                <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{book.bookName}</td>
                                <td><a href={book.bookUrl} target="_blank">Link</a></td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                </table>
            </div>
        </>
    );
}

export default FacultyBooks;