import React, { useEffect, useState, useContext } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faFileExcel, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import TextareaAutosize from 'react-textarea-autosize';
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import FileUpload from './FileUpload';
import BrochureUpload from './BrochureUpload';
import * as Yup from 'yup';
import DatePickerField from './DatePickerField';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { AuthContext } from "../Academy/Auth";

import { db } from '../../firebase';
library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faCheck, faTimes);

//validation
const ProgramEditorSchema = Yup.object().shape({
    programTitle: Yup.string().required('Required'),
    programSubTitle: Yup.string().required('Required'),
    programType: Yup.string().required('Please select an option'),
    associatedUniversities: Yup.array()
    .of(
        Yup.string().required('Required')
    )
});

// error messages
const ErrorMessages = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? <div className="text-danger ml-2">{error}</div> : null;
      }}
    />
);





function ProgramCardEditor(props) {
    const {currentUser} = useContext(AuthContext);
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [loadedProgram, setLoadedProgram] = useState('');
    useEffect(() => {
        db.collection('programListTests').doc(props.match.params.id).get().then((doc) => {
            if(doc.exists){
                setLoadedProgram(doc.data());
                setThumbnailUrl(doc.data().programThumbnail);
            }
        })
    },[]);
    return(
        <div className="academyProgram">
            <AcademyNavbar />
            <Formik
            enableReinitialize
            validationSchema={ProgramEditorSchema}
            initialValues={{
                position: 0,
                programData: loadedProgram.programData,
                programId: loadedProgram.programId,
                programSlug: loadedProgram.programSlug,
                programSubTitle: loadedProgram.programSubTitle,
                programThumbnail: loadedProgram.programThumbnail,
                programTitle: loadedProgram.programTitle,
                programType: loadedProgram.programType,
                associatedUniversities: loadedProgram.associatedUniversities ? loadedProgram.associatedUniversities : [],
                programStartDate: loadedProgram.programStartDate ? loadedProgram.programStartDate.toDate().toDateString() : '',
                editedAt: new Date(),
                editedBy: currentUser.uid
            }}
            onSubmit={(values, { resetForm }) => {
                values.programSlug = values.programTitle.replace(/\s+/g, '-').replace(/:/g,"").toLowerCase();
                values.programThumbnail = thumbnailUrl;
                // if date isnt changed it gets converted to a string so changing it into Date
                if(typeof(values.programStartDate) === "string"){
                    values.programStartDate = new Date(values.programStartDate);
                }
                db.collection('programListTests').doc(values.programSlug).update(values).then(() => {
                    props.history.push('/admin/programeditor/'+ loadedProgram.programId);
                });
                resetForm();
            }}
            >
                {({ errors, touched, isValidating, values }) => (
                <Form className={'container-fluid py-5 program-content-web'}>
                    <div className="container-fluid bg-black pt-0 pt-md-5">
                        <div className="container">
                            <div className="row program-header pb-5">
                                <div className="col-md-6 text-white mb-3 ">
                                    <Field component="textarea" className={"form-control mb-2" + (errors.programTitle && touched.programTitle ? ' is-invalid' : '')} name="programTitle" placeholder="Program Title" />
                                    {errors.programTitle && touched.programTitle ? (
                                    <div className="text-danger ml-2">{errors.programTitle}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6 text-white mb-3">
                                    <Field component="textarea" className={"form-control mb-2" + (errors.programSubTitle && touched.programSubTitle ? ' is-invalid' : '')} name="programSubTitle" placeholder="Program SubTitle" />
                                    {errors.programSubTitle && touched.programSubTitle ? (
                                    <div className="text-danger ml-2">{errors.programSubTitle}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6 text-white mb-3">
                                    <img src={thumbnailUrl} style={{width: '100%'}} />
                                    <FileUpload url={setThumbnailUrl} imagePreview={false}/>
                                </div>
                                <div className="col-md-6 text-white mb-3">
                                    <Field placeholder="Program Type" className={"form-control mb-2"  + (errors.programType && touched.programType ? ' is-invalid' : '')} name="programType" >
                                        {/* <option value="" disabled selected>Program Type</option>
                                        <option value="Executive Program">Executive Program</option> */}
                                    </Field>
                                    {errors.programType && touched.programType ? (
                                    <div className="text-danger ml-2">{errors.programType}</div>
                                    ) : null}
                                </div>
                                <div className="col-md-6 text-white mb-3">
 
                                        <FieldArray name="associatedUniversities">
                                            {({ insert, remove, push }) => (
                                                <>
                                            <div className="row">
                                                {values.associatedUniversities.length > 0 &&
                                                values.associatedUniversities.map((feature, index) => (
                                                    <div className="form-group col-sm-6" key={index} style={{display: 'flex', alignItems: 'center'}}>
                                                        <label htmlFor={`associatedUniversities[${index}]`} className='col-xs-4' style={{marginRight: '10px', marginBottom: '0'}}></label>
                                                        <Field
                                                        name={`associatedUniversities[${index}]`}
                                                        id={`associatedUniversities[${index}]`}
                                                        placeholder="Associated University"
                                                        type="text"
                                                        className='col-xs-8 form-control'
                                                        />
                                                        <ErrorMessages name={`associatedUniversities[${index}]`} />
                                                    <div className="col">
                                                            <button
                                                            type="button"
                                                            className="secondary btn btn-danger btn-cross"
                                                            onClick={() => remove(index)}
                                                            >
                                                            X
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="mx-auto text-center">
                                                <button
                                                type="button"
                                                className="secondary mt-3 mb-3 btn btn-outline-light btn-sm"
                                                onClick={() => push()}
                                                >
                                                Add University
                                                </button>
                                            </div>
                                            </>
                                            )}
                                        </FieldArray>
                                </div>
                                <div className="col-md-6 text-white mb-3 ">
                                    <span className="text-white mr-5 mb-5">Program Starting Date</span>
                                    <DatePickerField name="programStartDate"  />
                                </div>
                            </div>
                            <div className="row program-header pb-5 d-flex" style={{flexDirection: 'column'}}>
                                <h2 className="d-block mx-auto text-primary">Preview</h2>
                                <Card className="col-md-4 mb-3 mx-auto" bg="transparent" text="white">
                                    <Card.Img variant="top" src={thumbnailUrl} />
                                    <Card.Body className="px-0">
                                        <span className="small mb-4 text-warning  text-uppercase">STARTS {values.programStartDate ? moment(values.programStartDate).format('MMMM YYYY') : null}</span>
                                            <Card.Title>{values.programTitle ? values.programTitle : 'Enter Title'}</Card.Title>
                                            <Card.Text className="small">{values.programSubTitle ? values.programSubTitle : 'Enter Subtitles'}</Card.Text>
                                            <Card.Text className="small">{values.programType? values.programType : 'Select Program Type'}</Card.Text>
                                            <p className="small font-weight-bold text-muted pt-3 border-top">Learn and interact through live online classes with world class faculty from</p>
                                            {
                                                (values.associatedUniversities.length !== 0) ? (
                                                    <div className="row">
                                                        {
                                                            values.associatedUniversities.map((uni) => (
                                                                <p className="col-md-6 text-white mb-1 font-weight-bold">{uni}</p>
                                                            ))
                                                        }
                                                    </div>
                                                )  : null
                                            }
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>

                    <div style={{textAlign: 'center'}}> 
                        <button type="submit" className="btn btn-primary mt-3">Proceed</button>
                    </div>
                </Form>
                )}
            </Formik>
            <AcademyFooter />
        </div>
    )
 
}

export default ProgramCardEditor;
