import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "./Auth";
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar'


library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight);

const UserInfo = (props) => {
    const {currentUser} = useContext(AuthContext);
    const [leadBrochuresRequested, setLeadBrochuresRequested] = useState([]);
    const [programs, setPrograms] = useState([]);
    useEffect(() => {
        let leadsRef = firebase.firestore().collection('leads');
        leadsRef.get()
            .then(snapshot => {
                let tempLeadsList = [];
                snapshot.forEach(doc => {
                    if(doc.data().email === currentUser.email){
                        tempLeadsList.push(doc.data().programId)
                    }
                });
                let uniqueLeads = Array.from(new Set(tempLeadsList)); //removes duplicate values of brochures using "Set" -> @https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set
                setLeadBrochuresRequested(uniqueLeads)
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });

    },[])
    useEffect(() => {
        // as there's no slug in data
        // using the programID to fetch programName
        // then converting programName into slug
        // then fetching the slug and ultimately the complete program

        // currently slugs are not converted directly from programName
        // so using if else for now

        let programSlugs = [];
        leadBrochuresRequested.map((programId) => {
            if(programId === "IGESIA-EP-0001"){
                programSlugs.push('leading-digital-transformation-and-innovation');
            }else if(programId === "IGESIA-EP-0002"){
                programSlugs.push('artificial-intelligence-principles-and-techniques');
            }else if(programId === "IGESIA-EP-0003"){
                programSlugs.push('leading-academic-institutions-in-turbulent-times');
            }else if(programId === "IGESIA-EP-0004"){
                programSlugs.push('innovation-management-strategy-and-execution');
            }else if(programId === "IGESIA-EP-0005"){
                programSlugs.push('cyber-security-for-digital-leadership');
            }else if(programId === "IGESIA-EP-0006"){
                programSlugs.push('leveraging-design-thinking-for-innovation-the-multiplier-effect');
            }else if(programId === "IGESIA-EP-0007"){
                programSlugs.push('strategic-leadership-of-industry-4');
            }else if(programId === "IGESIA-EP-0008"){
                programSlugs.push('ipr-management-an-enterprise-imperative');
            }
        })

        programSlugs.map((program) => {
            firebase.firestore().collection('programList').doc(program).get()
            .then((doc) => {
                if(doc.exists){
                    setPrograms((prev) => [...prev, {programSlug: doc.id, ...doc.data()}])
                }
            })
        })

    },[leadBrochuresRequested])

    const convertToSlug = (programName) => {
        const slug = programName.replace(/\s+/g, '-').replace(/:/g,"").toLowerCase(); //convert whole programName into slug
    }



    return (
        <>
            <DashboardNavbar />
            <div className="container">
                <h2 className="text-white">You've requested the brochures of following programs in the past</h2>

            <div className="row">
                            {programs.map((item, key) => {
                                if(+item.position >= 0){
                                    return(
                                            <div className="col-md-4" key={key}>
                                                <Link className="link" to={`/program/${item.programSlug}`}>
                                                    <Card className="mb-3" bg="transparent" text="white">
                                                        <Card.Img variant="top" src={item.programThumbnail} />
                                                        <Card.Body className="px-0">
                                                            <span className="small mb-4 text-warning">STARTS OCTOBER 2020</span>
                                                            <Card.Title>{item.programTitle}</Card.Title>
                                                            <Card.Text className="small">{item.programSubTitle}</Card.Text>
                                                            <div className="row">
                                                                <div className="col-12 ">
                                                                    {item.associatedUniversities && <div>
                                                                        <p className="small font-weight-bold text-muted pt-3 border-top">Learn and interact through live online classes with world class faculty from</p>
                                                                        <div className="row">
                                                                            {item.associatedUniversities.map(university =>
                                                                                <p className="col-md-6 mb-1 small ">{university}</p>
                                                                            )}
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                                <div className="col-12">
                                                                 <p className="small mt-3">Attested with a record of attendance from the <strong>University of California, Los Angeles (UCLA) Extension</strong></p>
                                                                 <img className="mb-3" 
                                                                    src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac" 
                                                                 width="30%" />
                                                                </div>
                                                            </div>
                                                            <span className="text-muted">Learn More <FontAwesomeIcon icon={faArrowRight} size="sm" color="grey" /></span>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            </div>
                                    )
                                }
                            })}
                        </div>

            </div>
            <AcademyFooter />
        </>
    );
}


export default UserInfo;
