import React from 'react';
import { FilePond } from 'react-filepond';
import {withRouter} from 'react-router-dom';
import firebase from 'firebase';
import moment from 'moment';
import {storageRef, db} from '../../firebase'
import DashboardActions from '../Dashboard/DashboardActions';
import DashboardNavbar from '../Dashboard/DashboardNavbar';

class EditFaculty extends React.Component{
    state={
        editSelectTitle:'',
        editDob:'',
        editGender:'',
        editFirstName:'',
        editMiddleName:'',
        editLastName:'',
        editDescription:'',
        editImage:[],
        dobDateMax: moment().format("YYYY-MM-DD"),
        editLanguages:[],
        editMailInput:[{mail:''}],
        editEduInput:[{course:'', college:'', startDate:'', endDate:''}],
        editExpInput:[{designation:'', organisation:'', startDate:'', endDate:''}],
        date: moment().format("YYYY-MM"),
        editDocs:[],
        url:'',
        progress:null
    }
    componentDidMount(){
        var docRef = db.collection("faculty").doc(this.props.match.params.id);
        docRef.get().then((doc) => {
        if (doc.exists) {
            const id = doc.id;
            const fullName = doc.data().fullName;
            const description = doc.data().description;
            const languages = doc.data().languages;
            const education = doc.data().education;
            const experience = doc.data().experience;
            const profilePic = doc.data().profilePicUrl;
            const email = doc.data().email;
            const gender =  doc.data().gender;
            const dob = doc.data().dob;
            this.setState({ editDocs: [...this.state.editDocs, {fullName, id ,description, languages, education, experience, profilePic, email, gender,dob }] })
            this.setState({
                editSelectTitle : doc.data().fullName.selectTitle,
                editDob : doc.data().dob,
                editGender : doc.data().gender,
                editFirstName : doc.data().fullName.firstName,
                editMiddleName : doc.data().fullName.middleName,
                editLastName : doc.data().fullName.lastName,
                editDescription : doc.data().description,
                url : doc.data().profilePicUrl,
                editLanguages : doc.data().languages,
                editMailInput : doc.data().email,
                editEduInput : doc.data().education,
                editExpInput : doc.data().experience,
            })
            } else {
                console.log("No such document!");
            }
        })
    }
    editUploadHandle = (e) => {
        e.preventDefault()
        var that = this
        var file = this.state.editImage;
        var metadata = {contentType: 'image/jpeg' };
        var uploadTask = storageRef.child('faculty/' + file.name).put(file, metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        this.setState({progress: Math.round(progress)})
        switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING: 
                console.log('Upload is running');
                break;
        }
        },error => {
            switch (error.code){
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
        }},() => {
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){
            that.setState({url:downloadURL})
        });
        });
    }
    editCheckboxHandle = (event) => {
        if(event.target.checked){
          this.setState({editLanguages:[...this.state.editLanguages, event.target.value]})
        }
    }
    editHandleMailInputChange = (index, e) => {
        e.preventDefault()
        var values =[...this.state.editMailInput];
        if(e.target.name === "mail"){
          values[index].mail =e.target.value;
        }
        this.setState({editMailInput:values})
    }
    editEMailAddField = (e) => {
        e.preventDefault();
        var values = [...this.state.editMailInput];
        values.push({mail:''})
        this.setState({editMailInput:values})
    }
    editEMailRemoveField = (e, index) => {
        e.preventDefault();
        var values = [...this.state.editMailInput];
        values.splice(index, 1);
        this.setState({editMailInput:values})
    }
    editHandleEduInputChange = (index, e) => {
        e.preventDefault()
        const values = [...this.state.editEduInput];
        if (e.target.name === "course") {
          values[index].course = e.target.value;
        } else if(e.target.name==="college") {
          values[index].college = e.target.value;
        } else if(e.target.name==="start_date"){
          values[index].startDate = e.target.value;
        } else {
          values[index].endDate = e.target.value;
        }
          this.setState({editEduInput:values})
    }
    editEducationAddField = (e) => {
        e.preventDefault()
        const values = [...this.state.editEduInput];
        values.push({ course: '', college: '',startDate:'', endDate:'' });
        this.setState({editEduInput:values})
    };
   
    editEducationRemoveField = (e, index) => {
        e.preventDefault()
        const values = [...this.state.editEduInput];
        values.splice(index, 1);
        this.setState({editEduInput:values})
    }
    editHandleExpInputChange = (index,event) => {
        event.preventDefault()
        const value = [...this.state.editExpInput];
        if (event.target.name === "designation") {
          value[index].designation = event.target.value;
        } else if(event.target.name==="organisation") {
          value[index].organisation = event.target.value;
        } else if(event.target.name==="start_date"){
          value[index].startDate = event.target.value;
        } else {
          value[index].endDate = event.target.value;
        }
          this.setState({editExpInput:value})
    }
    editExperienceAddField = (e) => {
        e.preventDefault()
        const values = [...this.state.editExpInput];
        values.push({ designation: '', organisation: '',startDate:'', endDate:'' });
        this.setState({editExpInput:values})
    };
    
    editExperienceRemoveField = (e, index) => {
        e.preventDefault()
        const values = [...this.state.editExpInput];
        values.splice(index, 1);
        this.setState({editExpInput:values})
    }
    submitUpdate = (e, doc, index) => {
        e.preventDefault()
        console.log(doc)
        if(this.state.editMiddleName === '' || this.state.editMiddleName === undefined){
            const editFullName = {selectTitle:this.state.editSelectTitle, firstName:this.state.editFirstName, lastName:this.state.editLastName}
            db.collection('faculty').doc(doc.id).update({
                fullName:editFullName,
                description:this.state.editDescription,
                languages:this.state.editLanguages,
                dob:this.state.editDob,
                email:this.state.editMailInput,
                education:this.state.editEduInput,
                experience:this.state.editExpInput,
                profilePicUrl:this.state.url
            }).then(()=>{
                    this.setState({
                        editDocs:[]
                    })
                    alert('Updated Successfully')
                    this.props.history.push('/dashboard/');
                })
        }else{
            const editFullName = {selectTitle:this.state.editSelectTitle, firstName:this.state.editFirstName, lastName:this.state.lastName , lastName:this.state.editLastName}
            db.collection('faculty').doc(doc.id).update({
                fullName:editFullName,
                description:this.state.editDescription,
                languages:this.state.editLanguages,
                dob:this.state.editDob,
                email:this.state.editMailInput,
                education:this.state.editEduInput,
                experience:this.state.editExpInput,
                profilePicUrl:this.state.url
            }).then(()=>{
                    this.setState({
                        editDocs:[]
                    })
                    alert('Updated Successfully');
                    this.props.history.push('/dashboard/');
                })

        }
        
    }
    render(){
        return (
             <div className='container text-white'>
                <DashboardNavbar />
                <h3 className="text-white mt-3 mb-3">Edit Faculty</h3>
                { this.state.editDocs.map((doc, index) => (
                <div key={index}>
                
                    <form className='form-group' onSubmit={(e) => this.submitUpdate(e, doc, index)}>
                
                        <div className='row mb-5'>
                            <div className='col-md-6' > 
                                <label>Title :-</label><br/>
                                <select 
                                    className="custom-select mr-sm-2 " 
                                    defaultValue={doc.fullName.selectTitle} 
                                    onChange={event => this.setState({editSelectTitle:event.target.value})}>
                                    <option>Select Title</option>
                                    <option value='Prof'>Prof</option>
                                    <option value='Dr'>Dr</option>
                                    <option value='Mr.'>Mr</option>
                                    <option value='Ms'>Ms</option>
                                    <option value='Mrs'>Mrs</option>
                                    <option value='Smt'>Smt</option>
                                    <option value='Shri'>Shri</option>
                                </select>
                            </div>

                            

                            <div className='col-md-6'  >
                                <label>Gender:-</label><br/>
                                <select 
                                    className="custom-select mr-sm-2 " 
                                    defaultValue={doc.gender} 
                                    onChange={event => this.setState({editGender:event.target.value})}>
                                    <option>Select Gender</option>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                    <option value='Others'>Others</option>
                                </select>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-4'>
                                <label>FirstName :-</label><br/>
                                    <input 
                                    className='form-control' 
                                    placeholder='Enter your First Name'
                                    onChange={event => this.setState({editFirstName: event.target.value })}
                                    defaultValue={doc.fullName.firstName}
                                   /><br />
                            </div>

                            <div className='col-md-4'>
                                <label>MiddleName :-</label><br/>
                                    <input 
                                    className='form-control' 
                                    placeholder='Enter your Middle Name'  
                                    onChange={event => {this.setState({editMiddleName:event.target.value}) }}
                                    defaultValue={doc.fullName.middleName}
                                   /><br />
                            </div>

                            <div className='col-md-4'>
                                <label>LastName :-</label><br/>
                                    <input 
                                    className='form-control' 
                                    placeholder='Enter your Last Name'
                                    onChange={event => this.setState({editLastName: event.target.value})}
                                    defaultValue={doc.fullName.lastName}
                                    /><br />
                            </div>
                        </div>  
                
                        <div className='mb-3' >  
                            <div className='row ml-2' >
                                <label> Description :-</label><br/>
                                    <textarea 
                                    className='form-control' 
                                    placeholder='About yourself'
                                    onChange={event => this.setState({editDescription:event.target.value})}
                                    defaultValue={doc.description}
                                    />
                            </div>
                        </div>

                        <div className='row mb-3'>    
                            <div className='col-md-6 mb-2'>
                                <label  >Upload Profile pic:- </label>
                                    <FilePond  
                                        allowMultiple={false}
                                        onupdatefiles={fileItems => fileItems.map((fileItem) => this.setState({editImage:fileItem.file}))}>
                                    </FilePond>
                                    <button className='btn btn-primary mb-2' onClick={(e) => this.editUploadHandle(e)}>
                                        Upload
                                    </button>
                                    {(this.state.progress===null) ? (null):(
                                        <div className="progress">
                                            <div 
                                                className="progress-bar progress-bar-striped progress-bar-animated" 
                                                role="progressbar" 
                                                style= {{width:`${this.state.progress}%`}}
                                                aria-valuenow="10" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">{this.state.progress}%</div>
                                        </div>
                                    )}
                            </div>

                
                            <div className='col-md-6 ' >
                                <div >Languages:- </div><br/>
                                    <label>English
                                    <input className="mr-2"  type='checkbox' value='English' name='English' onChange={this.checkboxHandle}/></label>
                                    <label>Hindi
                                    <input className="mr-2" type='checkbox' value='Hindi' name='Hindi'  onChange={this.checkboxHandle}/></label>
                                    <label>Spanish
                                    <input className="mr-2" type='checkbox' value='Spanish' name='Spanish'  onChange={this.checkboxHandle}/></label>
                                    <label>German
                                    <input className="mr-2" type='checkbox' value='German' name='German'  onChange={this.checkboxHandle}/></label>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6 '>
                                {doc.email.map((mail,index) => (
                                    <div key={index}>
                                    {this.state.editMailInput.map((mailInput, index) => (
                                        <div>
                                            <div className="row ml-1 mb-3">
                                                <label>Email:-</label>
                                                    <input 
                                                    className='form-control' 
                                                    type='text' placeholder='Email' 
                                                    name='mail'
                                                    defaultValue={mail.mail} 
                                                    onChange={event => this.editHandleMailInputChange(index, event)} />
                                                <button className='btn btn-primary' onClick={(e) => this.editEMailAddField(e)}>+</button>
                                                <button className='btn btn-primary' onClick={(e)=> this.editEMailRemoveField(e,index)} >-</button>
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                ))}
                            </div>
                            <div className='col-md-6 ' >
                                <label>Date of Birth:-</label>
                                <input 
                                    className='form-control' 
                                    type='date' 
                                    placeholder='DOB' 
                                    name='dob' 
                                    max={this.state.dobDateMax}
                                    defaultValue={doc.dob}
                                    onChange={event => this.setState({editDob:event.target.value})}/>
                            </div>
                        </div>  
                          

                        {doc.education.map((education,index) =>  (
                            <div key={index}>
                            {this.state.editEduInput.map((editInput, index) => (
                                <div className=' border border-dark rounded my-4'>
                                    <div className='mx-3 my-3'>
                                        <h4>Education :-</h4>
                                            <div className='form-group'>
                                                <input 
                                                    className='form-control' 
                                                    type='text' 
                                                    placeholder='Course' 
                                                    name='course'
                                                    defaultValue={education.course}
                                                    onChange={event => this.editHandleEduInputChange(index, event)}/>
                                            </div> 
                                            <div className='form-group'>
                                                <input 
                                                    className='form-control' 
                                                    type='text' 
                                                    placeholder='College' 
                                                    name='college'
                                                    onChange={event => this.editHandleEduInputChange(index, event)}
                                                    defaultValue={education.college}/>
                                            </div> 
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <label>Start Date</label>
                                                        <input 
                                                        className='form-control' 
                                                        type='month'  
                                                        placeholder='Start Date'  
                                                        name='start_date'
                                                        max={this.state.date}
                                                        defaultValue={education.startDate} 
                                                        onChange={event => this.editHandleEduInputChange(index, event)}/>
                                                </div> 
                                                <div className='col-md-6'>
                                                    <label>End Date</label>
                                                        <input 
                                                            className='form-control' 
                                                            type='month' 
                                                            placeholder='End Date' 
                                                            name='End Date'
                                                            max={this.state.date}
                                                            defaultValue={education.endDate}
                                                            onChange={event => this.editHandleEduInputChange(index, event)}/>
                                                </div>
                                            </div>
                                            <div className='text-center my-3'>
                                                <button className='btn btn-primary mr-2' onClick={(e) => this.editEducationAddField(e)}>+</button>
                                                <button className='btn btn-primary' onClick={(e)=> this.editEducationRemoveField(e,index)} >-</button>
                                            </div>
                                            
                                    </div>
                                </div>
                                
                            ))}
                            </div>
                        ))}
                        {doc.experience.map((experience,index) => (
                            <div key={index}>
                            {this.state.editExpInput.map((editInput, index) => (
                                <div className=' border border-dark rounded my-4'>
                                    <div className='mx-3 my-3'>
                                        <h4>Experience :-</h4>
                                            <div className='form-group'>
                                                <input 
                                                    className='form-control' 
                                                    type='text' 
                                                    placeholder='Designation'  
                                                    name='designation'
                                                    defaultValue={experience.designation}
                                                    onChange={event => this.editHandleExpInputChange(index, event)}/>
                                            </div> 
                                            <div className='form-group'>
                                                <input 
                                                    className='form-control' 
                                                    type='text'
                                                    placeholder='Organisation'
                                                    name='organisation'
                                                    defaultValue={experience.organisation}
                                                    onChange={event => this.editHandleExpInputChange(index, event)}/>
                                            </div>
                                            <div className='row'> 
                                                <div className='col-md-6'>
                                                    <label>Start Date</label>
                                                        <input 
                                                            className='form-control' 
                                                            type='month' 
                                                            placeholder='Exp Start Date' 
                                                            name='start_date' 
                                                            max={this.state.date}
                                                            defaultValue={experience.startDate}
                                                            onChange={event => this.editHandleExpInputChange(index, event)}/>
                                                </div> 
                                                <div className='col-md-6'>
                                                    <label>End Date</label>
                                                        <input 
                                                        className='form-control' 
                                                        type='month' 
                                                        placeholder='Exp End Date'  
                                                        name='end_date'
                                                        defaultValue={experience.endDate}
                                                        max={this.state.date}
                                                        onChange={event => this.editHandleExpInputChange(index, event)}/>
                                                </div> 
                                            </div>
                                            <div>
                                                <button className='btn btn-primary' onClick={(e) => this.editExperienceAddField(e)}>+</button>
                                                <button className='btn btn-primary' onClick={(e)=> this.editExperienceRemoveField(e,index)} >-</button>
                                            </div>
                                    </div>
                                </div>
                            ))}
                            </div>))}
                        <button className="btn btn-success btn-lg">Update</button>
                    </form>
                </div>
                ))}      
            </div>
        )
    }
}

export default withRouter(EditFaculty);