import React, { useEffect, useState, useContext, useRef } from 'react';
import { AuthContext } from "./Auth";
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import DashboardNavbar from '../Dashboard/DashboardNavbar'
import Axios from 'axios';
import moment from 'moment';
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import * as Yup from 'yup';




const EditParticipant = (props) => {
    const {currentUser} = useContext(AuthContext);
    const [programs, setPrograms] = useState([]);
    const [programList, setProgramList] = useState([]);
    const [order, setOrder] = useState();
    const [validProps, setValidProps] = useState(false);
    const [suborders, setSuborders] = useState([]);
    const [orderParticipants, setOrderParticipants] = useState([]);
    const [currentSuborder, setCurrentSuborder] = useState(null);
    const renderCount = useRef(0);
    //checking whether url has state or not 
    useEffect(() => {
        if(!props.location.state || !props.location.state.order_id ){
            props.history.push('/orderinfo/');
        }
        else{
            setValidProps(true);
        }
    },[])


    useEffect(() => {
        if(validProps){
            Axios.get(`https://dev.igesia.com/orders/success`)
            .then((res) => {
                let tempOrder = '';
                res.data.data.map((order) => {
                    if(order.order.order_id === props.location.state.order_id){ 
                        tempOrder = order;
                    }
                })
                setOrder(tempOrder);
            })
        }
    },[validProps])

    useEffect(() => {
        if(validProps){
            firebase.firestore().collection('orderParticipants').doc(props.location.state.order_id).get().then((doc) => {
                if(doc.exists){
                    setSuborders(doc.data().suborders);
                }
            })
        }
    },[validProps])

    // Getting programs
    useEffect(() => {
        let programsRef = firebase.firestore().collection('executivePrograms');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programId: doc.id, ...doc.data() });
                });
                setPrograms(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])
    // getting orderParticipants
    useEffect(() => {
        if(validProps){
            let orderParticipantsRef = firebase.firestore().collection('orderParticipants').doc(props.location.state.order_id).get()
                .then((doc) => {
                    if(doc.exists){
                        doc.data().suborders.map((sub) => {
                            if(sub.product_id === props.location.state.product_id){
                                setOrderParticipants(doc.data());
                            }
                        })
                    }
            });
        }
    },[validProps]);

    useEffect(() => {
        if(validProps){
            orderParticipants.suborders.map((sub) => {
                if(sub.product_id === props.location.state.product_id){
                    setCurrentSuborder(sub)
                }
            })
        }
    },[orderParticipants])

    useEffect(() => {
        let programsRef = firebase.firestore().collection('programList');
        programsRef.get()
            .then(snapshot => {
                let tempProgramsList = [];
                snapshot.forEach(doc => {
                    tempProgramsList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramsList);
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    },[])

    const getProgramNameHandler = (id) => {
        let programName = '';
        programs.forEach((program) => {
            if(program.programId === id){
                programName = program.programName;
            }
        })
        return programName;
    }
    const getProgramThumbnailHandler = (id) => {
        let programThumbnail = '';
        programList.forEach((program) => {
            if(program.programId === id){
                programThumbnail = program.programThumbnail;
            }
        })
        return programThumbnail;
    }
    let UserDetailsSchema = null;
    if(validProps){
        UserDetailsSchema = Yup.object().shape({
            users: Yup.array().of(
                Yup.object().shape({
                    firstName: Yup.string().required('Required'),
                    lastName: Yup.string().required('Required'),
                    emailAddress: Yup.string().required('Required'),
                    phoneNumber: Yup.string().required('Required'),
                })
            )
            .max(props.location.state.quantity)
            .required('Required')
        });
    }
    //error messages

    const ErrorMessages = ({ name }) => (
        <Field
        name={name}
        render={({ form }) => {
            const error = getIn(form.errors, name);
            const touch = getIn(form.touched, name);
            return touch && error ? <div className="text-danger ml-2">{error}</div> : null;
        }}
        />
    );

    const updateSuborder = (updatedSuborders) =>{
        firebase.firestore().collection('orderParticipants').doc(props.location.state.order_id).set({
            order_id: props.location.state.order_id,
            suborders: updatedSuborders,
            createdAt: new Date(),
            participantsAddedByUID: currentUser.uid
        }).then(() => {
            alert('Participant Edited!')
            props.history.push('/orderinfo')
        })
    }



    return (
        <>
        {
            (validProps && order) ? 
            <>
            <DashboardNavbar />
            <div className="container" style={{maxWidth: 920}}>
            <h2 className="text-white">Assign Participant</h2>
            <div class="card bg-dark text-white my-3 py-3">
            <div class="card-header d-flex justify-content-between small flex-column flex-md-row">
                                    <div className="d-flex d-md-block justify-content-between">
                                        <div>
                                            Order Placed
                                        </div>
                                        <div>
                                            {moment(order.order.createdAt).format('LLL')}
                                        </div>
                                    </div>
                                    <div className="d-flex d-md-block justify-content-between">
                                        <div>
                                            Total
                                        </div>
                                        <div>
                                            ₹ {order.transaction_amount/100}
                                        </div>
                                    </div>
                                    <div className="d-flex d-md-block justify-content-between">
                                        <div>
                                            Bought By
                                        </div>
                                        <div>
                                            {order.order.lead.first_name} {order.order.lead.last_name}
                                        </div>
                                    </div>
                                    <div className="d-flex d-md-block justify-content-between">
                                        <div>
                                            Order ID
                                        </div>
                                        <div>
                                            {order.order.order_id}
                                        </div>
                                    </div>
                </div>
                {
                    order.order.suborders.map((suborder) => {
                        if(suborder.product_id === props.location.state.product_id){
                            return(
                                
        
                                                <div class="card-body d-flex align-items-center small">
                                                    <img src={getProgramThumbnailHandler(suborder.product_id)} className="img-fluid d-none d-sm-block" style={{width: '100px'}}></img>
                                                    <div className="d-flex w-100 align-items-center">
                                                        <div className="d-flex flex-column ml-3 justify-content-around w-50">
                                                        <span class="card-text">{getProgramNameHandler(suborder.product_id)}</span>
                                                        <span class="card-text mt-3 text-secondary">Quantity {suborder.quantity}</span>
                                                        </div>
                                                    </div>
                                                </div>


                        );

                        }
                    })
                }

            {/* form begins */}

                <Formik
                                                    initialValues={{
                                                        users: currentSuborder ? currentSuborder.participants : []
                                                    }}
                                                    enableReinitialize
                                                    validationSchema={UserDetailsSchema}
                                                    onSubmit={ values => {
                                                        const updatedSuborders = suborders.map((sub) => {
                                                            if(sub.product_id === props.location.state.product_id){
                                                                sub.participants = values.users;
                                                                return(sub)
                                                            }else{
                                                                return(sub)
                                                            }
                                                        });
                                                        console.log(updatedSuborders)
                                                        updateSuborder(updatedSuborders);
                                                    }}
                                                  >
                                                      {({ errors, touched, isValidating, values }) => (

                                                           <Form className="col-md-12">

                                                            <FieldArray name="users">
                                                                {({ insert, remove, push }) => (
                                                                <div>
                                                                    {values.users.length > 0 &&
                                                                    values.users.map((tag, index) => (
                                                                        <>
                                                                        <h6 className="font-weight-bolder">Personal details {index + 1}</h6>
                                                                        <div className="form-row">
                                                                        <div className="form-label-group col-6">
                                                                            <Field
                                                                            name={`users[${index}].firstName`}
                                                                            placeholder="First Name"
                                                                            type="text"
                                                                            className='form-control'
                                                                            />
                                                                            <label for={`users[${index}].firstName`}  className="mb-1">First name</label>
                                                                            <ErrorMessages name={`users[${index}].firstName`} />
                                                                        </div>
                                                                        <div className="form-label-group col-6">
                                                                            <Field
                                                                            name={`users[${index}].lastName`}
                                                                            placeholder="Last Name"
                                                                            type="text"
                                                                            className='form-control'
                                                                            />
                                                                            <label for={`users[${index}].lastName`} className="mb-1">Last name</label>
                                                                            <ErrorMessages name={`users[${index}].lastName`} />
                                                                        </div>
                                                                        </div>
                                                                        <div className="form-row">
                                                                        <div className="form-label-group col-6">
                                                                            <Field
                                                                            name={`users[${index}].emailAddress`}
                                                                            placeholder="Personal Email"
                                                                            type="email"
                                                                            className='form-control'
                                                                            />
                                                                            <label for={`users[${index}].emailAddress`} className="mb-1">Email</label>
                                                                            <ErrorMessages name={`users[${index}].emailAddress`} />
                                                                        </div>
                                                                        
                                                                        
                                                                        <div className="form-label-group col-6">
                                                                            <Field
                                                                            name={`users[${index}].phoneNumber`}
                                                                            placeholder="Phone Number"
                                                                            type="tel"
                                                                            className='form-control'
                                                                            />
                                                                            <label for={`users[${index}].phoneNumber`} className="mb-1">Mobile</label>
                                                                            <ErrorMessages name={`users[${index}].phoneNumber`} />
                                                                        </div>
                                                                        </div>





                                                                        {/* <div className="col text-center">
                                                                            <button
                                                                            type="button"
                                                                            className="btn btn-outline-danger btn-sm"
                                                                            onClick={() => remove(index)}
                                                                            >
                                                                            X
                                                                            </button>
                                                                        </div> */}


                                                                        </>
                                                                    ))}
                                                                    {/* <button
                                                                    type="button"
                                                                    className="btn btn-outline-light btn-sm"
                                                                    onClick={() => push()}
                                                                    >
                                                                    Add Participant
                                                                    </button> */}
                                                                </div>
                                                                )}
                                                            </FieldArray>
                                                            <div className="text-center">
                                                                <button className="btn btn-outline-light btn-sm" type="submit">Submit</button>
                                                            </div>
                                                        
                                                           </Form>
                                                      )}

                                                  </Formik>




        {/* form ends */}

                </div>
            </div>
            <AcademyFooter />
            </>
        : null
        }
        </>
    );
}



export default EditParticipant;
