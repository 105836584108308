import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    Image,
    Spinner,
    Modal,
    Alert,
    Badge
} from 'react-bootstrap';
import {
    Link
} from "react-router-dom";
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faFileExcel, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AcademyNavbar from '../Academy/AcademyNavbar';
import AcademyFooter from '../Academy/AcademyFooter';
import firebase from 'firebase';
import { library } from '@fortawesome/fontawesome-svg-core';
import TextareaAutosize from 'react-textarea-autosize';
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import FileUpload from './FileUpload';
import BrochureUpload from './BrochureUpload';
import MyTextAreaField from './MyTextAreaField';
import * as Yup from 'yup';

import { db } from '../../firebase';
library.add(faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faCheck, faTimes);

//validation
const ProgramBuilderSchema = Yup.object().shape({
    aboutProgram: Yup.string()
    .required('Required'),
    certificationText: Yup.string()
    .required('Required'),
    // faculty: Yup.array()
    // .of(
    //     Yup.object().shape({
    //         bio: Yup.string().required('Required'),
    //         facultyName: Yup.string().required('Required')
    //     })
    // ),
    // feebreakout
    learningJourney: Yup.object().shape({
        intro: Yup.string()
        .required('Required'),
        // modules: Yup.array()
        // .of(
        //     Yup.object().shape({
        //         moduleName: Yup.string().required('Required'),
        //     })
        // ),
    }),
    learningObjectives: Yup.object().shape({
        intro: Yup.string()
        .required('Required'),
        learningOutcomes: Yup.string()
        .required('Required'),
        learningPoints: Yup.array()
        .of(
            Yup.object().shape({
                content: Yup.string().required('Required'),
                title: Yup.string().required('Required')
            })
        )
    }),
    programFeatures: Yup.array()
    .of(
        Yup.object().shape({
            content: Yup.string().required('Required')
        })
    )
    ,
    programName: Yup.string()
    .min(5, 'Too Short')
    .required('Required'),
    programShortDescription: Yup.string()
    .min(5, 'Too Short')
    .required('Required'),
    recordOfParticipation : Yup.string()
    .min(5, 'Too Short')
    .required('Required'),
    // tags
    who: Yup.object().shape({
        intro: Yup.string()
        .required('Required'),
        questions: Yup.array()
        .of(
            Yup.string().required('Required')
        )
    })
});

//error messages

const ErrorMessages = ({ name }) => (
    <Field
      name={name}
      render={({ form }) => {
        const error = getIn(form.errors, name);
        const touch = getIn(form.touched, name);
        return touch && error ? <div className="text-danger ml-2">{error}</div> : null;
      }}
    />
);




function ProgramBuilder(props) {
    const [coverImageUrl, setCoverImageUrl] = useState('');
    const [facultyImageUrl, setFacultyImageUrl] = useState('');
    const [studentsImageUrl, setStudentsImageUrl] = useState('');
    const [recordOfParticipationImageUrl, setRecordOfParticipationImageUrl] = useState('');
    const [brochureUrl, setBrochureUrl] = useState('');
    const [facultyDB, setFacultyDB] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState([]);
    const [showFacultySelectionModule, setShowFacultySelectionModule] = useState(false);
    useEffect(() => {
        db.collection('faculty').get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setFacultyDB((prev) => [
                    ...prev,
                    {
                        id: doc.id,
                        data: doc.data(),
                        selected: false,
                        order: -1
                    }
                ])
            })
        })
    },[]);

    //faculty select and delete methods

    const facultySelectHandler = (id) => {
        const updatedFaculty = facultyDB;
        updatedFaculty.map((faculty) => {
            if(faculty.id === id){
                faculty.selected = true;
                setSelectedFaculty((prevState) => [...prevState,{id: faculty.id, firstName: faculty.data.fullName.firstName, lastName: faculty.data.fullName.lastName, description: faculty.data.description}])
            }
        })
        setFacultyDB(updatedFaculty);
    }
    const facultyRemoveHandler = (id) => {
        const updatedFaculty = facultyDB;
        updatedFaculty.map((faculty) => {
            if(faculty.id === id){
                faculty.selected = false;
                removeSelectedFacultyHandler(id);
            }
        })
        setFacultyDB(updatedFaculty);
    }

    //selectedfacultyremoveHandler

    const removeSelectedFacultyHandler = (id) => {
        const originalSelectedFaculty = selectedFaculty;
        const updatedSelectedFaculty = originalSelectedFaculty.filter((faculty) => faculty.id !== id)
        setSelectedFaculty(updatedSelectedFaculty);
    }

    //selectedFaculty refs to values

    const addFaculty = () => {
        let refs = [];
        selectedFaculty.map((selected) => {
            const selectedRef = db.doc('faculty/' + selected.id);
            refs.push(selectedRef);
        })
        return(refs);
    }

    // faculty modal
    const MyVerticallyCenteredModal = (props) => {
        const [searchFaculty, setSearchFaculty] = useState(facultyDB);
        const searchFacultyHandler = (e) => {
            let tempFaculty = [];
            tempFaculty = facultyDB.filter(faculty => faculty.data.fullName.firstName.toLowerCase().includes(e.target.value))
            setSearchFaculty(tempFaculty);
        }
        return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
        >
            <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
                <form class="form-inline">
                    <input class="form-control mr-sm-2" type="input" placeholder="Search" aria-label="Search" onKeyUp={(e) => searchFacultyHandler(e)}></input>
                </form>
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row overflow-auto" style={{maxHeight: '50vh'}}>
                    {
                        searchFaculty.map((faculty, index) => {
                            return(
                                <div className="col-xs-12 col-md-3 my-2">
                                    <Card className={'text-center h-100' + (faculty.selected ? ' card-selected' : ' ')} key={index}>
                                    <div style={{height: '167px'}}>
                                        <Card.Img variant="top" style={{width: '100%', height: '100%', objectFit: 'cover'}}  src={faculty.data.profilePicUrl} />
                                    </div>  
                                    <Card.Body className="p-2">
                                        <Card.Title className="h6" >{`${faculty.data.fullName.firstName}  ${faculty.data.fullName.lastName}`}</Card.Title>
                                        {
                                            faculty.selected ? <Button variant="danger" className={"btn-circle btn-cross"} onClick={() => facultyRemoveHandler(faculty.id)} ><FontAwesomeIcon icon={faTimes} color="white" /></Button>
                                            : <Button variant="success" className={"btn-circle btn-cross"} onClick={() => facultySelectHandler(faculty.id)} ><FontAwesomeIcon icon={faCheck} color="white" /></Button>
                                        }
                                        
                                    </Card.Body>
                                    </Card>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
        );
    }

    

    return(
        <div className="academyProgram">
            <AcademyNavbar />
            <Formik
            validationSchema={ProgramBuilderSchema}
            initialValues={{
                aboutProgram: '',
                brochure: '',
                certificationAuthority: 'UCLAExt',
                certificationText: '',
                faculty: [],
                feeBreakout: {baseAmount: 95000, tax: 18},
                learningJourney: {intro: '', modules: [{description: '', moduleName: '', moduleType: '', sessions: null}]},
                learningObjectives: {intro: '', learningOutcomes: '', learningPoints: [{content: '', title: ''}]},
                programFeatures: [{content: '', icon: 'lightbulb'}, {content: '', icon: 'chalkboard-teacher'}, {content: '', icon: 'video'}, {content: '', icon: 'users'}],
                programName: '',
                programShortDescription: '',
                recordOfParticipation: '',
                tags: [],
                who: { intro: '', questions: []},
                coverImageUrl: '',
                facultyImageUrl: '',
                studentsImageUrl: '',
                recordOfParticipationImageUrl: '',
                associatedUniversities: [],
                contacts: [{contactEmail: '', contactMobile: '', contactName: ''}]
            }}
            onSubmit={(values, { resetForm }) => {
                const countryCode = window.localStorage.getItem("phoneCountryCode") ? window.localStorage.getItem("phoneCountryCode") : "+91";
                values.coverImageUrl = coverImageUrl;
                values.facultyImageUrl = facultyImageUrl;
                values.studentsImageUrl = studentsImageUrl;
                values.recordOfParticipationImageUrl = recordOfParticipationImageUrl;
                values.brochure = brochureUrl;
                values.faculty = addFaculty();
                values.contacts.map((contact) => {
                    if(contact.contactMobile){
                        contact.contactMobile = `${countryCode} ${contact.contactMobile}`
                    }
                })
                db.collection('executiveProgramsTest').doc(props.match.params.id).set(values).then(() => {
                    console.log(values);
                    resetForm();
                    alert('Program added, and is waiting for approval!');
                    props.history.push('/dashboard/');
                });

            }}
            >
                {({ errors, touched, isValidating, values }) => (
                <Form className={'container-fluid py-5 program-content-web'}>

                <div className="container-fluid bg-black pt-0 pt-md-5">
                <div className="container">
                        <div class="flex-container row">
                            <div className="cover-image transparent--bg col-sm-4 col-xs-12">
                            <FileUpload url={setCoverImageUrl} imagePreview={true} />
                            </div>
                            <div className="d-md-block transparent--bg col-sm-4 col-xs-12">
                            <FileUpload url={setFacultyImageUrl} imagePreview={true}/>
                            </div>
                            <div className="d-md-block transparent--bg col-sm-4 col-xs-12">
                            <FileUpload url={setStudentsImageUrl} imagePreview={true}/>
                            </div>
                        </div>



                    <div className="row program-header pb-5">
                        <div className="col-md-6 text-white mb-3">
                            <h1>
                                <Field  className={"form-control font-weight-light h2" + (errors.programName && touched.programName ? ' is-invalid' : '') } name="programName" placeholder="Program Name" />
                                {errors.programName && touched.programName ? (
                                <div className="text-danger ml-2 h5">{errors.programName}</div>
                                ) : null}
                            </h1>
                            
                            <Field component="textarea" className={"form-control mb-2" + (errors.programShortDescription && touched.programShortDescription ? ' is-invalid' : '')} name="programShortDescription" placeholder="Program Short Description" />
                            {errors.programShortDescription && touched.programShortDescription ? (
                            <div className="text-danger ml-2">{errors.programShortDescription}</div>
                            ) : null}
                            <FieldArray name="tags">
                            {({ insert, remove, push }) => (
                                <>
                            <div className="row">
                                {values.tags.length > 0 &&
                                values.tags.map((tag, index) => (
                                    <div className="form-group col-sm-6 d-flex align-items-center" key={index}>
                                        <label htmlFor={`tags[${index}]`}></label>
                                        <Field
                                        name={`tags[${index}]`}
                                        id={`tags[${index}]`}
                                        placeholder="Tag"
                                        type="text"
                                        className='col-xs-8 form-control'
                                        />
                                    <div className="col">
                                        <button
                                        type="button"
                                        className="secondary btn btn-danger btn-cross"
                                        onClick={() => remove(index)}
                                        >
                                        X
                                        </button>
                                    </div>
                                    </div>
                                ))}
                            </div>
                                <div className="text-center">
                                    <button
                                    type="button"
                                    className="secondary mt-3 mb-3 btn btn-outline-light btn-sm"
                                    onClick={() => push()}
                                    >
                                    Add Tag
                                    </button>
                                </div>
                            </>
                            )}
                        </FieldArray>
                            {
                             values.tags.map((item, index) => (
                                <span key={item} class="badge text-uppercase badge-dark mr-2 mb-2 font-weight-normal p-2" >{item}</span>
                            ))}
                            <div>
                                <p className="text-white">Learn and interact through live online classes with world class faculty from</p>
                                <div>
                                    <FieldArray name="associatedUniversities">
                                        {({ insert, remove, push }) => (
                                            <>
                                        <div className="row">
                                            {values.associatedUniversities.length > 0 &&
                                            values.associatedUniversities.map((uni, index) => (
                                                <div className="form-group col-sm-6 d-flex align-items-center" key={index}>
                                                    <Field
                                                    name={`associatedUniversities[${index}]`}
                                                    id={`associatedUniversities[${index}]`}
                                                    placeholder="Associated University"
                                                    type="text"
                                                    className='col-xs-8 form-control'
                                                    />
                                                <div className="col">
                                                    <button
                                                    type="button"
                                                    className="secondary btn btn-danger btn-cross"
                                                    onClick={() => remove(index)}
                                                    >
                                                    X
                                                    </button>
                                                </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="text-center">
                                                <button
                                                type="button"
                                                className="secondary mt-3 mb-3 btn btn-outline-light btn-sm"
                                                onClick={() => push()}
                                                >
                                                Add University
                                                </button>
                                        </div>
                                        </>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-white ">
                            <div className="border-dark border-top border-bottom py-2">
                                <div className="row">
                                    <div className="col-md-4 pt-2 ">
                                        <img className="logo mb-3" src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac"
                                            width="100%" />
                                    </div>
                                    <div className="col-md-8 col-12 certification-text">
                                    <Field component="textarea" name="certificationText" className={'form-control'  + (errors.certificationText && touched.certificationText ? ' is-invalid' : '')} placeholder="Certifcation Text" />
                                    {errors.certificationText && touched.certificationText ? (
                                        <div className="text-danger ml-2">{errors.certificationText}</div>
                                    ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                            <FieldArray name="programFeatures">
                                    {({ insert, remove, push }) => (
                                    <div className="row">
                                        {values.programFeatures.length > 0 &&
                                        values.programFeatures.map((feature, index) => (
                                            <div className="form-group col-sm-6" key={index} style={{display: 'flex', alignItems: 'center'}}>
                                                <label htmlFor={`programFeatures[${index}].content`} className='col-xs-4' style={{marginRight: '10px', marginBottom: '0'}}><FontAwesomeIcon icon={feature.icon} color="grey" /></label>
                                                <Field
                                                name={`programFeatures[${index}].content`}
                                                id={`programFeatures[${index}].content`}
                                                placeholder="Feature"
                                                type="text"
                                                className='col-xs-8 form-control'
                                                />
                                                <ErrorMessages name={`programFeatures[${index}].content`} />
                                            </div>
                                        ))}
                                    </div>
                                    )}
                            </FieldArray>

                            </div>
                            <div className="row mt-3 d-md-none">
                                <div className="col-2">
                                </div>
                                <div className="col-10">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            {/* End of Hero section */}

            <div>
                <div className="container-fluid bg-light py-5 program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>About the Program</h2>
                            </div>
                                <div className="col-md-8 ">
                                    <MyTextAreaField className={'form-control' + (errors.aboutProgram && touched.aboutProgram ? ' is-invalid' : '')} placeholder="About The Program" name="aboutProgram"  />
                                    {errors.aboutProgram && touched.aboutProgram ? (
                                    <div className="text-danger ml-2">{errors.aboutProgram}</div>
                                    ) : null}
                                </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid py-5  program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>What you will learn</h2>
                            </div>
                            <div className="col-md-8 ">
                                <MyTextAreaField  className="form-control"  placeholder="Intro" name="learningObjectives.intro" />
                                <ErrorMessages name="learningObjectives.intro" />
                                <p>
                                    <ol>

                                    <FieldArray name="learningObjectives.learningPoints">
                                        {({ insert, remove, push }) => (
                                        <>
                                            {values.learningObjectives.learningPoints.length > 0 &&
                                            values.learningObjectives.learningPoints.map((learningPoint, index) => (
                                            <li>
                                            <p className="w-100 form-group row" key={index}>
                                            <label htmlFor={`learningPoints[${index}].title`}></label>
                                            <Field
                                            name={`learningObjectives.learningPoints[${index}].title`}
                                            id={`learningPoints[${index}].title`}
                                            placeholder="Title"
                                            type="text"
                                            className='col-md-8 form-control'
                                            />
                                            <ErrorMessages name={`learningObjectives.learningPoints[${index}].title`} />
                                            <label htmlFor={`learningPoints[${index}].content`}></label>
                                            <MyTextAreaField 
                                            name={`learningObjectives.learningPoints[${index}].content`}
                                            id={`learningPoints[${index}].content`}
                                            placeholder="Content"
                                            type="text"
                                            className='col-md-8 form-control'
                                            />
                                            <ErrorMessages name={`learningObjectives.learningPoints[${index}].content`} />
                                            <div className="col">
                                                <button
                                                type="button"
                                                className="secondary btn btn-danger btn-cross"
                                                onClick={() => remove(index)}
                                                >
                                                X
                                                </button>
                                            </div>
                                            </p>
                                            </li>
                                        ))}
                                        <button
                                        type="button"
                                        className="secondary mt-3 mb-3 btn btn-outline-dark btn-sm"
                                        onClick={() => push()}
                                        >
                                        Add Objective
                                        </button>
                                        </>
                                        )}
                                    </FieldArray>
                                    </ol>
                                </p>
                                <p>
                                   <MyTextAreaField  className="form-control" placeholder="Learning Outcomes" name="learningObjectives.learningOutcomes"/>
                                   <ErrorMessages name="learningObjectives.learningOutcomes" />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-light py-5 program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Record of Participation</h2>
                            </div>
                            <div className="col-md-8 ">
                            <Field component="textarea" className={"form-control" + (errors.recordOfParticipation && touched.recordOfParticipation ? ' is-invalid' : '') } placeholder="Record of Participation" name="recordOfParticipation"/>
                            {errors.recordOfParticipation && touched.recordOfParticipation ? (
                            <div className="text-danger ml-2">{errors.recordOfParticipation}</div>
                            ) : null}
                            <FileUpload url={setRecordOfParticipationImageUrl} imagePreview={true}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 program-content-web bg-white">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>World Class Faculty</h2>
                            </div>
                            <div className="col-md-8 ">
                                {
                                    !showFacultySelectionModule && selectedFaculty.map(faculty => {
                                            return(
                                                <p>
                                                    <h5>{`${faculty.firstName}  ${faculty.lastName}`}</h5>
                                                    <>
                                                        {
                                                            faculty.description.split("\\n").map(para => (<p>{para}</p>))
                                                        }
                                                    </>
                                                </p>
                                            );
                                    })
                                }
                            </div>
                            <div className="col-md-4"></div>
                            
                            <MyVerticallyCenteredModal
                                show={showFacultySelectionModule}
                                onHide={() => setShowFacultySelectionModule(false)}
                                // facultyDB={facultyDB}
                            />
                                                        <div className="secondary mt-3 mb-3 btn btn-outline-dark btn-sm"  onClick={() => setShowFacultySelectionModule(true)} >Pick Faculty</div>
                                {/* {
                                    showFacultySelectionModule ? 
                                    (
                                        <div className="col-md-8 text-center">
                                        <div className={(showFacultySelectionModule ? ' faculty-selector' : ' ')}>
                                            {
                                                facultyDB.map((faculty, index) => {
                                                    return(
                                                        <Card style={{ width: '200px' }} className={'text-center' + (faculty.selected ? ' card-selected' : ' ')} key={index}>
                                                        <Card.Img variant="top" src={faculty.data.profilePicUrl} />
                                                        <Card.Body>
                                                            <Card.Title >{`${faculty.data.fullName.firstName}  ${faculty.data.fullName.lastName}`}</Card.Title>
                                                            {
                                                                faculty.selected ? <Button variant="danger" className={"btn-circle"} onClick={() => facultyRemoveHandler(faculty.id)} ><FontAwesomeIcon icon={faTimes} color="white" /></Button>
                                                                : <Button variant="success" className={"btn-circle"} onClick={() => facultySelectHandler(index)} ><FontAwesomeIcon icon={faCheck} color="white" /></Button>
                                                            }
                                                            
                                                        </Card.Body>
                                                        </Card>
                                                    )
                                                })
                                            }
                                        </div>
                                        <Button variant="info" onClick={() => setShowFacultySelectionModule(false)} >Add Faculty</Button>
                                        </div>
                                    )
                                    :
                                    <div className="col-md-8 text-center">
                                        <Button variant="primary" onClick={() => setShowFacultySelectionModule(true)} >Pick Faculty</Button>
                                    </div>
                                } */}
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 text-white program-content-web">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Your Learning Journey</h2>
                            </div>
                            <div className="col-md-8">
                                <MyTextAreaField  className={"form-control"} placeholder="Intro" name="learningJourney.intro" />
                                <ErrorMessages name={`learningJourney.intro`} />
                                <div className="program-structure">
                                <FieldArray name="learningJourney.modules">
                                        {({ insert, remove, push }) => (
                                        <>
                                            {values.learningJourney.modules.length > 0 &&
                                            values.learningJourney.modules.map((mod, index) => {
                                                    return(
                                                        <>
                                                        <p className="form-group row" key={index}>
                                                        <label htmlFor={`learningJourney.modules[${index}].moduleType`}></label>
                                                        <Field
                                                        name={`learningJourney.modules[${index}].moduleType`}
                                                        id={`learningJourney.modules[${index}].moduleType`}
                                                        placeholder="Module Type"
                                                        type="text"
                                                        className='col-md-8 form-control'
                                                        />
                                                        <ErrorMessages name={`learningJourney.modules[${index}].moduleType`} />
                                                        <label htmlFor={`learningJourney.modules[${index}].moduleName`}></label>
                                                        <Field
                                                        name={`learningJourney.modules[${index}].moduleName`}
                                                        id={`learningJourney.modules[${index}].moduleName`}
                                                        placeholder="Module Name"
                                                        type="text"
                                                        className='col-md-8 form-control'
                                                        />
                                                        <ErrorMessages name={`learningJourney.modules[${index}].moduleName`} />
                                                        <label htmlFor={`learningJourney.modules[${index}].description`}></label>
                                                        <MyTextAreaField 
                                                        name={`learningJourney.modules[${index}].description`}
                                                        id={`learningJourney.modules[${index}].description`}
                                                        placeholder="Description"
                                                        className='col-md-8 form-control'
                                                        />
                                                        <ErrorMessages name={`learningJourney.modules[${index}].description`} />
                                                        <label htmlFor={`learningJourney.modules[${index}].sessions`}></label>
                                                        <Field
                                                        name={`learningJourney.modules[${index}].sessions`}
                                                        id={`learningJourney.modules[${index}].sessions`}
                                                        placeholder="Sessions"
                                                        type="number"
                                                        className='col-md-8 form-control'
                                                        />
                                                        <div className="col">
                                                        <button
                                                        type="button"
                                                        className="secondary btn btn-danger btn-cross"
                                                        onClick={() => remove(index)}
                                                        >
                                                        X
                                                        </button>
                                                        </div>
                                                        </p>
                                                        </>
                                                    );
                                            })}
                                            <button
                                            type="button"
                                            className="secondary mt-3 mb-3 btn btn-outline-light btn-sm"
                                            onClick={() => push()}
                                            >
                                            Add Module
                                            </button>
                                        </>
                                        )}
                                </FieldArray>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Who should take this course?</h2>
                            </div>
                            <div className="col-md-8 ">
                                <Field component="textarea" className="form-control" placeholder="Intro" name="who.intro"/>
                                <ErrorMessages name="who.intro" />           
                                <h5>This program will benefit you if</h5>
                                <p>
                                    <ul>
                                    <FieldArray name="who.questions">
                                        {({ insert, remove, push }) => (
                                        <>
                                            {values.who.questions.length > 0 &&
                                            values.who.questions.map((que, index) => (
                                            <p className="form-group row" key={index}>
                                            <label htmlFor={`who.questions[${index}]`}></label>
                                            <Field
                                            name={`who.questions[${index}]`}
                                            id={`who.questions[${index}]`}
                                            placeholder="Question"
                                            type="text"
                                            className='col-md-8 form-control'
                                            />
                                            <ErrorMessages name={`who.questions[${index}]`} />  
                                            <div className="col">
                                                <button
                                                type="button"
                                                className="secondary btn btn-danger btn-cross"
                                                onClick={() => remove(index)}
                                                >
                                                X
                                                </button>
                                            </div>
                                            </p>
                                        ))}
                                        <button
                                        type="button"
                                        className="secondary mt-3 mb-3 btn btn-outline-dark btn-sm"
                                        onClick={() => push()}
                                        >
                                        Add Question
                                        </button>
                                        </>
                                        )}
                                    </FieldArray>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 text-white program-content-web">
                    <div className="container">
                        <div className="row" style={{alignItems: 'center'}}>
                            <div className="col-md-4">
                                <h2>Upload Brochure</h2>
                            </div>
                            <div className="col-md-8 ">
                                <BrochureUpload url={setBrochureUrl}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid py-5 program-content-web" style={{ background: "#EFEFEF" }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <h2>Contact</h2>
                            </div>
                            <div className="col-md-8 ">
                                <FieldArray name="contacts">
                                        {({ insert, remove, push }) => (
                                        <>
                                            {values.contacts.length > 0 &&
                                            values.contacts.map((contact, index) => (
                                            <p className="form-group row" key={index}>
                                            <Field
                                            name={`contacts[${index}].contactName`}
                                            id={`contacts[${index}].contactName`}
                                            placeholder="Name"
                                            type="text"
                                            className='col-md-8 form-control'
                                            />
                                            <ErrorMessages name={`contacts[${index}].contactName`} />  
                                            <Field
                                            name={`contacts[${index}].contactEmail`}
                                            id={`contacts[${index}].contactEmail`}
                                            placeholder="Email"
                                            type="email"
                                            className='col-md-8 form-control'
                                            />
                                            <ErrorMessages name={`contacts[${index}].contactEmail`} />  
                                            <Field
                                            name={`contacts[${index}].contactMobile`}
                                            id={`contacts[${index}].contactMobile`}
                                            placeholder="Phone number"
                                            type="tel"
                                            className='col-md-8 form-control'
                                            />
                                            <ErrorMessages name={`contacts[${index}].contactMobile`} />  
                                            <div className="col">
                                                <button
                                                type="button"
                                                className="secondary btn btn-danger btn-cross"
                                                onClick={() => remove(index)}
                                                >
                                                X
                                                </button>
                                            </div>
                                            </p>
                                        ))}
                                        <button
                                        type="button"
                                        className="secondary mt-3 mb-3 btn btn-outline-dark btn-sm"
                                        onClick={() => push()}
                                        >
                                        Add Contact
                                        </button>
                                        </>
                                        )}
                                </FieldArray>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End of form-inputs */}
                <div style={{textAlign: 'center'}}> 
                <button type="submit" className="btn btn-primary mt-3">Submit</button>
                </div>
                </Form>
                )}
            </Formik>
            <AcademyFooter />
        </div>
    )
 
}

export default ProgramBuilder;