import React, {useContext, useState, useEffect} from 'react';
import { Navbar, Nav, NavDropdown, Modal, Button, Card } from 'react-bootstrap'
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faUserGraduate, faFileMedical, faFileSignature, faInfoCircle, faAddressCard, faMoneyCheckAlt, faFileImport, faFilePdf, faThumbsUp, faShoppingCart, faEnvelope, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import firebase from "../../firebase";
import { CanSeeOtherUsersContext, LeadsAccessContext, AuthContext} from '../Academy/Auth';



const DashboardActions = (props) => {
    const [userData, setUserData] = useState({});
    const canSeeOtherUsers = useContext(CanSeeOtherUsersContext);
    const onlyLeadsAccess = useContext(LeadsAccessContext);
    const {currentUser} = useContext(AuthContext);
    const verifyEmailHandler = () => {
        currentUser.sendEmailVerification().then(() => {
            alert('Verification email has been sent to your registered email id.')
        }).catch((error) =>{
            alert(error);
        })
    }

    useEffect(() => {
        firebase.firestore().collection('academyUsers').doc(currentUser.uid).get().then((doc) => {
            if(doc.exists) {
                setUserData(doc.data());
            }
        })
    },[currentUser])


  return (
    <>
    {
        props.hasAccess ? (
            <div className="container mt-3">
                <div className="row">
                    <div className="row w-100">
                            <div className="col-sm-12 text-theme"> 
                                <h3 className="text-white my-2">Sales</h3>
                            </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/leads" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Leads</Card.Title>
                                    <Card.Text className="w-75">
                                        View the leads
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faUsers} />
                                </Card>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/enrolledusers" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Enrolled Users</Card.Title>
                                    <Card.Text className="w-75">
                                        View the enrolled users
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faUserGraduate} />
                                </Card>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/referrals" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Referrals</Card.Title>
                                    <Card.Text className="w-75">
                                        View the referrals list and sales made
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faMoneyCheckAlt} />
                                </Card>
                            </Link>
                        </div>
                        {/* {
                        canSeeOtherUsers ? (
                            <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                                <Link to="/admin/approveleads" style={{ textDecoration: 'none' }}>
                                    <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                        <Card.Title>Approve Leads</Card.Title>
                                        <Card.Text className="w-75">
                                            Approve or discard the leads to be added
                                        </Card.Text>
                                        <FontAwesomeIcon className="dashBoard--card-icon" icon={faUserPlus} />
                                    </Card>
                                </Link>
                            </div>
                        )
                        : null
                        } */}
                    </div>
                    <div className="row w-100">
                            <div className="col-sm-12 text-theme"> 
                                <h3 className="text-white my-2">Programs</h3>
                            </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/programcardbuilder" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Add Program</Card.Title>
                                    <Card.Text className="w-75">
                                        Add a program
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faFileMedical} />
                                </Card>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/selectprogram/" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Edit/Delete Program</Card.Title>
                                    <Card.Text className="w-75">
                                        Edit or delete an existing program
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faFileSignature} />
                                </Card>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/socialmedia/" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Social Media</Card.Title>
                                    <Card.Text className="w-75">
                                        View social media posts
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faThumbsUp} />
                                </Card>
                            </Link>
                        </div>
                        {
                        canSeeOtherUsers ? (
                            <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                                <Link to="/approveprograms" style={{ textDecoration: 'none' }}>
                                    <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                        <Card.Title>Approve Programs</Card.Title>
                                        <Card.Text className="w-75">
                                            Approve or discard the programs to be added
                                        </Card.Text>
                                        <FontAwesomeIcon className="dashBoard--card-icon" icon={faFileImport} />
                                    </Card>
                                </Link>
                            </div>
                        )
                        : null
                        }
                    </div>
                    <div className="row w-100">
                            <div className="col-sm-12 text-theme"> 
                                <h3 className="text-white my-2">Faculty and experts</h3>
                            </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/addfaculty" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Add Faculty</Card.Title>
                                    <Card.Text className="w-75">
                                        Add faculty in database
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faAddressCard} />
                                </Card>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/allfaculties" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>View Faculty</Card.Title>
                                    <Card.Text className="w-75">
                                        View/Edit faculty in database
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faAddressCard} />
                                </Card>
                            </Link>
                        </div>
                    </div>
                    <div className="row w-100">
                        <div className="col-sm-12 text-theme"> 
                            <h3 className="text-white my-2">Your Profile</h3>
                        </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/userinfo" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>User Info</Card.Title>
                                    <Card.Text className="w-75">
                                        View the programs you've show interest in
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faInfoCircle} />
                                </Card>
                            </Link>
                        </div>
                        {/* <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/orderinfo" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Order Info</Card.Title>
                                    <Card.Text className="w-75">
                                        View your past purchases
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faShoppingCart} />
                                </Card>
                            </Link>
                        </div> */}
                    </div>
                    <div className="row w-100">
                    <div className="col-sm-12 text-theme"> 
                            <h3 className="text-white my-2">Admin Controls</h3>
                        </div>
                        {
                            canSeeOtherUsers ? (
                                <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                                    <Link to="/sendemails" style={{ textDecoration: 'none' }}>
                                        <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                            <Card.Title>Email enrolled users</Card.Title>
                                            <Card.Text className="w-75">
                                                Send email to enrolled users
                                            </Card.Text>
                                            <FontAwesomeIcon className="dashBoard--card-icon" icon={faEnvelope} />
                                        </Card>
                                    </Link>
                                </div>
                            )
                            : null
                        }
                        {
                            canSeeOtherUsers ? (
                                <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                                    <Link to="/currentusers" style={{ textDecoration: 'none' }}>
                                        <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                            <Card.Title>View other users</Card.Title>
                                            <Card.Text className="w-75">
                                                View the other users who have admin rights
                                            </Card.Text>
                                            <FontAwesomeIcon className="dashBoard--card-icon" icon={faUsers} />
                                        </Card>
                                    </Link>
                                </div>
                            )
                            : null
                        }
                    </div>
                </div>
            </div>

        )
        :
        (
        <div className="container mt-3">
            <div className="row">
                {
                    currentUser.emailVerified ? 
                    <>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/orderinfo" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Order Info</Card.Title>
                                    <Card.Text className="w-75">
                                        View your past purchases
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faShoppingCart} />
                                </Card>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/userinfo" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>User Info</Card.Title>
                                    <Card.Text className="w-75">
                                        View the programs you've show interest in
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faInfoCircle} />
                                </Card>
                            </Link>
                        </div>
                    </>
                    : 
                    <div className="d-flex bg-warning p-3 m-3 flex-column align-items-center mx-auto">
                        <h6>Your email isn't verified. Please verify your email to see your orders. Click on the button to send a verification email.</h6>
                        <Button variant="primary" className="btn-sm mt-3" onClick={verifyEmailHandler}>Verify Email</Button>
                    </div>
                }
                {
                    onlyLeadsAccess ? 
                    <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                        <Link to="/leads" style={{ textDecoration: 'none' }}>
                        <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                            <Card.Title>Leads</Card.Title>
                            <Card.Text className="w-75">
                                View the leads
                            </Card.Text>
                            <FontAwesomeIcon className="dashBoard--card-icon" icon={faUsers} />
                        </Card>
                        </Link>
                    </div>
                    :
                    null
                }
                {
                    userData.addProgramAccess ? 
                    <>
                            <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/programcardbuilder" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Add Program</Card.Title>
                                    <Card.Text className="w-75">
                                                            Add a program
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faFileMedical} />
                                </Card>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-md-8 mb-5 mb-lg-3">
                            <Link to="/admin/selectprogram/" style={{ textDecoration: 'none' }}>
                                <Card className="bg-primary text-white card-body d-flex align-items-end flex-column text-right class h-100" style={{ padding: '1.25rem'}}>
                                    <Card.Title>Edit/Delete Program</Card.Title>
                                    <Card.Text className="w-75">
                                                            Edit or delete an existing program
                                    </Card.Text>
                                    <FontAwesomeIcon className="dashBoard--card-icon" icon={faFileSignature} />
                                </Card>
                            </Link>
                        </div>
                        </>
                                      : null
                }
            </div>
        </div>

        )
    }

      
    </>
  );
}

export default DashboardActions;