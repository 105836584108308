import React, { useEffect, useState, useRef } from 'react'
import {
    Accordion,
    Card,
    Button,
    Col,
    Badge
} from 'react-bootstrap'
import {
    Link
} from "react-router-dom";
import './enroll.css';
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCheckCircle, faCheck } from '@fortawesome/free-solid-svg-icons';
import firebase from 'firebase';
import _, { min } from 'lodash';
import { Formik, Field, Form, ErrorMessage, FieldArray, yupToFormErrors, getIn} from 'formik';
import * as Yup from 'yup';

// TEST CREDENTIALS
// const baseURL = 'https://dev.igesia.com';
// const keyID = 'rzp_test_MUBBgyLeFbvEU8';

// LIVE CREDENTIALS
const baseURL = 'https://api.igesia.com';
const keyID = 'rzp_live_6Eg9DpjaMPO1a6';

const countryData = {
    "BD": "880",
    "BE": "32",
    "BF": "226",
    "BG": "359",
    "BA": "387",
    "BB": "+1-246",
    "WF": "681",
    "BL": "590",
    "BM": "+1-441",
    "BN": "673",
    "BO": "591",
    "BH": "973",
    "BI": "257",
    "BJ": "229",
    "BT": "975",
    "JM": "+1-876",
    "BV": "",
    "BW": "267",
    "WS": "685",
    "BQ": "599",
    "BR": "55",
    "BS": "+1-242",
    "JE": "+44-1534",
    "BY": "375",
    "BZ": "501",
    "RU": "7",
    "RW": "250",
    "RS": "381",
    "TL": "670",
    "RE": "262",
    "TM": "993",
    "TJ": "992",
    "RO": "40",
    "TK": "690",
    "GW": "245",
    "GU": "+1-671",
    "GT": "502",
    "GS": "",
    "GR": "30",
    "GQ": "240",
    "GP": "590",
    "JP": "81",
    "GY": "592",
    "GG": "+44-1481",
    "GF": "594",
    "GE": "995",
    "GD": "+1-473",
    "GB": "44",
    "GA": "241",
    "SV": "503",
    "GN": "224",
    "GM": "220",
    "GL": "299",
    "GI": "350",
    "GH": "233",
    "OM": "968",
    "TN": "216",
    "JO": "962",
    "HR": "385",
    "HT": "509",
    "HU": "36",
    "HK": "852",
    "HN": "504",
    "HM": " ",
    "VE": "58",
    "PR": "+1-787",//"PR": "+1-787 and 1-939",
    "PS": "970",
    "PW": "680",
    "PT": "351",
    "SJ": "47",
    "PY": "595",
    "IQ": "964",
    "PA": "507",
    "PF": "689",
    "PG": "675",
    "PE": "51",
    "PK": "92",
    "PH": "63",
    "PN": "870",
    "PL": "48",
    "PM": "508",
    "ZM": "260",
    "EH": "212",
    "EE": "372",
    "EG": "20",
    "ZA": "27",
    "EC": "593",
    "IT": "39",
    "VN": "84",
    "SB": "677",
    "ET": "251",
    "SO": "252",
    "ZW": "263",
    "SA": "966",
    "ES": "34",
    "ER": "291",
    "ME": "382",
    "MD": "373",
    "MG": "261",
    "MF": "590",
    "MA": "212",
    "MC": "377",
    "UZ": "998",
    "MM": "95",
    "ML": "223",
    "MO": "853",
    "MN": "976",
    "MH": "692",
    "MK": "389",
    "MU": "230",
    "MT": "356",
    "MW": "265",
    "MV": "960",
    "MQ": "596",
    "MP": "+1-670",
    "MS": "+1-664",
    "MR": "222",
    "IM": "+44-1624",
    "UG": "256",
    "TZ": "255",
    "MY": "60",
    "MX": "52",
    "IL": "972",
    "FR": "33",
    "IO": "246",
    "SH": "290",
    "FI": "358",
    "FJ": "679",
    "FK": "500",
    "FM": "691",
    "FO": "298",
    "NI": "505",
    "NL": "31",
    "NO": "47",
    "NA": "264",
    "VU": "678",
    "NC": "687",
    "NE": "227",
    "NF": "672",
    "NG": "234",
    "NZ": "64",
    "NP": "977",
    "NR": "674",
    "NU": "683",
    "CK": "682",
    "XK": "",
    "CI": "225",
    "CH": "41",
    "CO": "57",
    "CN": "86",
    "CM": "237",
    "CL": "56",
    "CC": "61",
    "CA": "1",
    "CG": "242",
    "CF": "236",
    "CD": "243",
    "CZ": "420",
    "CY": "357",
    "CX": "61",
    "CR": "506",
    "CW": "599",
    "CV": "238",
    "CU": "53",
    "SZ": "268",
    "SY": "963",
    "SX": "599",
    "KG": "996",
    "KE": "254",
    "SS": "211",
    "SR": "597",
    "KI": "686",
    "KH": "855",
    "KN": "+1-869",
    "KM": "269",
    "ST": "239",
    "SK": "421",
    "KR": "82",
    "SI": "386",
    "KP": "850",
    "KW": "965",
    "SN": "221",
    "SM": "378",
    "SL": "232",
    "SC": "248",
    "KZ": "7",
    "KY": "+1-345",
    "SG": "65",
    "SE": "46",
    "SD": "249",
    "DO": "+1-809",//"DO": "+1-809 and 1-829",
    "DM": "+1-767",
    "DJ": "253",
    "DK": "45",
    "VG": "+1-284",
    "DE": "49",
    "YE": "967",
    "DZ": "213",
    "US": "1",
    "UY": "598",
    "YT": "262",
    "UM": "1",
    "LB": "961",
    "LC": "+1-758",
    "LA": "856",
    "TV": "688",
    "TW": "886",
    "TT": "+1-868",
    "TR": "90",
    "LK": "94",
    "LI": "423",
    "LV": "371",
    "TO": "676",
    "LT": "370",
    "LU": "352",
    "LR": "231",
    "LS": "266",
    "TH": "66",
    "TF": "",
    "TG": "228",
    "TD": "235",
    "TC": "+1-649",
    "LY": "218",
    "VA": "379",
    "VC": "+1-784",
    "AE": "971",
    "AD": "376",
    "AG": "+1-268",
    "AF": "93",
    "AI": "+1-264",
    "VI": "+1-340",
    "IS": "354",
    "IR": "98",
    "AM": "374",
    "AL": "355",
    "AO": "244",
    "AQ": "",
    "AS": "+1-684",
    "AR": "54",
    "AU": "61",
    "AT": "43",
    "AW": "297",
    "IN": "91",
    "AX": "+358-18",
    "AZ": "994",
    "IE": "353",
    "ID": "62",
    "UA": "380",
    "QA": "974",
    "MZ": "258"
};

const countryFullName = {
    "AF": "Afghanistan",
    "AX": "land Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "AndorrA",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "CV": "Cape Verde",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, The Democratic Republic of the",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Cote D'Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and Mcdonald Islands",
    "VA": "Holy See (Vatican City State)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran, Islamic Republic Of",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea, Democratic People'S Republic of",
    "KR": "Korea,Republic of",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People'S Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia, The Former Yugoslav Republic of",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States of",
    "MD": "Moldova, Republic of",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territory, Occupied",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "RWANDA",
    "SH": "Saint Helena",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "US": "United States",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Viet Nam",
    "VG": "Virgin Islands, British",
    "VI": "Virgin Islands, U.S.",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
};


function EnrollNow() {

    const [enrollProgram, setEnrollProgram] = useState([]);
    const [countOfProgramIds, setCountOfProgramIds] = useState(null);
    const [uniqueProgramIds, setUniqueProgramIds] = useState([]);
    const [uniqueEnrollPrograms, setUniqueEnrollPrograms] = useState([]);
    const [activeEventKey, setActiveEventKey] = useState("0");
    const [errorBlock, setErrorBlock] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [estimate, setEstimate] = useState({});

    const [baseAmountArray, setBaseAmountArray] = useState([]);
    const [taxArray, setTaxArray] = useState([]);
    const [totalBaseAmount, setTotalBaseAmount] = useState(0);
    const [totalTax, setTotalTax] = useState(0);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [countryCode, setCountryCode] = useState(window.localStorage.getItem("countryCode") ? window.localStorage.getItem("countryCode") : "");
    const [phoneNumber, setPhoneNumber] = useState(window.localStorage.getItem("phoneNumber") ? window.localStorage.getItem("phoneNumber") : "+1");

    const [companyName, setCompanyName] = useState("");
    const [panNo, setPanNo] = useState("");
    const [gstNo, setGstNo] = useState("");
    const [designation, setDesignation] = useState("");
    const [corporateEmailAddress, setCorporateEmailAddress] = useState("");
    const [businessUnit, setBusinessUnit] = useState("");
    const [experience, setExperience] = useState("");
    const [reason, setReason] = useState("");
    const [referredBy, setReferredBy] = useState("");
    
    const [address, setAddress] = useState("");
    const [locality, setLocality] = useState("");

    const [countryName, setCountryName] = useState("");
    const [stateName, setStateName] = useState("");

    // const [dummyCountryName, setDummyCountryName] = useState("");
    // const [dummyStateName, setDummyStateName] = useState("");

    const [pincode, settPincode] = useState("");
    const [cityName, setCityName] = useState("");
    // const [consent, setConsent] = useState(true);
    // const [consentError, setConsentError] = useState(false);
    const [accessCountryCode, setAccessCountryCode] = useState("");
    const [paymentCompleted, setPaymentCompleted] = useState(false);
    const [orderId, setOrderId] = useState("");

    const [referrals, setReferrals] = useState([]);


    const ref = useRef(null);
    const ref2 = useRef(null);


    useEffect(() => {
        setEnrollProgram(window.localStorage.getItem("enrollProgram") ? JSON.parse(window.localStorage.getItem("enrollProgram")) : [])
        // console.log(JSON.parse(window.localStorage.getItem("enrollProgram")))

        setActiveEventKey(window.localStorage.getItem("activeEventKey") ? window.localStorage.getItem("activeEventKey") : "0")

        const userDetails = JSON.parse(window.localStorage.getItem("userDetails"));
        if (userDetails) {
            setFirstName(userDetails.first_name)
            setLastName(userDetails.last_name)
            setEmailAddress(userDetails.email)
            setCountryCode(userDetails.country_code)
            setPhoneNumber(userDetails.mobile)
            setAccessCountryCode(userDetails.access_country_code)

            setCompanyName(userDetails.company_name)
            setDesignation(userDetails.designation)
            setBusinessUnit(userDetails.business_unit)
            setCorporateEmailAddress(userDetails.corporate_email_id)
            setExperience(userDetails.experience)
            setReason(userDetails.reason)
            // setReferredBy(userDetails.referred_by)

            setAddress(userDetails.address_details.address_line_1)
            setLocality(userDetails.address_details.address_line_2)
            setCityName(userDetails.address_details.city)
            setStateName(userDetails.address_details.state)

            setCountryName(userDetails.address_details.country ? userDetails.address_details.country : countryFullName[userDetails.country_code])
            
            settPincode(userDetails.address_details.pin_code)
        }

        fetch('https://05e4wl8tgc.execute-api.us-east-1.amazonaws.com/production/hello', {
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': 'http://localhost:3000'
            }
        })
            .then((response) => response.json())
            .then((json) => {
                let country = json.input["CloudFront-Viewer-Country"]
                setAccessCountryCode(`${country}`);
                setCountryCode(`${country}`);
                
                window.localStorage.setItem("accessCountryCode", `${country}`);
                window.localStorage.setItem("countryCode", `${country}`);

                if (!window.localStorage.getItem("userDetails")) {
                    setPhoneNumber(`+${countryData[country]}`);
                    setCountryName(`${countryFullName[country]}`)

                    window.localStorage.setItem("phoneNumber", `+${countryData[country]}`);
                }

            })
            .catch((error) => {
                console.log(error);
            });

    }, []);

    useEffect(() => {
        // useeffect for changing no of orders and counting unique programdetails and ids
        const programIds = JSON.parse(window.localStorage.getItem("enrollProgramIds"));
        setCountOfProgramIds(_.countBy(programIds));
        setUniqueProgramIds([...new Set(programIds)]);

        const programsDetails = window.localStorage.getItem("enrollProgram") ? JSON.parse(window.localStorage.getItem("enrollProgram")) : [];
        const tempProgramDetailsPerProgramId = _.groupBy(programsDetails, "programId")
        setUniqueEnrollPrograms(tempProgramDetailsPerProgramId);
    },[enrollProgram])

    useEffect(() => {

        const suborders = uniqueProgramIds.map((program) => {
            return(
                {
                    id: program,
                    quantity: countOfProgramIds[program],
                    category: 0
                }
            )
        })
        console.log(suborders);
        postData(`${baseURL}/orders/estimate`, {suborders: suborders})
        .then(response => {
            // console.log("Order API:", response);
            setEstimate(response.data);
        })
        .catch(error => {
            console.error(error)
        })
    },[uniqueProgramIds])


    useEffect(() => {
        setBaseAmountArray([])
        setTaxArray([])
        enrollProgram.map(item => {
            setBaseAmountArray(prev => [...prev, item.feeBreakout.baseAmount])
            setTaxArray(prev => [...prev, item.feeBreakout.tax])
        })
        // console.log("Enroll program updated")
    }, [enrollProgram])

    useEffect(() => {
        // console.log("baseAmountArray updated", baseAmountArray)
        baseAmountArray.length > 0 && setTotalBaseAmount(baseAmountArray.reduce((a, c) => a + c))
    }, [baseAmountArray]);

    useEffect(() => {
        // console.log("taxArray updated", taxArray)
        taxArray.length > 0 && setTotalTax(taxArray.reduce((a, c) => a + c) / taxArray.length)
    }, [taxArray]);

    useEffect(() => {
        window.localStorage.setItem("activeEventKey", activeEventKey)
    }, [activeEventKey])

    // loading all the referrals from firebase
    useEffect(() => {
        firebase.firestore().collection('referrals').get()
        .then(snapshot => {
            let tempReferralsList = [];
            snapshot.forEach(doc => {
                tempReferralsList.push({id: doc.id, email:doc.data().email});
            });
            setReferrals(tempReferralsList);
        })
    },[])

    const addToCart = program => {
        let pastCart = JSON.parse(window.localStorage.getItem("enrollProgram"));
        let pastCartOrderIds = JSON.parse(window.localStorage.getItem("enrollProgramIds"));

        pastCart.splice(pastCartOrderIds.indexOf(program), 0, uniqueEnrollPrograms[program][0]);
        pastCartOrderIds.splice(pastCartOrderIds.indexOf(program), 0 , program)

        window.localStorage.setItem("enrollProgram", JSON.stringify(pastCart));
        window.localStorage.setItem("enrollProgramIds", JSON.stringify(pastCartOrderIds));
        setEnrollProgram(pastCart);
    }


    const removeFromCart = program => {
        let pastCart = JSON.parse(window.localStorage.getItem("enrollProgram"));
        let pastCartOrderIds = JSON.parse(window.localStorage.getItem("enrollProgramIds"));

        pastCart.splice(pastCartOrderIds.indexOf(program), 1)
        
        pastCartOrderIds.splice(pastCartOrderIds.indexOf(program), 1)

        window.localStorage.setItem("enrollProgram", JSON.stringify(pastCart));
        window.localStorage.setItem("enrollProgramIds", JSON.stringify(pastCartOrderIds));
        setEnrollProgram(pastCart);
    }

    const updateFromCart = (key, value) => {
        let pastCart = JSON.parse(window.localStorage.getItem("enrollProgram"));
        // let pastCartOrderIds = JSON.parse(window.localStorage.getItem("enrollProgramIds"));

        // pastCart.splice(key, 1)
        pastCart[key]["feeBreakout"] = value["feeBreakout"]
        // pastCartOrderIds.splice(key, 1)

        window.localStorage.setItem("enrollProgram", JSON.stringify(pastCart));
        // window.localStorage.setItem("enrollProgramIds", JSON.stringify(pastCartOrderIds));
        setEnrollProgram(pastCart);
    }

    const formatCurrent = value => {
        return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', maximumSignificantDigits: 7 }).format(value)
    }

    const handleLead = (userValues) => {
        // if (!consent) {
        //     setConsentError(true);
        //     return;
        // }

        let userDetails = {
            first_name: userValues.firstName,
            last_name: userValues.lastName,
            email: userValues.emailAddress,
            country_code: userValues.countryCode,
            mobile: userValues.phoneNumber,
            access_country_code: userValues.accessCountryCode,
            address_details: {
                address_line_1: userValues.address,
                address_line_2: userValues.locality,
                city: userValues.cityName,
                pin_code: userValues.pincode,
                state: userValues.stateName,
                country: userValues.countryName
            },
            company_name: userValues.companyName,
            designation: userValues.designation,
            corporate_email_id: userValues.corporateEmailAddress,
            business_unit: userValues.businessUnit,
            experience: userValues.experience,
            reason: userValues.reason,
            referred_by: userValues.referredBy,
            gst_number: userValues.gstNo,
            pan_number: userValues.panNo
        };


        setFirstName(userDetails.first_name)
        setLastName(userDetails.last_name)
        setEmailAddress(userDetails.email)
        setCountryCode(userDetails.country_code)
        setPhoneNumber(userDetails.mobile)
        setAccessCountryCode(userDetails.access_country_code)

        setCompanyName(userDetails.company_name)
        setDesignation(userDetails.designation)
        setBusinessUnit(userDetails.business_unit)
        setCorporateEmailAddress(userDetails.corporate_email_id)
        setExperience(userDetails.experience)
        setReason(userDetails.reason)
        // setReferredBy(userDetails.referred_by)

        setAddress(userDetails.address_details.address_line_1)
        setLocality(userDetails.address_details.address_line_2)
        setCityName(userDetails.address_details.city)
        setStateName(userDetails.address_details.state)

        setCountryName(userDetails.address_details.country ? userDetails.address_details.country : countryFullName[userDetails.country_code])
        
        settPincode(userDetails.address_details.pin_code)


        // console.log("userDetails: ", userDetails);

        if (window.localStorage.getItem("leadId")) {

            putData(`${baseURL}/leads/${window.localStorage.getItem("leadId")}`, userDetails)
                .then(function (response) {
                    // console.log("Lead API:", response);
                    if (response.status === 400) {
                        setErrorBlock("0");
                        setErrorMessage(response.message);
                    }
                    if (response.status === 201 || response.status === 200) {
                        setActiveEventKey("2");
                        window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        } else {

            postData(`${baseURL}/leads/create`, userDetails)
                .then(function (response) {
                    // console.log("Lead API:", response);
                    if (response.status === 400) {
                        setErrorBlock("0");
                        setErrorMessage(response.message);
                    }
                    if (response.status === 201 || response.status === 200) {
                        setActiveEventKey("2");
                        window.localStorage.setItem("userDetails", JSON.stringify(userDetails));
                        window.localStorage.setItem("leadId", response.data.lead_id);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        }
    }

    const handleOrder = () => {
        if (
            window.localStorage.getItem("leadId")
            && window.localStorage.getItem("userDetails")
            && window.localStorage.getItem("enrollProgramIds")
        ) {

            // console.log("orderDetails: ", orderDetails);
            firebase
                .firestore()
                .collection("executivePrograms")
                .doc(JSON.parse(window.localStorage.getItem("enrollProgramIds"))[0])
                .get()
                .then(response => {
                    // console.log(response.data())
                    updateFromCart(0, response.data());

                    let orderAmount = response.data().feeBreakout.baseAmount + response.data().feeBreakout.baseAmount * response.data().feeBreakout.tax / 100;
                    /*
                    first count no of programs per every programid in localstorage
                    as programids from localstorage can have duplicate values
                    second get only the unique values array from it
                    */
                    const programIds = JSON.parse(window.localStorage.getItem("enrollProgramIds"));
                    // const countOfProgramIds = _.countBy(programIds);
                    // const uniqueProgramIds = [...new Set(programIds)];

                    const suborders = uniqueProgramIds.map((program) => {
                        return(
                            {
                                id: program,
                                quantity: countOfProgramIds[program],
                                category: 0
                            }
                        )
                    })

                    console.log(suborders);
                    let orderDetails = {
                        lead_id: window.localStorage.getItem("leadId"),
                        // program_id: JSON.parse(window.localStorage.getItem("enrollProgramIds"))[0],
                        // program_slot_id: "OCT-2020",
                        // order_amount: orderAmount
                        suborders: suborders
                    }

                    // console.log("orderDetails", orderDetails)

                    postData(`${baseURL}/orders/create`, orderDetails)
                        .then(response => {
                            // console.log("Order API:", response);
                            (response.status === 201 || response.status === 200) && setActiveEventKey("3");
                            window.localStorage.setItem("orderDetails", JSON.stringify(orderDetails));
                            window.localStorage.setItem("orderGeneratedData", JSON.stringify(response.data));
                        })
                        .catch(error => {
                            console.error(error)
                        })
                })
        }
    }

    const handlePayment = async (e) => {
        e.preventDefault();

        if (
            window.localStorage.getItem("orderDetails")
            && window.localStorage.getItem("orderGeneratedData")
        ) {
            const orderGeneratedData = JSON.parse(window.localStorage.getItem("orderGeneratedData"));
            const enrollProgram = JSON.parse(window.localStorage.getItem("enrollProgram"))[0];

            let options = {
                key: keyID,
                amount: orderGeneratedData.order_amount,
                currency: orderGeneratedData.order_currency,
                name: "Igesia",
                description: enrollProgram ? enrollProgram.programName : "Igesia Executive Program",
                image: "https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Figesia-logo-positive-sm.png?alt=media&token=c7b1d5b9-c7b1-4e6d-a771-3d88321d20f6",
                order_id: orderGeneratedData.rzp_order_id,
                handler: async (response) => {
                    // console.log("Handler Response: ", response)
                    try {
                        let processDetails = {
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_signature: response.razorpay_signature
                        }

                        // console.log("processDetails: ", processDetails);

                        postData(`${baseURL}/orders/${orderGeneratedData.order_id}/process`, processDetails)
                            .then(response => {
                                // console.log("Process API:", response);

                                if (response.status === 201 || response.status === 200) {
                                    setPaymentCompleted(true);
                                    setOrderId(response.data.order_id);
                                    setActiveEventKey("0");

                                    // storing in referrals
                                    handleReferrals(response.data.order_id);
                                }

                                // window.localStorage.setItem("order_id",response.data.order_id);
                                // window.localStorage.setItem("processDetails", JSON.stringify(processDetails));
                                // window.localStorage.setItem("processGeneratedData", JSON.stringify(response.data));

                                window.localStorage.removeItem("leadId")
                                window.localStorage.removeItem("orderDetails")
                                window.localStorage.removeItem("enrollProgramIds")
                                window.localStorage.removeItem("enrollProgram")
                                window.localStorage.removeItem("orderGeneratedData")
                            })
                            .catch(error => {
                                console.error(error)
                            })

                    } catch (error) {
                        console.log(error)
                    }

                },
                prefill: {
                    name: `${firstName} ${lastName}`,
                    email: `${emailAddress}`,
                    contact: `${phoneNumber}`
                },
                notes: {
                    address: "Igesia Academy",
                    website: window.location.href
                },
                theme: {
                    color: "#191C25"
                }
            };

            var rzp1 = new window.Razorpay(options);
            rzp1.open();
        }
    }

    const handleReferrals = (orderid) => {
        if(referredBy && referredBy !== "Others"){
            let refID = '';
            referrals.forEach((referral) => {
                if(referral.email === referredBy){
                    refID = referral.id;
                }
            })
            firebase.firestore().collection('referrals').doc(refID).update({
                salesMade: firebase.firestore.FieldValue.increment(1),
                orderIdsOfSalesMade: firebase.firestore.FieldValue.arrayUnion(orderid)
            })
        }else{
            return false;
        }
    }

    async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //   credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }

    async function putData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            //   credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return response.json(); // parses JSON response into native JavaScript objects
    }

    const UserDetailsSchema = Yup.object().shape({
        firstName: Yup.string()
        .required('Required'),
        lastName: Yup.string()
        .required('Required'),
        emailAddress: Yup.string()
        .email()
        .required('Required'),
        phoneNumber: Yup.string()
        .min(5,"Required")
        .matches(/^\+[0-9\s\-\(\)]+$/,"Enter valid phone number with Dialing code")
        .required('Required'),
        companyName: Yup.string()
        .required('Required'),
        designation: Yup.string()
        .required('Required'),
        corporateEmailAddress: Yup.string()
        .email()
        .required('Required'),
        businessUnit: Yup.string()
        .required('Required'),
        experience: Yup.string()
        .required('Required'),
        reason: Yup.string()
        .required('Required'),
        referredBy: Yup.string()
        .min(3,'Required')
        .required('Required'),
        address: Yup.string()
        .required('Required'),
        locality: Yup.string()
        .required('Required'),
        // countryName: Yup.string()
        // .required('Required'),
        // stateName: Yup.string()
        // .required('Required'),
        pincode: Yup.string()
        .matches(/^[0-9\s\-\(\)]+$/)
        .required('Required'),
        cityName: Yup.string()
        .required('Required'),
    });
    const UserCountrySchema = Yup.object().shape({
        countryName: Yup.string()
        .required('Required'),
        stateName: Yup.string()
        .required('Required'),
    });

    return (
        <div className="container my-5">
            {enrollProgram.length > 0
                ? <>
                    {paymentCompleted
                        ? <div>
                            <Card className="rounded-0">
                                <Card.Header className="bg-white">
                                    <p className="text-dark mb-0 font-weight-bold">Payment completed!</p>
                                </Card.Header>
                                <Card.Body>
                                    {/* <FontAwesomeIcon icon={faCheckCircle} className="text-success w-100" size="5x" />
                                    <p className="h4 font-weight-bold text-center">Congratulations, your order is successfull!</p>
                                    <p className="small text-center">Order reference id: {orderId}</p>
                                    <p className="text-center ">Signup <Link to={'/signup/'} className="h5">here</Link> to fillout the rest of the participants details.</p>
                                    <p className="text-center">We will be in touch shortly, you can also reach out to <a href="mailto:academy@igesia.co">academy@igesia.co</a></p>
                                    <p className="mb-0 text-center"><Link to={`/`}>Back to home</Link></p> */}
                                    <FontAwesomeIcon icon={faCheckCircle} className="text-success w-100" size="5x" />
                                    <p className="h4 font-weight-bold text-center">Congratulations, your order is successfull!</p>
                                    <p className="font-weight-bold text-center">Order reference id: {orderId}</p>
                                    <p className="text-center">A confirmation email has been sent to: {emailAddress}</p>
                                    <p className="text-center h6">Signup <Link to={'/signup/'} className="font-weight-bold">here</Link> to fillout the rest of the participants details.</p>
                                    <br></br>
                                    <p className="text-center">We will be in touch shortly, you can also reach out to <a href="mailto:academy@igesia.co">academy@igesia.co</a></p>
                                    <p className="mb-0 text-center"><Link to={`/`}>Back to home</Link></p>
                                </Card.Body>
                            </Card>
                        </div>
                        : <div className="row">
                            <div className="col-md-4 order-2 mt-md-0 mt-3">
                                <Card className="rounded-0">
                                    <Card.Header className="bg-white font-weight-bold text-secondary">FEE DETAILS</Card.Header>
                                    <Card.Body>
                                        <p className="d-flex">
                                            <span className="flex-grow-1">Fee ({enrollProgram.length} {enrollProgram.length > 1 ? "programs" : "program"})</span>
                                            <span>{baseAmountArray.length > 0 && formatCurrent(estimate ? estimate.totalOriginalPrice ? estimate.totalOriginalPrice : totalBaseAmount : totalBaseAmount)}</span>
                                        </p>
                                        {
                                            enrollProgram.length > 1 ?
                                            <p className="d-flex small">
                                            <span className="flex-grow-1">Discount ({enrollProgram.length} {enrollProgram.length > 1 ? "programs" : "program"})</span>
                                            <span>- {baseAmountArray.length > 0 && formatCurrent(estimate ? estimate.discountPrice ?estimate.discountPrice : 0 : 0)}</span>
                                            </p>
                                            : 
                                            null
                                        }

                                        <p className="d-flex">
                                            <span className="flex-grow-1"><span className="small">+ GST ({taxArray.length > 0 && taxArray.reduce((a, c) => a + c) / taxArray.length}%)</span></span>
                                            <span>+ {(baseAmountArray.length > 0 && taxArray.length > 0) && formatCurrent(estimate ? estimate.gstAmount ? estimate.gstAmount : totalBaseAmount * totalTax / 100 : totalBaseAmount * totalTax / 100)}</span>
                                        </p>

                                        <hr />

                                        <p className="d-flex mb-0">
                                            <span className="flex-grow-1 text-dark font-weight-bold">Total Payable</span>
                                            <span className="text-dark font-weight-bold">{(baseAmountArray.length > 0 && taxArray.length > 0) && formatCurrent(estimate ? estimate.amountPayable ? estimate.amountPayable : totalBaseAmount + totalBaseAmount * totalTax / 100 : totalBaseAmount + totalBaseAmount * totalTax / 100)}</span>
                                        </p>
                                    </Card.Body>
                                </Card>
                            </div>


                            <Accordion activeKey={activeEventKey} className="col-md-8 order-1">
                            <div className="bg-warning p-2">
                                <span className="font-weight-bold">Please select number of participants. You can only add 5 programs/participants at max per transaction.</span>
                                <br></br>
                                <span className="font-weight-bold">One participant can enroll for only one program at a time.</span>
                            </div>

                            <Card className="rounded-0 border-0">
                                    <Card.Header
                                        eventKey="0"
                                        className={activeEventKey === "0" ? "bg-primary" : "bg-light"}>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 d-flex align-items-center">
                                                <Badge
                                                    variant="light"
                                                    className={activeEventKey === "0"
                                                        ? "text-primary px-2 py-1 rounded-sm"
                                                        : "text-secondary border border-secondary px-2 py-1 rounded-sm"}
                                                >1</Badge>
                                                <p
                                                    className={activeEventKey === "0"
                                                        ? "text-white ml-3 my-0 font-weight-bold"
                                                        : "text-secondary ml-3 my-0 font-weight-bold"}
                                                >YOUR CART {(activeEventKey !== "0" && activeEventKey > 0) && <FontAwesomeIcon icon={faCheck} className="text-success" />}</p>
                                            </div>
                                            {(activeEventKey !== "0" && activeEventKey > 0) && <div>
                                                <Button
                                                    variant="outline-primary"
                                                    className="rounded-0"
                                                    size="sm"
                                                    onClick={() => setActiveEventKey("0")}
                                                >CHANGE</Button>
                                            </div>}
                                        </div>
                                    </Card.Header>
                                    {(activeEventKey !== "0" && activeEventKey > 0)
                                        && <Card.Footer className="bg-white">
                                            <div>{uniqueProgramIds.map((program, key) => (
                                                <div className="d-flex">
                                                    <p className="flex-grow-1 small mb-0">{key + 1}. {uniqueEnrollPrograms[program][0].programName}<span className="font-italic ml-2">x{countOfProgramIds[program]}</span></p>
                                                    <p className="small mb-0"><span className="font-weight-bold">{formatCurrent(uniqueEnrollPrograms[program][0].feeBreakout.baseAmount * countOfProgramIds[program])}</span> + <span className="small">GST ({uniqueEnrollPrograms[program][0].feeBreakout.tax}%)</span></p>
                                                    
                                                </div>
                                            ))}</div>
                                        </Card.Footer>}
                                    <Accordion.Collapse eventKey="0">
                                        <div>
                                            <Card.Body className="row p-0">

                                                {

                                                    uniqueProgramIds.map((program, key) => (
                                                        <div className="col-12 border-bottom d-flex">
                                                            <div className="mt-4 ml-4 pr-0 d-md-block d-none" style={{ height: 60, backgroundColor: "#EFEFEF", width: 90 }}>
                                                                <img src={uniqueEnrollPrograms[program][0].programThumbnail} style={{ height: 60, width: 90 }} className="thumbnail rounded-sm" />
                                                            </div>
                                                            <div className="p-4 w-100">
                                                                <h6>{uniqueEnrollPrograms[program][0].programName}</h6>
                                                                <p className="text-muted small">{uniqueEnrollPrograms[program][0].programShortDescription}</p>

                                                                <div className="row align-items-center">
                                                                    <p className="col col-md-8 text-dark mb-0">
                                                                        <span className="font-weight-bold">{formatCurrent(uniqueEnrollPrograms[program][0].feeBreakout.baseAmount * countOfProgramIds[program])}</span> + <span className="small">GST ({uniqueEnrollPrograms[program][0].feeBreakout.tax}%)</span>
                                                                        <p className="small mb-0"><span className="small">Participants {countOfProgramIds[program]}</span></p>
                                                                        </p>
                                                                    <div className="col col-md-4 text-right ">
                                                                        <Button variant="none"  className="mr-2 btn btn-outline-primary btn-sm" onClick={() => removeFromCart(program)}>
                                                                            <small className="font-weight-bold">-</small>
                                                                        </Button>
                                                                        <span>{countOfProgramIds[program]}</span>
                                                                        <Button variant="none" disabled={enrollProgram.length === 5} className="ml-2 btn btn-outline-primary btn-sm" onClick={() => addToCart(program)}>
                                                                        <small className="font-weight-bold">+</small>
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                
                                                }

                                            </Card.Body>
                                            <Card.Footer className="bg-white border-0">
                                                <Accordion.Toggle
                                                    className="btn btn-primary rounded-0"
                                                    onClick={() => setActiveEventKey("1")}
                                                    >
                                                    CONTINUE
                                    </Accordion.Toggle>
                                            </Card.Footer>
                                        </div>
                                    </Accordion.Collapse>
                                </Card>


                                {/* User Details Start */}
                                <Card className="rounded-0 border-0 mt-3">
                                    <Card.Header
                                        eventKey="1"
                                        className={activeEventKey === "1" ? "bg-primary" : "bg-light"}>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 d-flex align-items-center">
                                                <Badge
                                                    variant="light"
                                                    className={activeEventKey === "1"
                                                        ? "text-primary px-2 py-1 rounded-sm"
                                                        : "text-secondary border border-secondary px-2 py-1 rounded-sm"}
                                                >2</Badge>
                                                <p
                                                    className={activeEventKey === "1"
                                                        ? "text-white ml-3 my-0 font-weight-bold"
                                                        : "text-secondary ml-3 my-0 font-weight-bold"}
                                                >DETAILS {(activeEventKey !== "1" && activeEventKey > 1) && <FontAwesomeIcon icon={faCheck} className="text-success" />}</p>
                                            </div>
                                            {(activeEventKey !== "1" && activeEventKey > 1) && <div>
                                                <Button
                                                    variant="outline-primary"
                                                    className="rounded-0"
                                                    size="sm"
                                                    onClick={() => setActiveEventKey("1")}
                                                >CHANGE</Button>
                                            </div>}
                                        </div>
                                    </Card.Header>
                                    {(activeEventKey !== "1" && activeEventKey > 1)
                                        && <Card.Footer className="bg-white">
                                            <div className="d-flex">
                                                <p className="small mb-0"><span className="font-weight-bold">{firstName} {lastName}</span>, {phoneNumber}, {address}, {locality} - {pincode}, {cityName}, {stateName}, {countryName}</p>
                                            </div>
                                            <div className="d-flex">
                                                <p className="small mb-0">{corporateEmailAddress}, {designation}, {businessUnit}, {companyName} ({experience} years)</p>
                                            </div>
                                        </Card.Footer>}
                                    <Accordion.Collapse eventKey="1">
                                        <div>

                                            <Card.Body>

                                                {/* <Form className="col-md-12"> */}
                                                    <Formik
                                                    innerRef={ref}
                                                    enableReinitialize
                                                    validationSchema={UserDetailsSchema}
                                                    validateOnBlur={true}
                                                    validateOnChange={true}
                                                    initialValues={{
                                                            firstName: firstName,
                                                            lastName: lastName,
                                                            emailAddress: emailAddress,
                                                            countryCode: countryCode,
                                                            phoneNumber: phoneNumber,

                                                            companyName: companyName,
                                                            panNo: panNo,
                                                            gstNo: gstNo,
                                                            designation: designation,
                                                            corporateEmailAddress: corporateEmailAddress,
                                                            businessUnit: businessUnit,
                                                            experience: experience,
                                                            reason: reason,
                                                            referredBy: referredBy,

                                                            address: address,
                                                            locality: locality,
                                                            pincode: pincode,
                                                            cityName: cityName,
                                                            accessCountryCode: accessCountryCode
                                                    }}
                                                    onSubmit={ (values) => {
                                                        // todo add if statements to check if empty
                                                        values.countryName = ref2.current.values.countryName;
                                                        values.stateName = ref2.current.values.stateName;
                                                        console.log(values)
                                                        handleLead(values); 
                                                    }}
                                                  >
                                                      {({ errors, touched, isValidating, values }) => (

                                                           <Form className="col-md-12">
                                                                <div>
                                                                        <>
                                                                        <h6 className="font-weight-bolder">Registered by</h6>
                                                                        <div className="form-row mt-3">
                                                                        <div className="form-label-group col-6">
                                                                            <Field
                                                                            name="firstName"
                                                                            placeholder="First Name"
                                                                            type="text"
                                                                            className='form-control'
                                                                            />
                                                                            <label for={`firstName`} className="mb-1">First name</label>
                                                                            {errors.firstName && touched.firstName ? (
                                                                                <div className="text-danger ml-2">{errors.firstName}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        <div className="form-label-group col-6">
                                                                            <Field
                                                                            name={`lastName`}
                                                                            placeholder="Last Name"
                                                                            type="text"
                                                                            className='form-control'
                                                                            />
                                                                            <label for={`lastName`} className="mb-1">Last name</label>
                                                                            {errors.lastName && touched.lastName ? (
                                                                                <div className="text-danger ml-2">{errors.lastName}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        </div>
                                                                        <div className="form-row">
                                                                        <div className="form-label-group col-12">
                                                                            <Field
                                                                            name={`emailAddress`}
                                                                            placeholder="Personal Email"
                                                                            type="email"
                                                                            className='form-control'
                                                                            />
                                                                            <label for={`emailAddress`} className="mb-1">Personal Email</label>
                                                                            {errors.emailAddress && touched.emailAddress ? (
                                                                                <div className="text-danger ml-2">{errors.emailAddress}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        </div>
                                                                        <div className="form-row">
                                                                        <div className="form-label-group col-12">
                                                                            <Field
                                                                            name="phoneNumber"
                                                                            placeholder="Phone Number"
                                                                            type="tel"
                                                                            className='form-control'
                                                                            />
                                                                            <label for={`phoneNumber`} className="mb-1">Phone Number</label>
                                                                            {errors.phoneNumber && touched.phoneNumber ? (
                                                                                <div className="text-danger ml-2">{errors.phoneNumber}</div>
                                                                            ) : null}
                                                                        </div>
                                                                        </div>

                                                                        <h6 className="mt-4 font-weight-bolder">Company details</h6>
                                                                        <div className="form-row row">
                                                                            <div className="form-label-group col-12">
                                                                                <Field
                                                                                    name="companyName"
                                                                                    placeholder="Company name"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                <label for="companyName" className="mb-1">Company name</label>
                                                                                {errors.companyName && touched.companyName ? (
                                                                                <div className="text-danger ml-2">{errors.companyName}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-row row">
                                                                            <div className="form-label-group col-6">
                                                                                <Field
                                                                                    name="panNo"
                                                                                    placeholder="Designation"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                <label for="panNo" className="mb-1">PAN No.</label>
                                                                            </div>
                                                                            <div className="form-label-group col-6">
                                                                                <Field
                                                                                    name="gstNo"
                                                                                    placeholder="Function"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                <label for="gstNo" className="mb-1">GST No.</label>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-row row">
                                                                            <div className="form-label-group col-6">
                                                                                <Field
                                                                                    name="designation"
                                                                                    placeholder="Designation"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                <label for="designation" className="mb-1">Designation</label>
                                                                                {errors.designation && touched.designation ? (
                                                                                <div className="text-danger ml-2">{errors.designation}</div>
                                                                                ) : null}
                                                                            </div>
                                                                            <div className="form-label-group col-6">
                                                                                <Field
                                                                                    name="businessUnit"
                                                                                    placeholder="Function"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                <label for="businessUnit" className="mb-1">Function</label>
                                                                                {errors.businessUnit && touched.businessUnit ? (
                                                                                <div className="text-danger ml-2">{errors.businessUnit}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-row">
                                                                            <div className="form-label-group col-12">
                                                                                <Field
                                                                                    name="corporateEmailAddress"
                                                                                    placeholder="yourname@company.com"
                                                                                    className="form-control"
                                                                                    type="email"
                                                                                />
                                                                                <label for="corporateEmailAddress" className="mb-1">Corporate Email</label>
                                                                                {errors.corporateEmailAddress && touched.corporateEmailAddress ? (
                                                                                <div className="text-danger ml-2">{errors.corporateEmailAddress}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-row row mb-2">
                                                                            <div className="col-6">
                                                                                <label for="experience" className="mb-1">Experience</label>
                                                                                <Field component="select" name="experience"  class={"custom-select"  + (errors.experience && touched.experience ? ' is-invalid' : '')}>
                                                                                    <option disabled selected={experience === "" ? true : false} value="">Please select</option>
                                                                                    <option selected={experience === "0-5" ? true : false} value="0-5">0 - 5 years</option>
                                                                                    <option selected={experience === "5-10" ? true : false} value="5-10">5 - 10 years</option>
                                                                                    <option selected={experience === "10-15" ? true : false} value="10-15">10 - 15 years</option>
                                                                                    <option selected={experience === "15-20" ? true : false} value="15-20">15 - 20 years</option>
                                                                                    <option selected={experience === "20+" ? true : false} value="20+">20+ years</option>
                                                                                </Field>
                                                                                {errors.experience && touched.experience ? (
                                                                                <div className="text-danger ml-2">{errors.experience}</div>
                                                                                ) : null}
                                                                            </div>
                                                                            <div className="col-6">
                                                                                <label for="referredBy" className="mb-1">Referred by</label>
                                                                                <Field component="select" name="referredBy" className={"custom-select"  + (errors.referredBy && touched.referredBy ? ' is-invalid' : '')}>
                                                                                    <option disabled selected={referredBy === "" ? true : false} value="">Please select</option>
                                                                                    <option selected={referredBy === "rachna.jindal@cii.in" ? true : false} value="rachna.jindal@cii.in">Rachna Jindal</option>
                                                                                    <option selected={referredBy === "prakash.kedia@cii.in" ? true : false} value="prakash.kedia@cii.in">Prakash Kedia</option>
                                                                                    <option selected={referredBy === "ravindra.kishore@cii.in" ? true : false} value="ravindra.kishore@cii.in">Ravindra Kishore</option>
                                                                                    <option selected={referredBy === "pooja.sanchala@cii.in" ? true : false} value="pooja.sanchala@cii.in">Pooja Sanchala</option>
                                                                                    <option selected={referredBy === "akriti.kumari@cii.in" ? true : false} value="akriti.kumari@cii.in">Akriti Kumari</option>
                                                                                    <option selected={referredBy === "prince.thomas@cii.in" ? true : false} value="prince.thomas@cii.in">Prince Thomas</option>
                                                                                    <option selected={referredBy === "divya.arya@cii.in" ? true : false} value="divya.arya@cii.in">Divya Arya</option>
                                                                                    <option selected={referredBy === "jkumar@cii.in" ? true : false} value="jkumar@cii.in">Jyoti Kumar</option>
                                                                                    <option selected={referredBy === "anil.pandey@cii.in" ? true : false} value="anil.pandey@cii.in">Anil Pandey</option>
                                                                                    <option selected={referredBy === "shalini.sharma@cii.in" ? true : false} value="shalini.sharma@cii.in">Shalini Sharma</option>
                                                                                    <option selected={referredBy === "neha.gupta@cii.in" ? true : false} value="neha.gupta@cii.in">Neha Gupta</option>
                                                                                    <option selected={referredBy === "poonam.dikshit@cii.in" ? true : false} value="poonam.dikshit@cii.in">Poonam Dikshit</option>
                                                                                    <option selected={referredBy === "payal.sharma@cii.in" ? true : false} value="payal.sharma@cii.in">Payal Sharma</option>
                                                                                    <option selected={referredBy === "ramesh.karwani@cii.in" ? true : false} value="ramesh.karwani@cii.in">Ramesh</option>
                                                                                    <option selected={referredBy === "namita.bhal@cii.in" ? true : false} value="namita.bhal@cii.in">Namita Bahl</option>
                                                                                    <option selected={referredBy === "khannadivya1986@gmail.com" ? true : false} value="khannadivya1986@gmail.com">Divya Khanna</option>
                                                                                    <option selected={referredBy === "amitshahi@igesia.co" ? true : false} value="amitshahi@igesia.co">Amit Shahi</option>
                                                                                    <option selected={referredBy === "arani@igesia.co" ? true : false} value="arani@igesia.co">Arani Chaudhuri</option>
                                                                                    <option selected={referredBy === "aroop@igesia.co" ? true : false} value="aroop@igesia.co">Aroop Banerjee</option>
                                                                                    <option selected={referredBy === "deepak@igesia.co" ? true : false} value="deepak@igesia.co">Deepak</option>
                                                                                    <option selected={referredBy === "george@igesia.co" ? true : false} value="george@igesia.co">George</option>
                                                                                    <option selected={referredBy === "natasha@igesia.co" ? true : false} value="natasha@igesia.co">Natasha</option>
                                                                                    <option selected={referredBy === "soumitra@igesia.co" ? true : false} value="soumitra@igesia.co">Soumitra Dutta</option>
                                                                                    <option selected={referredBy === "Others" ? true : false} value="Others">Others</option>
                                                                                </Field>
                                                                                {errors.referredBy && touched.referredBy ? (
                                                                                <div className="text-danger ml-2">{errors.referredBy}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-row row mb-2">
                                                                            <div className="col-12">
                                                                                <label for="reason" className="mb-1">I am interested in the program on behalf of:</label>
                                                                                <Field component="select" name="reason" class={"custom-select"  + (errors.reason && touched.reason ? ' is-invalid' : '')}>
                                                                                    <option disabled selected={reason === "" ? true : false} value="">Please select</option>
                                                                                    <option selected={reason === "Myself" ? true : false} value="Myself">Myself</option>
                                                                                    <option selected={reason === "Someone in my organisation" ? true : false} value="Someone in my organisation">Someone in my organisation</option>
                                                                                    <option selected={reason === "Myself and someone else" ? true : false} value="Myself and someone else">Myself and someone else</option>
                                                                                </Field>
                                                                                {errors.reason && touched.reason ? (
                                                                                <div className="text-danger ml-2">{errors.reason}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        <h6 className="mt-4 font-weight-bolder">Address details</h6>
                                                                        <div className="form-row row">
                                                                            <div className="form-label-group col-12">
                                                                                <Field
                                                                                    name="address"
                                                                                    placeholder="Address"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                <label for="address" className="mb-1">Address</label>
                                                                                {errors.address && touched.address ? (
                                                                                <div className="text-danger ml-2">{errors.address}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-row row">
                                                                            <div className="form-label-group col-12">
                                                                                <Field
                                                                                    name="locality"
                                                                                    placeholder="locality"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                <label for="locality" className="mb-1">Locality (Area and Street)</label>
                                                                                {errors.locality && touched.locality ? (
                                                                                <div className="text-danger ml-2">{errors.locality}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>

                                                                        <Formik
                                                                        innerRef={ref2}
                                                                        enableReinitialize
                                                                        validationSchema={UserCountrySchema}
                                                                        validateOnBlur={true}
                                                                        validateOnChange={true}
                                                                        initialValues={{
                                                                            countryName: countryName,
                                                                            stateName: stateName,
                                                                        }}


                                                                        >
                                                                        {({ errors, touched, isValidating, values }) => (

                                                                            <Form>
                                                                                    <div>
                                                                                            <div className="form-row">
                                                                                                <div className="form-label-group col-6">
                                                                                                    <CountryDropdown
                                                                                                        name="countryName"
                                                                                                        className="form-control"
                                                                                                        value={countryName}
                                                                                                        onChange={(val) => setCountryName(val)}
                                                                                                        />
                                                                                                    {errors.countryName && touched.countryName ? (
                                                                                                    <div className="text-danger ml-2">{errors.countryName}</div>
                                                                                                    ) : null}
                                                                                                </div>

                                                                                                <div className="form-label-group col-6">
                                                                                                    <RegionDropdown
                                                                                                        country={countryName}
                                                                                                        className="form-control"
                                                                                                        value={stateName}
                                                                                                        name="stateName"
                                                                                                        onChange={(val) => setStateName(val)} />
                                                                                                    {errors.stateName && touched.stateName ? (
                                                                                                    <div className="text-danger ml-2">{errors.stateName}</div>
                                                                                                    ) : null}
                                                                                                </div>
                                                                                            </div>
                                                                                    </div>


                                                                            </Form>
                                                                        )}

                                                                        </Formik>

                                                                        {/* <div className="form-row">
                                                                            <div className="form-label-group col-6">
                                                                                <CountryDropdown
                                                                                    name="countryName"
                                                                                    className="form-control"
                                                                                    value={countryName}
                                                                                    onChange={(val) => setCountryName(val)}
                                                                                     />
                                                                                {errors.countryName && touched.countryName ? (
                                                                                <div className="text-danger ml-2">{errors.countryName}</div>
                                                                                ) : null}
                                                                            </div>

                                                                            <div className="form-label-group col-6">
                                                                                <RegionDropdown
                                                                                    country={countryName}
                                                                                    className="form-control"
                                                                                    value={stateName}
                                                                                    name="stateName"
                                                                                    onChange={(val) => setStateName(val)} />
                                                                                {errors.stateName && touched.stateName ? (
                                                                                <div className="text-danger ml-2">{errors.stateName}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div> */}

                                                                        <div className="form-row">
                                                                            <div className="form-label-group col-6">
                                                                                <Field
                                                                                    name="pincode"
                                                                                    placeholder="pincode"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                <label for="pincode" className="mb-1">Pincode</label>
                                                                                {errors.pincode && touched.pincode ? (
                                                                                <div className="text-danger ml-2">{errors.pincode}</div>
                                                                                ) : null}
                                                                            </div>

                                                                            <div className="form-label-group col-6">
                                                                                <Field
                                                                                    name="cityName"
                                                                                    placeholder="cityName"
                                                                                    className="form-control"
                                                                                    type="text"
                                                                                />
                                                                                <label for="cityName" className="mb-1">City</label>
                                                                                {errors.cityName && touched.cityName ? (
                                                                                <div className="text-danger ml-2">{errors.cityName}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        </>
                                                                    
                                                                </div>
                                                           </Form>
                                                      )}

                                                  </Formik>

                                                  </Card.Body>

                                                <Card.Footer className="bg-white">
                                                    {(errorBlock === "0" && errorMessage !== "") && <p className="text-danger">Notice: {errorMessage}</p>}
                                                    <Accordion.Toggle
                                                        className="btn btn-primary rounded-0"
                                                        type="submit"
                                                        onClick={() => {
                                                            ref2.current.submitForm()
                                                            .then(() => {

                                                            })
                                                            .catch((err) => console.log(err))
                                                            ref.current.submitForm()
                                                            .then(() => {
                                                                
                                                            })
                                                            .catch((err) => {
                                                                console.log(err)
                                                            })
                                                            
                                                            }
                                                        }
                                                        >
                                                        CONTINUE
                                                    </Accordion.Toggle>
                                                </Card.Footer>
                                                </div>
                                                </Accordion.Collapse>
                                                </Card>
                                                {/* User Details End */}





                                                    {/* <Form.Row>
                                                        <div className="form-label-group col-6">
                                                            <input
                                                                name="firstName"
                                                                value={firstName}
                                                                onChange={e => setFirstName(e.target.value)}
                                                                placeholder="First name"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            <label for="firstName" className="mb-1">First name</label>
                                                        </div>
                                                        <div className="form-label-group col-6">
                                                            <input
                                                                name="lastName"
                                                                value={lastName}
                                                                onChange={e => setLastName(e.target.value)}
                                                                placeholder="Last name"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            <label for="lastName" className="mb-1">Last name</label>
                                                        </div>
                                                    </Form.Row>

                                                    <Form.Row>
                                                        <div className="form-label-group col-12">
                                                            <input
                                                                name="emailAddress"
                                                                value={emailAddress}
                                                                onChange={e => setEmailAddress(e.target.value)}
                                                                placeholder="yourname@email.com"
                                                                className="form-control"
                                                                type="email"
                                                            />
                                                            <label for="emailAddress" className="mb-1">Personal Email</label>
                                                        </div>
                                                    </Form.Row>

                                                    <Form.Row>
                                                        <div className="form-label-group col-12">
                                                            <input
                                                                name="phoneNumber"
                                                                placeholder={phoneNumber}
                                                                className="form-control"
                                                                value={phoneNumber}
                                                                onChange={e => {
                                                                    var pattern = /^\+[0-9\s\-\(\)]+$/;

                                                                    e.target.value.match(pattern) && setPhoneNumber(e.target.value)
                                                                }}
                                                                type="tel"
                                                                pattern="\+[0-9\s\-\(\)]+"
                                                            />
                                                            <label for="phoneNumber" className="mb-1">Phone number</label>
                                                        </div>
                                                    </Form.Row> */}

                                                    

                                                    {/* Company Details */}
                                                    {/* <h6 className="mt-4 font-weight-bolder">Company details</h6>
                                                    <Form.Row className="row">
                                                        <div className="form-label-group col-12">
                                                            <input
                                                                name="companyName"
                                                                value={companyName}
                                                                onChange={e => setCompanyName(e.target.value)}
                                                                placeholder="Company name"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            <label for="companyName" className="mb-1">Company name</label>
                                                        </div>
                                                    </Form.Row>

                                                    <Form.Row className="row">
                                                        <div className="form-label-group col-6">
                                                            <input
                                                                name="designation"
                                                                value={designation}
                                                                onChange={e => setDesignation(e.target.value)}
                                                                placeholder="Designation"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            <label for="designation" className="mb-1">Designation</label>
                                                        </div>
                                                        <div className="form-label-group col-6">
                                                            <input
                                                                name="businessUnit"
                                                                value={businessUnit}
                                                                onChange={e => setBusinessUnit(e.target.value)}
                                                                placeholder="Function"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            <label for="businessUnit" className="mb-1">Function</label>
                                                        </div>
                                                    </Form.Row>

                                                    <Form.Row>
                                                        <div className="form-label-group col-12">
                                                            <input
                                                                name="corporateEmailAddress"
                                                                value={corporateEmailAddress}
                                                                onChange={e => setCorporateEmailAddress(e.target.value)}
                                                                placeholder="yourname@company.com"
                                                                className="form-control"
                                                                type="email"
                                                            />
                                                            <label for="corporateEmailAddress" className="mb-1">Corporate Email</label>
                                                        </div>
                                                    </Form.Row>

                                                    <Form.Row className="row mb-2">
                                                        <div className="col-6">
                                                            <label for="experience" className="mb-1">Experience (in years)</label>
                                                            <select name="experience" onChange={e => setExperience(e.target.value)} class="custom-select">
                                                                <option disabled selected={experience === "" ? true : false} value="">Please select</option>
                                                                <option selected={experience === "0-5" ? true : false} value="0-5">0 - 5 years</option>
                                                                <option selected={experience === "5-10" ? true : false} value="5-10">5 - 10 years</option>
                                                                <option selected={experience === "10-15" ? true : false} value="10-15">10 - 15 years</option>
                                                                <option selected={experience === "15-20" ? true : false} value="15-20">15 - 20 years</option>
                                                                <option selected={experience === "20+" ? true : false} value="20+">20+ years</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-6">
                                                            <label for="referredBy" className="mb-1">Referred by</label>
                                                            <select name="referredBy" onChange={e => setReferredBy(e.target.value)} class="custom-select">
                                                                <option disabled selected={referredBy === "" ? true : false} value="">Please select</option>
                                                                <option selected={referredBy === "rachna.jindal@cii.in" ? true : false} value="rachna.jindal@cii.in">Rachna Jindal</option>
                                                                <option selected={referredBy === "prakash.kedia@cii.in" ? true : false} value="prakash.kedia@cii.in">Prakash Kedia</option>
                                                                <option selected={referredBy === "ravindra.kishore@cii.in" ? true : false} value="ravindra.kishore@cii.in">Ravindra Kishore</option>
                                                                <option selected={referredBy === "pooja.sanchala@cii.in" ? true : false} value="pooja.sanchala@cii.in">Pooja Sanchala</option>
                                                                <option selected={referredBy === "akriti.kumari@cii.in" ? true : false} value="akriti.kumari@cii.in">Akriti Kumari</option>
                                                                <option selected={referredBy === "prince.thomas@cii.in" ? true : false} value="prince.thomas@cii.in">Prince Thomas</option>
                                                                <option selected={referredBy === "divya.arya@cii.in" ? true : false} value="divya.arya@cii.in">Divya Arya</option>
                                                                <option selected={referredBy === "jkumar@cii.in" ? true : false} value="jkumar@cii.in">Jyoti Kumar</option>
                                                                <option selected={referredBy === "anil.pandey@cii.in" ? true : false} value="anil.pandey@cii.in">Anil Pandey</option>
                                                                <option selected={referredBy === "shalini.sharma@cii.in" ? true : false} value="shalini.sharma@cii.in">Shalini Sharma</option>
                                                                <option selected={referredBy === "neha.gupta@cii.in" ? true : false} value="neha.gupta@cii.in">Neha Gupta</option>
                                                                <option selected={referredBy === "poonam.dikshit@cii.in" ? true : false} value="poonam.dikshit@cii.in">Poonam Dikshit</option>
                                                                <option selected={referredBy === "payal.sharma@cii.in" ? true : false} value="payal.sharma@cii.in">Payal Sharma</option>
                                                                <option selected={referredBy === "ramesh.karwani@cii.in" ? true : false} value="ramesh.karwani@cii.in">Ramesh</option>
                                                                <option selected={referredBy === "namita.bhal@cii.in" ? true : false} value="namita.bhal@cii.in">Namita Bahl</option>
                                                                <option selected={referredBy === "khannadivya1986@gmail.com" ? true : false} value="khannadivya1986@gmail.com">Divya Khanna</option>
                                                                <option selected={referredBy === "Others" ? true : false} value="Others">Others</option>
                                                            </select>
                                                        </div>
                                                    </Form.Row>

                                                    <Form.Row className="row mb-2">
                                                        <div className="col-12">
                                                            <label for="reason" className="mb-1">I am interested in the program on behalf of:</label>
                                                            <select name="reason" onChange={e => setReason(e.target.value)} class="custom-select">
                                                                <option disabled selected={reason === "" ? true : false} value="">Please select</option>
                                                                <option selected={reason === "Myself" ? true : false} value="Myself">Myself</option>
                                                                <option selected={reason === "Someone in my organisation" ? true : false} value="Someone in my organisation">Someone in my organisation</option>
                                                                <option selected={reason === "Myself and someone else" ? true : false} value="Myself and someone else">Myself and someone else</option>
                                                            </select>
                                                        </div>
                                                    </Form.Row> */}

                                                    {/* Address Details */}
                                                    {/* <h6 className="mt-4 font-weight-bolder">Address details</h6>
                                                    <Form.Row className="row">
                                                        <div className="form-label-group col-12">
                                                            <input
                                                                name="address"
                                                                value={address}
                                                                onChange={e => setAddress(e.target.value)}
                                                                placeholder="Address"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            <label for="address" className="mb-1">Address</label>
                                                        </div>
                                                    </Form.Row>

                                                    <Form.Row className="row">
                                                        <div className="form-label-group col-12">
                                                            <input
                                                                name="locality"
                                                                value={locality}
                                                                onChange={e => setLocality(e.target.value)}
                                                                placeholder="locality"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            <label for="locality" className="mb-1">Locality (Area and Street)</label>
                                                        </div>
                                                    </Form.Row>

                                                    <Form.Row>
                                                        <div className="form-label-group col-6">
                                                            <CountryDropdown
                                                                className="form-control"
                                                                value={countryName}
                                                                onChange={(val) => setCountryName(val)} />
                                                        </div>

                                                        <div className="form-label-group col-6">
                                                            <RegionDropdown
                                                                country={countryName}
                                                                className="form-control"
                                                                value={stateName}
                                                                onChange={(val) => setStateName(val)} />
                                                        </div>
                                                    </Form.Row>

                                                    <Form.Row>
                                                        <div className="form-label-group col-6">
                                                            <input
                                                                name="pincode"
                                                                value={pincode}
                                                                onChange={e => {
                                                                    var pattern = /^[0-9\s\-\(\)]+$/;

                                                                    e.target.value.match(pattern) && settPincode(e.target.value)
                                                                }}
                                                                placeholder="pincode"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            <label for="pincode" className="mb-1">Zipcode</label>
                                                        </div>

                                                        <div className="form-label-group col-6">
                                                            <input
                                                                name="cityName"
                                                                value={cityName}
                                                                onChange={e => setCityName(e.target.value)}
                                                                placeholder="cityName"
                                                                className="form-control"
                                                                type="text"
                                                            />
                                                            <label for="cityName" className="mb-1">City</label>
                                                        </div>
                                                    </Form.Row> */}

                                                    {/* <Form.Group id="formGridCheckbox">
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Check this consent"
                                                            checked={consent}
                                                            onChange={e => {
                                                                setConsent(!consent)
                                                                setConsentError(!consentError)
                                                            }}
                                                            isInvalid={consentError}
                                                            feedback="Please check this to proceed" />
                                                    </Form.Group> */}
                                                {/* </Form> */}

                                                {/* dynamic of myself & someonelse */}

                                                {
                                                // (reason === 'Myself and someone else') ? 
                                                // (
                                                //     <Formik
                                                //     initialValues={{
                                                //         users:[{
                                                //             firstName: '',
                                                //             lastName: '',
                                                //             emailAddress: '',
                                                //             setEmailAddress: '',
                                                //             countryCode: '',
                                                //             phoneNumber: '',
                                                //             countryName: '',
                                                //             regionName: ''

                                                //         }]
                                                //     }}
                                                //     onSubmit={ values => {
                                                //         alert('here');
                                                //         console.log(values,'myself and someone else')
                                                //     }}
                                                //   >
                                                //       {({ errors, touched, isValidating, values }) => (

                                                //            <Form className="col-md-12">

                                                //             <FieldArray name="users">
                                                //                 {({ insert, remove, push }) => (
                                                //                 <div>
                                                //                     {values.users.length > 0 &&
                                                //                     values.users.map((tag, index) => (
                                                //                         <>
                                                //                         <hr></hr>
                                                //                         <h6 className="font-weight-bolder">Personal details</h6>
                                                //                         <Form.Row className="mt-3">
                                                //                         <div className="form-label-group col-6" key={index}>
                                                //                             <Field
                                                //                             name={`firstName[${index}]`}
                                                //                             placeholder="First Name"
                                                //                             type="text"
                                                //                             className='form-control'
                                                //                             />
                                                //                             <label for={`firstName[${index}]`} className="mb-1">First name</label>
                                                //                         </div>
                                                //                         <div className="form-label-group col-6" key={index}>
                                                //                             <Field
                                                //                             name={`lastName[${index}]`}
                                                //                             placeholder="Last Name"
                                                //                             type="text"
                                                //                             className='form-control'
                                                //                             />
                                                //                             <label for={`lastName[${index}]`} className="mb-1">Last name</label>
                                                //                         </div>
                                                //                         </Form.Row>
                                                //                         <Form.Row>
                                                //                         <div className="form-label-group col-12" key={index}>
                                                //                             <Field
                                                //                             name={`emailAddress[${index}]`}
                                                //                             placeholder="Personal Email"
                                                //                             type="text"
                                                //                             className='form-control'
                                                //                             />
                                                //                             <label for={`emailAddress[${index}]`} className="mb-1">Personal Email</label>
                                                //                         </div>
                                                //                         </Form.Row>
                                                //                         <Form.Row>
                                                //                         <div className="form-label-group col-12" key={index}>
                                                //                             <Field
                                                //                             name={`phoneNumber[${index}]`}
                                                //                             placeholder="Phone Number"
                                                //                             type="tel"
                                                //                             className='form-control'
                                                //                             pattern="\+[0-9\s\-\(\)]+"
                                                //                             value={phoneNumber}
                                                //                             />
                                                //                             <label for={`phoneNumber[${index}]`} className="mb-1">Phone Number</label>
                                                //                         </div>
                                                //                         </Form.Row>






                                                //                         <div className="col text-center">
                                                //                             <button
                                                //                             type="button"
                                                //                             className="secondary btn btn-danger "
                                                //                             onClick={() => remove(index)}
                                                //                             >
                                                //                             X
                                                //                             </button>
                                                //                         </div>


                                                //                         </>
                                                //                     ))}
                                                //                     <button
                                                //                     type="button"
                                                //                     className="secondary btn btn-info mt-3"
                                                //                     onClick={() => push()}
                                                //                     >
                                                //                     Add Participant
                                                //                     </button>
                                                //                 </div>
                                                //                 )}
                                                //             </FieldArray>

                                                //            </Form>
                                                //       )}

                                                //   </Formik>
                                                // )
                                                // : null
                                            }


                                                {/* myself and someonelse ends */}
                                            {/* dyanmic for now here */}

                                            {
                                                // (reason === 'Someone in my organisation') ? 
                                                // (
                                                //     <Formik
                                                //     initialValues={{
                                                //         users:[{
                                                //             firstName: '',
                                                //             lastName: '',
                                                //             emailAddress: '',
                                                //             setEmailAddress: '',
                                                //             countryCode: '',
                                                //             phoneNumber: '',

                                                //         }]
                                                //     }}
                                                //     onSubmit={ values => {

                                                //     }}
                                                //   >
                                                //       {({ errors, touched, isValidating, values }) => (

                                                //            <Form className="col-md-12">

                                                //             <FieldArray name="users">
                                                //                 {({ insert, remove, push }) => (
                                                //                 <div>
                                                //                     {values.users.length > 0 &&
                                                //                     values.users.map((tag, index) => (
                                                //                         <>
                                                //                         <hr></hr>
                                                //                         <h6 className="font-weight-bolder">Personal details</h6>
                                                //                         <Form.Row className="mt-3">
                                                //                         <div className="form-label-group col-6" key={index}>
                                                //                             <Field
                                                //                             name={`firstName[${index}]`}
                                                //                             placeholder="First Name"
                                                //                             type="text"
                                                //                             className='form-control'
                                                //                             />
                                                //                             <label for={`firstName[${index}]`} className="mb-1">First name</label>
                                                //                         </div>
                                                //                         <div className="form-label-group col-6" key={index}>
                                                //                             <Field
                                                //                             name={`lastName[${index}]`}
                                                //                             placeholder="Last Name"
                                                //                             type="text"
                                                //                             className='form-control'
                                                //                             />
                                                //                             <label for={`lastName[${index}]`} className="mb-1">Last name</label>
                                                //                         </div>
                                                //                         </Form.Row>
                                                //                         <Form.Row>
                                                //                         <div className="form-label-group col-12" key={index}>
                                                //                             <Field
                                                //                             name={`emailAddress[${index}]`}
                                                //                             placeholder="Personal Email"
                                                //                             type="text"
                                                //                             className='form-control'
                                                //                             />
                                                //                             <label for={`emailAddress[${index}]`} className="mb-1">Personal Email</label>
                                                //                         </div>
                                                //                         </Form.Row>
                                                //                         <Form.Row>
                                                //                         <div className="form-label-group col-12" key={index}>
                                                //                             <Field
                                                //                             name={`phoneNumber[${index}]`}
                                                //                             placeholder="Phone Number"
                                                //                             type="tel"
                                                //                             className='form-control'
                                                //                             pattern="\+[0-9\s\-\(\)]+"
                                                //                             value={phoneNumber}
                                                //                             />
                                                //                             <label for={`phoneNumber[${index}]`} className="mb-1">Phone Number</label>
                                                //                         </div>
                                                //                         </Form.Row>


                                                //                         <h6 className="font-weight-bolder">Company details</h6>
                                                //                         <h6 className="text-center font-italic font-weight-normal">Same as above</h6>


                                                //                         <div className="col text-center">
                                                //                             <button
                                                //                             type="button"
                                                //                             className="secondary btn btn-danger "
                                                //                             onClick={() => remove(index)}
                                                //                             >
                                                //                             X
                                                //                             </button>
                                                //                         </div>


                                                //                         </>
                                                //                     ))}
                                                //                     <button
                                                //                     type="button"
                                                //                     className="secondary btn btn-info mt-3"
                                                //                     onClick={() => push()}
                                                //                     >
                                                //                     Add Participant
                                                //                     </button>
                                                //                 </div>
                                                //                 )}
                                                //             </FieldArray>

                                                //            </Form>
                                                //       )}

                                                //   </Formik>
                                                // )
                                                // : null
                                            }


                                    {/* dynamic ends here */}




                                {/* Order Summary Start */}
                                <Card className="rounded-0 border-0 mt-3">
                                    <Card.Header
                                        eventKey="2"
                                        className={activeEventKey === "2" ? "bg-primary" : "bg-light"}>
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 d-flex align-items-center">
                                                <Badge
                                                    variant="light"
                                                    className={activeEventKey === "2"
                                                        ? "text-primary px-2 py-1 rounded-sm"
                                                        : "text-secondary border border-secondary px-2 py-1 rounded-sm"}
                                                >3</Badge>
                                                <p
                                                    className={activeEventKey === "2"
                                                        ? "text-white ml-3 my-0 font-weight-bold"
                                                        : "text-secondary ml-3 my-0 font-weight-bold"}
                                                >ORDER SUMMARY {(activeEventKey !== "1" && activeEventKey > 1) && <FontAwesomeIcon icon={faCheck} className="text-success" />}</p>
                                            </div>
                                            {(activeEventKey !== "2" && activeEventKey > 2) && <div>
                                                <Button
                                                    variant="outline-primary"
                                                    className="rounded-0"
                                                    size="sm"
                                                    onClick={() => setActiveEventKey("2")}
                                                >CHANGE</Button>
                                            </div>}
                                        </div>
                                    </Card.Header>
                                    {(activeEventKey !== "2" && activeEventKey > 2)
                                        && <Card.Footer className="bg-white">
                                        <div>{uniqueProgramIds.map((program, key) => (
                                            <div className="d-flex">
                                                <p className="flex-grow-1 small mb-0">{key + 1}. {uniqueEnrollPrograms[program][0].programName}<span className="font-italic ml-2">x{countOfProgramIds[program]}</span></p>
                                                <p className="small mb-0"><span className="font-weight-bold">{formatCurrent(uniqueEnrollPrograms[program][0].feeBreakout.baseAmount * countOfProgramIds[program])}</span> + <span className="small">GST ({uniqueEnrollPrograms[program][0].feeBreakout.tax}%)</span></p>
                                                
                                            </div>
                                        ))}</div>
                                        </Card.Footer>}
                                    <Accordion.Collapse eventKey="2">
                                        <div>
                                            <Card.Body className="row p-0">

                                                {uniqueProgramIds.map((program, key) => (
                                                    <div className="col-12 border-bottom d-flex">
                                                        {/* <div className="mt-4 ml-4 pr-0" style={{ height: 100, backgroundColor: "#EFEFEF", width: 800 / 5.33 }}>
                                                            <img src={program.programThumbnail} style={{ height: 100, width: 800 / 5.33 }} className="thumbnail rounded-sm" />
                                                        </div> */}
                                                        <div className="p-4">
                                                        <h6>{uniqueEnrollPrograms[program][0].programName}<span className="font-italic ml-2">x{countOfProgramIds[program]}</span></h6>
                                                            {/* <p className="text-muted small">{program.programShortDescription}</p> */}
                                                            <span className="font-weight-bold">{formatCurrent(uniqueEnrollPrograms[program][0].feeBreakout.baseAmount * countOfProgramIds[program])}</span> + <span className="small">GST ({uniqueEnrollPrograms[program][0].feeBreakout.tax}%)</span>                                                            {/* <Button variant="none" className="px-0 text-muted" onClick={() => removeFromCart(key)}>
                                                                <small className="font-weight-bold">REMOVE</small>
                                                            </Button> */}
                                                        </div>
                                                    </div>
                                                ))}

                                            </Card.Body>
                                            <Card.Footer className="bg-white border-0">
                                                <Accordion.Toggle
                                                    className="btn btn-primary rounded-0"
                                                    onClick={handleOrder} >
                                                    CONTINUE
                                    </Accordion.Toggle>
                                            </Card.Footer>
                                        </div>
                                    </Accordion.Collapse>
                                </Card>
                                {/* Order Summary End */}


                                {/* Payment Summary Start */}
                                <Card className="rounded-0 border-0 mt-3">
                                    <Card.Header
                                        eventKey="3"
                                        className={activeEventKey === "3" ? "bg-primary" : "bg-light"}>
                                        <div className="d-flex align-items-center">
                                            <Badge
                                                variant="light"
                                                className={activeEventKey === "3"
                                                    ? "text-primary px-2 py-1 rounded-sm"
                                                    : "text-secondary border border-secondary px-2 py-1 rounded-sm"}
                                            >4</Badge>
                                            <p className={activeEventKey === "3" ? "text-white ml-3 my-0 font-weight-bold" : "text-secondary ml-3 my-0 font-weight-bold"}>PAYMENT SUMMARY</p>
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="3">
                                        <Card.Body>
                                            {<div className="d-flex justify-content-center">
                                                <Button
                                                    variant="primary"
                                                    className="rounded-0 mr-auto"
                                                    onClick={e => handlePayment(e)}
                                                    id="rzp-button1">PAY NOW</Button>

                                                <div>
                                                    <p className="mb-0 small font-weight-bold">Total Payable</p>
                                                    <p className="mb-0 text-success font-weight-bold">{(baseAmountArray.length > 0 && taxArray.length > 0) && formatCurrent(totalBaseAmount + totalBaseAmount * totalTax / 100)}</p>
                                                </div>
                                            </div>}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                {/* Payment Summary End */}

                            </Accordion>
                        </div>}
                </>
                : <div>
                    <Card className="rounded-0">
                        <Card.Header className="bg-white">
                            <p className="text-secondary mb-0 font-weight-bold">Empty cart</p>
                        </Card.Header>
                        <Card.Body>
                            <FontAwesomeIcon icon={faExclamationCircle} className="text-warning w-100" size="5x" />
                            <p className="h4 font-weight-bold text-center">No programs selected!</p>
                            <p className="small text-center">Please explore and add programs</p>
                            <p className="mb-0 text-center"><Link to={`/`}>Back to home</Link></p>
                        </Card.Body>
                    </Card>
                </div>}
        </div>
    )
}

export default EnrollNow;