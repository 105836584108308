import React from 'react';
import moment from 'moment';
import {storageRef} from '../../firebase';
import firebase from '../../firebase';
import { FilePond } from 'react-filepond';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import DashboardNavbar from '../Dashboard/DashboardNavbar';


class AddFaculty extends React.Component{
    state={
        selectTitle:'',
        dob:'',
        dobDateMax: moment().format("YYYY-MM-DD"),
        gender:'',
        firstName:'',
        middleName:'',
        lastName:'',
        description:'',
        image:[],
        languages:[],
        mailInput:[{mail:''}],
        eduInput:[{course:'', college:'', startDate:'', endDate:''}],
        expInput:[{designation:'', organisation:'', startDate:'', endDate:''}],
        date: moment().format("YYYY-MM"),
        progress:null
    }


    uploadHandle = (e) => {
        e.preventDefault()
        var that = this
        var file = this.state.image;
        var metadata = {contentType: 'image/jpeg' };
        var uploadTask = storageRef.child('faculty/' + file.name).put(file, metadata);
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
        snapshot => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        this.setState({progress: Math.round(progress)})
        switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED:
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING: 
                console.log('Upload is running');
                break;
        }
        },error => {
            switch (error.code){
                case 'storage/unauthorized':
                    break;
                case 'storage/canceled':
                    break;
                case 'storage/unknown':
                    break;
        }},() => {
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL){
            console.log('File available at', downloadURL);
            that.setState({url:downloadURL})
            console.log('url',that.state.url)
        });
        });
    }
    checkboxHandle = (event) => {
        if(event.target.checked){
          this.setState({languages:[...this.state.languages, event.target.value]})
        }
    }
    handleMailInputChange = (index, e) => {
        e.preventDefault()
        var values =[...this.state.mailInput];
        if(e.target.name === "mail"){
          values[index].mail =e.target.value;
        }
        this.setState({mailInput:values})
    }
    eMailAddField = (e) => {
        e.preventDefault();
        var values = [...this.state.mailInput];
        values.push({mail:''})
        this.setState({mailInput:values})
    }
    eMailRemoveField = (e, index) => {
        e.preventDefault();
        var values = [...this.state.mailInput];
        values.splice(index, 1);
        this.setState({mailInput:values})
    }
    handleEduInputChange = (index, e) => {
        e.preventDefault()
        const values = [...this.state.eduInput];
        if (e.target.name === "course") {
          values[index].course = e.target.value;
        } else if(e.target.name==="college") {
          values[index].college = e.target.value;
        } else if(e.target.name==="start_date"){
          values[index].startDate = e.target.value;
        } else {
          values[index].endDate = e.target.value;
        }
          this.setState({eduInput:values})
    }
    educationAddField = (e) => {
        e.preventDefault()
        const values = [...this.state.eduInput];
        values.push({ course: '', college: '',startDate:'', endDate:'' });
        this.setState({eduInput:values})
    };
   
    educationRemoveField = (e, index) => {
        e.preventDefault()
        const values = [...this.state.eduInput];
        values.splice(index, 1);
        this.setState({eduInput:values})
    }
    handleExpInputChange = (index,event) => {
        event.preventDefault()
        const value = [...this.state.expInput];
        if (event.target.name === "designation") {
          value[index].designation = event.target.value;
        } else if(event.target.name==="organisation") {
          value[index].organisation = event.target.value;
        } else if(event.target.name==="start_date"){
          value[index].startDate = event.target.value;
        } else {
          value[index].endDate = event.target.value;
        }
          this.setState({expInput:value})
    }
    experienceAddField = (e) => {
        e.preventDefault()
        const values = [...this.state.expInput];
        values.push({ designation: '', organisation: '',startDate:'', endDate:'' });
        this.setState({expInput:values})
    };
    
    experienceRemoveField = (e, index) => {
        e.preventDefault()
        const values = [...this.state.expInput];
        values.splice(index, 1);
        this.setState({expInput:values})
    }
    submit = (e) => {
        e.preventDefault();
        if(this.state.middleName===''){
        const fullname = {selectTitle:this.state.selectTitle,firstName:this.state.firstName, lastName:this.state.lastName}
        firebase.firestore().collection('faculty').add({
            fullName:fullname,
            gender:this.state.gender,
            dob:this.state.dob,
            email:this.state.mailInput,
            description:this.state.description,
            languages:this.state.languages,
            education:this.state.eduInput,
            experience:this.state.expInput,
            books:[],
            papers:[],
            programsTeaching:[],
            profilePicUrl:this.state.url
        }).then(() => this.setState({selectTitle:'', firstName: '', middleName:'', lastName: '' , 
            description:'', languages:[], gender:'', dob:'', image:[],
            education:{course:'', college:'', startDate:'', endDate:''}, 
            experience:{designation:'', organisation:'', startDate:'', endDate:'' }}))
       }else{
            const fullName = {selectTitle:this.state.selectTitle, firstName:this.state.firstName, middleName:this.state.middleName, lastName:this.state.lastName,  languages:[],}
            console.log('under url',this.state.url)
            firebase.firestore().collection('faculty').add({
            fullName:fullName,
            gender:this.state.gender,
            dob:this.state.dob,
            email:this.state.mailInput,
            description:this.state.description,
            languages:this.state.languages,
            education:this.state.eduInput,
            experience:this.state.expInput,
            books:[],
            papers:[],
            programsTeaching:[],
            profilePicUrl:this.state.url
        }).then(() => this.setState({selectTitle:'', firstName: '', middleName:'', lastName: '' , 
            description:'', languages:[], gender:'', dob:'', image:[],
            education:{course:'', college:'', date:''}, 
            experience:{designation:'', organisation:'', startDate:'', endDate:'' }}))
        }
            alert("Added Successfully");
            this.props.history.push('/dashboard/');
        }
    render(){
        return (
            <div className='container'>
                {/*<div className='my-5'>
                    <img alt="igesia logo" style={{width:'15%', height:'15%'}}  src="https://firebasestorage.googleapis.com/v0/b/igesia-dbadmin.appspot.com/o/faculty%2Funnamed.png?alt=media&token=32746652-7be7-4ed6-a7f6-596023775188" />
                </div>*/}
                <div className='container'>
                    <DashboardNavbar />
                    <h3 className="text-white mt-3 mb-3">Add Faculty</h3>
                    <form className='form-group text-white' onSubmit={this.submit} >
                
                        <div className='row mb-4'>
                            <div className='col-md-6' > 
                                <label>Title :-</label><br/>
                                <select 
                                    className="custom-select mr-sm-2 " 
                                    value={this.state.selectTitle} 
                                    onChange={event => this.setState({selectTitle:event.target.value})}>
                                    <option>Choose Prefix</option>
                                    <option value='Professor'>Professor</option>
                                    <option value='Dr.'>Dr.</option>
                                    <option value='Hon’ble Vice Chancellor Professor'>Hon’ble Vice Chancellor Professor</option>
                                    <option value='Mr.'>Mr.</option>
                                    <option value='Ms.'>Ms.</option>
                                    <option value='Mrs.'>Mrs.</option>
                                    <option value='Smt.'>Smt.</option>
                                    <option value='Shri.'>Shri.</option>
                                </select>
                            </div>

                            <div className='col-md-6'  >
                                <label>Gender:-</label><br/>
                                <select 
                                    className="custom-select mr-sm-2 " 
                                    value={this.state.gender} 
                                    onChange={event => this.setState({gender:event.target.value})}>
                                    <option>Select Gender</option>
                                    <option value='Male'>Male</option>
                                    <option value='Female'>Female</option>
                                    <option value='Others'>Others</option>
                                </select>
                            </div>
                        </div><hr/>

                        <div className='row'>
                            <div className='col-md-4'>
                                <label>FirstName :-</label><br/>
                                    <input 
                                    required
                                    className='form-control' 
                                    placeholder='Enter your First Name*'
                                    onChange={event => this.setState({firstName: event.target.value })}
                                    value={this.state.firstName}/>
                            </div>

                            <div className='col-md-4'>
                                <label>MiddleName :-</label><br/>
                                    <input 
                                    className='form-control' 
                                    placeholder='Enter your Middle Name'  
                                    onChange={event => {this.setState({middleName:event.target.value}) }}
                                    value={this.state.middleName}/><br />
                            </div>

                            <div className='col-md-4'>
                                <label>LastName :-</label><br/>
                                    <input 
                                    required
                                    className='form-control' 
                                    placeholder='Enter your Last Name*'
                                    onChange={event => this.setState({lastName: event.target.value})}
                                    value={this.state.lastName}/><br />
                            </div>
                        </div>  <hr/>
                
                        <div className='mb-3' >  
                            <div className='row ml-2' >
                                <label> Description :-</label><br/>
                                    <textarea 
                                    className='form-control' 
                                    placeholder='About yourself'
                                    onChange={event => this.setState({description:event.target.value})}
                                    value={this.state.description}/>
                            </div>
                        </div><hr/>
                

                        <div className='row mb-4'>    
                            <div className='col-md-6 mb-2'>
                                <label  >Upload Profile pic:- </label>
                                    <FilePond 
                                        maxFiles={1} 
                                        allowMultiple={false}
                                        onupdatefiles={fileItems => fileItems.map((fileItem) => this.setState({image:fileItem.file}))}>
                                    </FilePond>
                                    <button className='btn btn-primary mt-3' onClick={(e) => this.uploadHandle(e)}>
                                        Upload
                                    </button>
                                    {(this.state.progress===null) ? (null):(
                                        <div className="progress">
                                            <div 
                                                className="progress-bar progress-striped" 
                                                role="progressbar" 
                                                style= {{width:`${this.state.progress}%`}}
                                                aria-valuenow="10" 
                                                aria-valuemin="0" 
                                                aria-valuemax="100">{this.state.progress}%</div>
                                        </div>
                                    )}
                            </div>

                
                            <div className='col-md-6 ' >
                                <div >Languages:- </div><br/>
                                    <label>English
                                    <input className="mr-2" type='checkbox' value='English' name='English' onChange={this.checkboxHandle}/></label>
                                    <label>Hindi
                                    <input className="mr-2" type='checkbox' value='Hindi' name='Hindi'  onChange={this.checkboxHandle}/></label>
                                    <label>Spanish
                                    <input className="mr-2" type='checkbox' value='Spanish' name='Spanish'  onChange={this.checkboxHandle}/></label>
                                    <label>German
                                    <input className="mr-2" type='checkbox' value='German' name='German'  onChange={this.checkboxHandle}/></label>
                            </div>
                        </div>   <hr/> 

                        <div className='row'>
                            <div className='col-md-6'>
                                {this.state.mailInput.map((mailInput,index) => (
                                    <div key={index}>
                                        <div className="row ml-1 mb-3">
                                            <label>Email:-</label>
                                                <input 
                                                className='form-control' 
                                                type='text' placeholder='Email' 
                                                name='mail'
                                                value={this.state.mailInput.mail} 
                                                onChange={event => this.handleMailInputChange(index, event)} />
                                            <button className='btn btn-primary' onClick={(e) => this.eMailAddField(e)}>+</button>
                                            <button className='btn btn-primary' onClick={(e)=> this.eMailRemoveField(e,index)} >-</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='col-md-6 ' >
                                <label>Date of Birth:-</label>
                                <input 
                                    className='form-control' 
                                    type='date' 
                                    placeholder='DOB' 
                                    name='dob' 
                                    max={this.state.dobDateMax}
                                    value={this.state.dob}
                                    onChange={event => this.setState({dob:event.target.value})}/>
                            </div>
                        </div>
                            
                        { this.state.eduInput.map((eduInput,index) =>  (
                            <div className=' border border-dark rounded my-4' key={index}>
                                <div className='mx-3 my-3'>
                                    <h4>Education :-</h4>
                                    
                                        <div className='form-group'>
                                            <input 
                                                className='form-control' 
                                                type='text' 
                                                placeholder='Course' 
                                                name='course'
                                                value={this.state.eduInput.course}
                                                onChange={event => this.handleEduInputChange(index, event)}/>
                                        </div> 
                                        <div className='form-group'>
                                            <input 
                                                className='form-control' 
                                                type='text' 
                                                placeholder='College' 
                                                name='college'
                                                onChange={event => this.handleEduInputChange(index, event)}
                                                value={this.state.eduInput.college}/>
                                        </div> 
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Start Date</label>
                                                    <input 
                                                    className='form-control' 
                                                    type='month'  
                                                    placeholder='Start Date'  
                                                    name='start_date'
                                                    max={this.state.date}
                                                    value={this.state.eduInput.startDate} 
                                                    onChange={event => this.handleEduInputChange(index, event)}/>
                                            </div> 
                                            <div className='col-md-6'>
                                                <label>End Date</label>
                                                    <input 
                                                        className='form-control' 
                                                        type='month' 
                                                        placeholder='End Date' 
                                                        name='End Date'
                                                        max={this.state.date}
                                                        value={this.state.eduInput.endDate}
                                                        onChange={event => this.handleEduInputChange(index, event)}/>
                                            </div>
                                        </div>
                                        <div className='text-center my-3'>
                                            <button className='btn btn-primary mr-2' onClick={(e) => this.educationAddField(e)}>+</button>
                                            <button className='btn btn-primary' onClick={(e)=> this.educationRemoveField(e,index)} >-</button>
                                        </div>
                                </div>    
                            </div>))}
                        

                            { this.state.expInput.map((expInput,index) =>  (
                            <div className=' border border-dark rounded my-4' key={index}>
                                <div className='mx-3 my-3'>
                                    <h4>Experience :-</h4>
                                    
                                        <div className='form-group'>
                                            <input 
                                                className='form-control' 
                                                type='text' 
                                                placeholder='Designation' 
                                                name='designation'
                                                value={this.state.expInput.designation}
                                                onChange={event => this.handleExpInputChange(index, event)}/>
                                        </div> 
                                        <div className='form-group'>
                                            <input 
                                                className='form-control' 
                                                type='text' 
                                                placeholder='Organisation' 
                                                name='organisation'
                                                onChange={event => this.handleExpInputChange(index, event)}
                                                value={this.state.expInput.organisation}/>
                                        </div> 
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label>Start Date</label>
                                                    <input 
                                                    className='form-control' 
                                                    type='month'  
                                                    placeholder='Start Date'  
                                                    name='start_date'
                                                    max={this.state.date}
                                                    value={this.state.expInput.startDate} 
                                                    onChange={event => this.handleExpInputChange(index, event)}/>
                                            </div> 
                                            <div className='col-md-6'>
                                                <label>End Date</label>
                                                    <input 
                                                        className='form-control' 
                                                        type='month' 
                                                        placeholder='End Date' 
                                                        name='end_date'
                                                        max={this.state.date}
                                                        value={this.state.expInput.endDate}
                                                        onChange={event => this.handleExpInputChange(index, event)}/>
                                            </div>
                                        </div>
                                        <div className='text-center my-3'>
                                            <button className='btn btn-primary mr-2' onClick={(e) => this.experienceAddField(e)}>+</button>
                                            <button className='btn btn-primary' onClick={(e)=> this.experienceRemoveField(e,index)} >-</button>
                                        </div>
                                </div>    
                            </div>))}<hr/>
                        <button className="btn btn-success btn-lg">Submit</button>
                    </form>

                </div>
            </div>
        )
    }
}

export default withRouter(AddFaculty);