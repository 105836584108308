import React from 'react';
import { Navbar, Nav, NavDropdown, CardDeck, Card } from 'react-bootstrap'
import {
    Link
} from "react-router-dom";

function Academy() {
    return (
        <>
            <div className="container-fluid">
                <div className="container">
                    <Navbar bg="black" variant="dark" expand="lg" className="px-0">
                        <Navbar.Brand href="#home"><img src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Figesia-logo-reverse-sm.png?alt=media&token=7981f7ad-29f8-42f9-988d-c68308ec8888"
                            width="60"
                        /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <Link class="nav-link" to="/home">Home</Link>
                                <Link class="nav-link" to="/academy">Academy</Link>
                                <Link class="nav-link" to="/insights">Insights</Link>
                                <NavDropdown title="Product" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">Learning XP</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.1">Academy</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">Aspire</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.3">Shicsha</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#action/3.4">Health XP</NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.4">Health</NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                            <a class="nav-link" href="https://web.igesia.co" target="_blank">Console</a>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
            </div>
        <div className="container-fluid bg-black pt-5">
            <div className="container">
                <div className="row">
                    <div className="col text-white py-5">
                        <h1 className="font-weight-light">The future of work is here.</h1>
                    </div>
                </div>
                <div className="row justify-content-md-center ">
                    <CardDeck>
                        <Card bg="dark" text="white">
                            <Card.Body>
                                <Card.Title>Academy</Card.Title>
                                <Card.Text>
                                    <p>
                                        High Quality. Low Cost. Scalable. Executive Education.
                            </p>
                                    <p>
                                        <Link to="/academy" variant="primary">Explore</Link>
                                    </p>
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">In Progress.</small>
                            </Card.Footer>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title>Shicsha</Card.Title>
                                <Card.Text>
                                High Quality. Personalised. <s>Out of school</s> education.
                                </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Ideation</small>
                            </Card.Footer>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title>Aspire</Card.Title>
                                <Card.Text>
                                    High Quality. Personalised. <s>Out of school</s> education.
      </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Ideation</small>
                            </Card.Footer>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Card.Title>Health</Card.Title>
                                <Card.Text>
                                    High Quality. Low Cost. Next Generation. Wellness & Healthcare.
      </Card.Text>
                            </Card.Body>
                            <Card.Footer>
                                <small className="text-muted">Ideation</small>
                            </Card.Footer>
                        </Card>
                    </CardDeck>
                </div>
            </div>
        </div>
        </>
    );
}

export default Academy;