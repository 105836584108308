import React, { useEffect, useState } from 'react';
import { Card, Popover, OverlayTrigger, Button, Nav, NavDropdown, Spinner } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faVideo, faUsers, faChalkboardTeacher, faLightbulb, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {
    Link, useHistory
} from "react-router-dom";
import AcademyNavbar from './AcademyNavbar'
import AcademyFooter from './AcademyFooter'
import firebase from 'firebase';
import background from '../../images/polygon.png'

const categoryoptions = (
    <Popover id="popover-basic">
        <Popover.Title as="h3">Cateogry</Popover.Title>
        <Popover.Content>
            And here's some <strong>amazing</strong> content. It's very engaging.
        right?
      </Popover.Content>
    </Popover>
);

const Category = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Category</Button>
    </OverlayTrigger>
);

const Dates = () => (
    <OverlayTrigger trigger="click" placement="bottom" overlay={categoryoptions}>
        <Button className="mb-5 mr-3 rounded small" variant="outline-light" style={{ borderColor: "#555555" }}>Dates</Button>
    </OverlayTrigger>
);

function Academy(props) {

    const handleChange = function (event) {
        console.log(event.target.value)
    }

    const history = useHistory();

    function handleClick() {
        history.push("/program");
    }

    const [programList, setProgramList] = useState([]);
    const [loadingProgramList, setLoadingProgramList] = useState(true);

    useEffect(() => {

        let programListRef = firebase.firestore().collection('programList');
        let allPrograms = programListRef.orderBy("position", "asc").get()
            .then(snapshot => {
                let tempProgramList = [];

                snapshot.forEach(doc => {
                    tempProgramList.push({ programSlug: doc.id, ...doc.data() });
                });
                setProgramList(tempProgramList);
                setLoadingProgramList(false);
                console.log(tempProgramList)
            })
            .catch(err => {
                console.log('Error getting documents', err);
            });
    }, [])

    useEffect(() => {
        // check whether the url has ref or not
        console.log(props)
        if(!props.match.params.ref || !props.match.params[0]){
            //if no ref in url, skip
        }else if(props.match.params.ref){
            // url has ref
            if(window.localStorage.getItem('ref')){
                // there is already a ref in local storage
                // console.log('LS has ref already!');
                // incrementing count of new ref but not replacing LS
                firebase.firestore().collection('referrals').doc(props.match.params.ref).update({
                    referralLinkVisited : firebase.firestore.FieldValue.increment(1)
                })
            }else if (!window.localStorage.getItem('ref')){
                // no ref in local storage
                window.localStorage.setItem('ref', JSON.stringify(props.match.params.ref));
                firebase.firestore().collection('referrals').doc(props.match.params.ref).update({
                    referralLinkVisited : firebase.firestore.FieldValue.increment(1)
                })
            }

        }
    },[])



    // useEffect(() => {
    //     programList.map((program, key) => {
    //         let thatProgram = {"position": program.position, "programId": program.programId, "programSlug": program.programSlug, "programSubTitle": program.programSubTitle, "programThumbnail": program.programThumbnail, "programTitle": program.programTitle, "programType": program.programType}
    //         console.log(program.programSlug, thatProgram)
    //         postData("https://igesia-dbadmin.firebaseio.com/programs.json", {[program.programSlug]: thatProgram})
    //     })
    // }, [programList])

    // async function postData(url = '', data = {}) {
    //     // Default options are marked with *
    //     const response = await fetch(url, {
    //         method: 'PATCH', // *GET, POST, PUT, DELETE, etc.
    //         mode: 'cors', // no-cors, *cors, same-origin
    //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //         //   credentials: 'same-origin', // include, *same-origin, omit
    //         headers: {
    //             'Content-Type': 'application/json'
    //             // 'Content-Type': 'application/x-www-form-urlencoded',
    //         },
    //         redirect: 'follow', // manual, *follow, error
    //         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //         body: JSON.stringify(data) // body data type must match "Content-Type" header
    //     });
    //     return response.json(); // parses JSON response into native JavaScript objects
    // }

    return (
        <>
            <AcademyNavbar />
            <div className="container-fluid pt-5">
                <div className="container">
                    <div className="row mb-5">
                        {/* style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }} */}
                        <div className="col-md-6 text-white py-4 ">
                            <h1 className="font-weight-light mb-3">World-class education designed to prepare you for the future. </h1>
                            <p className="lead">Delivered by the best faculty worldwide via live virtual teaching on the next-gen Igesia learning platform</p>
                        </div>
                        <div className="col-md-6 text-white py-4">
                            <div className="w-100 rounded mb-2"
                                style={{
                                    backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fezgif-2-b0c8c621d546.jpg?alt=media&token=c3742d85-fab5-4c74-aa3d-1332399fb06d)',
                                    backgroundSize: 'cover',
                                    height: '300px'
                                }}>
                            </div>
                            <p className="small">Inaugural address by Nobel Laureate A. Michael Spence (all programs)</p>
                        </div>
                    </div>
                    <div className="row mb-5 text-white text-center">
                        <div className="col-md-3 mb-3 flex-grow-1">
                            <div className="rounded border p-4">
                                <h5>16 Live Virtual Sessions</h5>
                                <p className="mb-0">Over 2 months</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 flex-grow-1">
                            <div className="rounded border p-4">
                                <h5>5 Interactive Modules</h5>
                                <p className="mb-0">Taught by Top Global Faculty</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 flex-grow-1">
                            <div className="rounded border p-4">
                                <h5>Dedicated Group Coach</h5>
                                <p className="mb-0">With Active Group Work</p>
                            </div>
                        </div>
                        <div className="col-md-3 mb-3 flex-grow-1">
                            <div className="rounded border p-4">
                                <h5>Live Sessions<br />with</h5>
                                <p className="mb-0">Industry Experts</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-black pt-5">
                <div className="container">
                    {loadingProgramList
                        ? <div className="container-fluid w-100 d-flex justify-content-center align-items-center" style={{ height: "20vh" }}>
                            <Spinner animation="border" role="status" variant="light">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                        : <div className="row">
                            {programList.map((item, key) => {
                                if(+item.position >= 0){
                                    return(
                                            <div className="col-md-4" key={key}>
                                                <Link className="link" to={`/program/${item.programSlug}`}>
                                                    <Card className="mb-3" bg="transparent" text="white">
                                                        <Card.Img variant="top" src={item.programThumbnail} />
                                                        <Card.Body className="px-0">
                                                            <span className="small mb-4 text-warning">STARTS OCTOBER 2020</span>
                                                            <Card.Title>{item.programTitle}</Card.Title>
                                                            <Card.Text className="small">{item.programSubTitle}</Card.Text>
                                                            <div className="row">
                                                                <div className="col-12 ">
                                                                    {item.associatedUniversities && <div>
                                                                        <p className="small font-weight-bold text-muted pt-3 border-top">Learn and interact through live online classes with world class faculty from</p>
                                                                        <div className="row">
                                                                            {item.associatedUniversities.map(university =>
                                                                                <p className="col-md-6 mb-1 small ">{university}</p>
                                                                            )}
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                                <div className="col-12">
                                                                 <p className="small mt-3">Attested with a record of attendance from the <strong>University of California, Los Angeles (UCLA) Extension</strong></p>
                                                                 <img className="mb-3" 
                                                                    src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fucla_reverse.png?alt=media&token=adbdeab7-41e8-4d9a-a1a7-9f2d8fb3f5ac" 
                                                                 width="30%" />
                                                                </div>
                                                            </div>
                                                            <span className="text-muted">Learn More <FontAwesomeIcon icon={faArrowRight} size="sm" color="grey" /></span>
                                                        </Card.Body>
                                                    </Card>
                                                </Link>
                                            </div>
                                    )
                                }
                            })}
                        </div>}

                </div>
            </div>
            {/* <div className="container-fluid bg-light py-5" style={{
                backgroundSize: 'cover', backgroundPosition: 'bottom right',
                backgroundImage: 'url(http://themesmummy.com/mitech/static/130fe9ab91ac2fc94b9b15afd0b37809/8c24d/title-bar-01-bg.jpg)'
            }}>
                <div className="container">
                    <div className="row" style={{ borderBottom: "1px solid rgba(255,255,255,0.2)" }}>
                        <div className="col-md-6 text-dark pt-0 pt-md-5 ">
                            <p className="pt-5">RESOURCES</p>
                            <h2 className="font-weight-light">Get a copy of our program calendar</h2>
                            <button className="btn btn-primary btn-md mt-3 px-4 font-weight-bold">Download now</button>
                        </div>
                        <div className="col-md-6" style={{ overflow: "hidden" }}>
                            <img src="http://themesmummy.com/mitech/static/80be319d82ff066e03c711a0e8ba7ad5/7ce22/book-cover.png" />
                        </div>
                    </div>
                </div>
            </div> */}
            <AcademyFooter />
        </>
    );
}

export default Academy;