import React, {useContext} from 'react';
import { Navbar, Nav, NavDropdown, Modal, Button } from 'react-bootstrap'
import {
  Link
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import firebase from "../../firebase";
import {NameContext} from '../Academy/Auth';
import { useHistory } from 'react-router-dom'

const DashboardNavbar = (props) => {
  const firstName = useContext(NameContext);
  let history = useHistory();
  return (
    <div className="container-fluid">
      <div className="container">
        <Navbar bg="black" variant="dark" expand="lg" className="px-0">
          <Navbar.Brand href="/"><img src="https://firebasestorage.googleapis.com/v0/b/igesia-web.appspot.com/o/images%2Fpublic%2Fedited.svg?alt=media&token=11473d04-0b59-43e5-b4f3-3c66798c0ad3"
            width="120"
          /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
            </Nav>
            <Nav>
              <NavDropdown title={<FontAwesomeIcon icon={faUserCircle} className="fa-2x" />} id="basic-nav-dropdown">
                <NavDropdown.Item className="text-capitalize">{firstName}</NavDropdown.Item>
                <NavDropdown.Item onClick={() => {history.push('/home')}}>View Live Site</NavDropdown.Item>
                <NavDropdown.Item onClick={() => {firebase.auth().signOut().then(() => alert('signed out'))}}>Sign out</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>

      </div>
    </div>
  );
}

export default DashboardNavbar;